import "../App.css";
import { Switch, Route, useHistory } from "react-router-dom";
import Dashboard from "../Page/Dashboard/Dashboard.js";
import EditProfile from "../Page/EditProfile/EditProfile";
import LoginDoctor from "../Component/Auth/LoginDoctor/LoginDoctor";
import SignupDoctor from "../Component/Auth/SignupDoctor/SignupDoctor";
import SetUpProfile from "../Page/SetUpProfile/SetUpProfile";
import SetUpAppoinmentSchedule from "../Page/SetUpAppoinmentSchedule/SetUpAppoinmentSchedule";
import OtpDoctor from "../Component/Auth/OtpDoctor/OtpDoctor";
import PatientList from "../Page/PatientList/PatientList";
import ChatScreen from "../Page/ChatScreen/ChatScreen";
import Video from "../Page/Video/Video";
import ForgetPassword from "../Component/Auth/ForgetPassword/ForgetPassword";
import OtpVerification from "../Component/Auth/OtpVerification/OtpVerification";
import ResetPassword from "../Component/Auth/ResetPassword/ResetPassword";
import QuestionSummary from "../Page/QuestionSummary/QuestionSummary";
import ViewPatient from "../Page/ViewPatient/ViewPatient.js";
import AddTreatment from "../Page/AddTreatment/AddTreatment.js";
import AddMedicine from "../Page/AddMedicine/AddMedicine.js";
import ConsultationList from "../Page/ConsultationList/ConsultationList";
import PatientSummryCard from "../Page/SummaryCard/PatientSummryCard/PatientSummryCard.js";
import ViewConsultation from "../Page/ViewConsultation/ViewConsultation.js";
import PathologyList from "../Page/PathologyList/PathologyList";
import SubscriptionModel from "../Page/SubscriptionModel/SubscriptionModel";
import TreatmentList from "../Page/AddTreatment/TreatmentList";
import PatientSummaryCard from "../Page/AddTreatment/PatientSummaryCard";
import Auth from "../helpers/Auth";
import { Nomatch } from "../Page/Nomatch";
import React, { useContext, useEffect, useState } from "react";
import { appContext } from "../helpers/AppContext";
import ChatScreenListMobile from "../Page/ChatScreen/ChatScreenListMobile";
import ChatScreenMobile from "../Page/ChatScreen/ChatScreenMobile";
import CustomerSupport from "../Page/Dashboard/CustomerSupport";

function RouteLink() {
  const { socket } = useContext(appContext);
  const [rooms, setRooms] = useState();
  const [msg, setMsg] = useState();
  const [isMobile, setIsMobile] = React.useState(window.innerWidth > 991);
  const { isCostpmerSupportOpen, setIsCostpmerSupportOpen } =
    useContext(appContext);

  const userData = Auth.getUserData();

  // handle responsiveness
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      const mobile = window.innerWidth > 991;
      if (mobile !== isMobile) setIsMobile(mobile);
    });
  }, [isMobile]);


  useEffect(() => {
    if (userData?._id) {
      socket?.emit("online", { userId: userData?._id });
      socket?.on("receive_message", (data) => {
        setMsg(data);
        window.location.pathname !== "/ChatScreen" &&
          socket?.emit("message_deliver_status", {
            messageId: data._id,
            status: 1,
            roomId: data?.roomId,
          });
        askNotificationPermission();
        if (
          Notification.permission === "granted" &&
          window.location.pathname !== "/ChatScreen"
        ) {
          createNotification(data);
        }
      });
      return () => {
        socket?.off("get_rooms");
        rooms?.rooms?.map((res) => {
          socket?.emit("leave_room", {
            roomId: res?._id,
            userId: res?.user?._id,
          });
        });
      };
    }
  }, [window.location.pathname, msg]);

  useEffect(() => {
    if (userData?._id) {
      if (window?.location?.pathname !== "/ChatScreen") {
        socket?.on("get_rooms", async (rooms) => {
          rooms?.rooms &&
            rooms?.rooms.map((res) => {
              socket?.emit("join_room", {
                roomId: res?._id,
                userId: res?.user?._id,
              });
            });
          setRooms(rooms?.rooms);
        });
      }
    }
  }, [msg, window.location.pathname]);

  const createNotification = (data) => {
    const text = data?.message;
    const notification = new Notification(
      data?.firstName + " " + data?.lastName,
      { body: text, icon: "" }
    );
    notification.onclick = () => {
      notification.close();
      window.parent.focus();
    };
  };
  const checkNotificationPromise = () => {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  };
  const askNotificationPermission = () => {
    const handlePermission = (permission) => {
      if (!Reflect.has(Notification, "permission")) {
        Notification.permission = permission;
      }
    };

    if (!Reflect.has(window, "Notification")) {
    } else {
      if (checkNotificationPromise()) {
        Notification.requestPermission().then(handlePermission);
      } else {
        Notification.requestPermission(handlePermission);
      }
    }
  };
  return (
    <>
    {/* whole app routes */}
      <Switch>
        <PublicRoute exact path="/">
          <LoginDoctor />
        </PublicRoute>
        <PublicRoute exact path="/SignupDoctor">
          <SignupDoctor />
        </PublicRoute>
        <PublicRoute exact path="/ForgetPassword">
          <ForgetPassword />
        </PublicRoute>
        <PublicRoute exact path="/OtpVerification">
          <OtpVerification />
        </PublicRoute>
        <PublicRoute exact path="/ResetPassword">
          <ResetPassword />
        </PublicRoute>
        <PublicRoute exact path="/OtpDoctor">
          <OtpDoctor />
        </PublicRoute>
        <PrivateRoute exact path="/SetUpProfile">
          <SetUpProfile />
        </PrivateRoute>
        <PrivateRoute exact path="/SetUpAppoinmentSchedule">
          <SetUpAppoinmentSchedule />
        </PrivateRoute>
        <PrivateRoute exact path="/EditProfile">
          <EditProfile />
        </PrivateRoute>
        <PrivateRoute exact path="/QuestionSummary/:id">
          <QuestionSummary isMobile={isMobile} />
        </PrivateRoute>
        <PrivateRoute exact path="/PatientList">
          <PatientList />
        </PrivateRoute>
        <PrivateRoute exact path="/ChatScreen">
          {isMobile ? <ChatScreen /> : <ChatScreenListMobile />}
        </PrivateRoute>
        <PrivateRoute exact path="/ChatScreenMobile/:id/:id">
          <ChatScreenMobile />
        </PrivateRoute>
        <PrivateRoute exact path="/video">
          <Video />
        </PrivateRoute>
        <PrivateRoute exact path="/Video">
          <Video />
        </PrivateRoute>
        <PrivateRoute exact path="/Dashboard">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute exact path="/viewpatient">
          <ViewPatient isMobile={isMobile}/>
        </PrivateRoute>
        <PrivateRoute exact path="/AddTreatment/:id">
          <AddTreatment />
        </PrivateRoute>
        <PrivateRoute exact path="/ConsultationList">
          <ConsultationList />
        </PrivateRoute>
        <PrivateRoute exact path="/AddMedicine">
          <AddMedicine />
        </PrivateRoute>
        <PrivateRoute exact path="/PatientSummryCard">
          <PatientSummryCard />
        </PrivateRoute>
        <PrivateRoute exact path="/ViewConsultation/:id">
          <ViewConsultation isMobile={isMobile} />
        </PrivateRoute>
        <PrivateRoute exact path="/PathologyList">
          <PathologyList />
        </PrivateRoute>
        <PrivateRoute exact path="/SubscriptionModel">
          <SubscriptionModel />
        </PrivateRoute>
        <PrivateRoute exact path="/TreatmentList">
          <TreatmentList />
        </PrivateRoute>
        <PrivateRoute exact path="/PatientSummaryCard">
          <PatientSummaryCard />
        </PrivateRoute>
        <Route exact path="*">
          <Nomatch />
        </Route>
      </Switch>
      {isCostpmerSupportOpen ? <CustomerSupport /> : ""}
    </>
  );
}

export default RouteLink;

const PrivateRoute = ({ children, ...rest }) => {
  let auth = Auth.isUserAuthenticated();
  let history = useHistory();
  return (
    <Route
      {...rest}
      render={() => {
        if (auth) {
          return children;
        } else history.push({ pathname: "/" });
      }}
    />
  );
};

const PublicRoute = ({ children, ...rest }) => {
  let auth = !Auth.isUserAuthenticated();
  let history = useHistory();
  return (
    <Route
      {...rest}
      render={() => {
        if (auth) {
          return children;
        } else history.push({ pathname: "/Dashboard" });
      }}
    />
  );
};
