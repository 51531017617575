import "./SetUpAppoinmentSchedule.css";
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { TimePicker } from "antd";
import moment from "moment";
import { ApiPut } from "../../helpers/API/API_data";
import { ErrorToast, SuccessToast } from "../../helpers/Toast";
import { useMediaQuery } from "@mui/material";

const SetUpAppoinmentSchedule = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({
    isSunday: false,
    isMonday: false,
    isTuesday: false,
    isWednesday: false,
    isThursday: false,
    isFriday: false,
    isSaturday: false,
  });

  const [selectedTimes, setSelectedTimes] = useState({});
  const defaultStartTime = moment("00:00", "HH:mm");
  const defaultEndTime = moment("00:00", "HH:mm");

// convert string of date to moment obj
  function convertStringToDate(scheduleData) {
    const newData = {};

    for (const day in scheduleData) {
      if (Object.hasOwnProperty.call(scheduleData, day)) {
        const events = scheduleData[day].map((event) => ({
          startTime: moment(event.startTime),
          endTime: moment(event.endTime),
        }));

        newData[day] = events;
      }
    }

    return newData;
  }
// convert shedule data into h and m format
  const transformScheduleData = (scheduleData) => {
    const transformedData = {};

    for (const day in scheduleData) {
      if (Object.hasOwnProperty.call(scheduleData, day)) {
        const dayTimes = scheduleData[day].map((event) => ({
          starttime: {
            h: new Date(event.startTime).getHours().toString().padStart(2, "0"),
            m: new Date(event.startTime)
              .getMinutes()
              .toString()
              .padStart(2, "0"),
          },
          endtime: {
            h: new Date(event.endTime).getHours().toString().padStart(2, "0"),
            m: new Date(event.endTime).getMinutes().toString().padStart(2, "0"),
          },
        }));

        transformedData[`${day.toLowerCase()}Time`] = dayTimes;
      }
    }

    return transformedData;
  };

  // pre fill schedule data on edit
  useEffect(() => {
    const url = new URL(window.location.href);
    setIsEdit(url.searchParams.get("isEdit"));
    let setUPSchedule = localStorage.getItem("Doctordetails");
    if (setUPSchedule) {
      setUPSchedule = JSON.parse(setUPSchedule);
      setSelectedTimes(convertStringToDate(setUPSchedule?.originalSchduleData));
      setCheckboxStates({
        ...checkboxStates,
        isMonday: setUPSchedule?.schedule?.isMonday,
        isSunday: setUPSchedule?.schedule?.isSunday,
        isTuesday: setUPSchedule?.schedule?.isTuesday,
        isWednesday: setUPSchedule?.schedule?.isWednesday,
        isThursday: setUPSchedule?.schedule?.isThursday,
        isFriday: setUPSchedule?.schedule?.isFriday,
        isSaturday: setUPSchedule?.schedule?.isSaturday,
      });
    }
  }, []);

  // handle select time slot
  const handleTimeSelect = (day, index, type, value, dayOfWeek) => {
    const updatedTimes = { ...selectedTimes };
    if (!updatedTimes[dayOfWeek]) {
      updatedTimes[dayOfWeek] = [];
    }
    if (!updatedTimes[dayOfWeek][index]) {
      updatedTimes[dayOfWeek][index] = {};
    }
    updatedTimes[dayOfWeek][index][type] = value;
    setSelectedTimes(updatedTimes);
  };
  
  // add time new time slot
  const addNewTimeSlot = (day, dayOfWeek) => {
    const updatedTimes = { ...selectedTimes };
    if (!updatedTimes[dayOfWeek]) {
      updatedTimes[dayOfWeek] = [];
    }
    updatedTimes[dayOfWeek].push({
      startTime: defaultStartTime,
      endTime: defaultEndTime,
    });
    setSelectedTimes(updatedTimes);
  };

   // remove time new time slot
  const removeTimeSlot = (dayOfWeek, indexToRemove) => {
    const updatedTimes = { ...selectedTimes };
    if (
      updatedTimes[dayOfWeek] &&
      updatedTimes[dayOfWeek].length > indexToRemove
    ) {
      updatedTimes[dayOfWeek].splice(indexToRemove, 1);
      setSelectedTimes(updatedTimes);
    }
  };

  const handleCheckboxChange = (day) => { 
    setCheckboxStates((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
    if (!checkboxStates[`is${day}`]) {
    const dayOfWeek = day.slice(2); 
    const selectedTimeSlots = selectedTimes[dayOfWeek];

    if (selectedTimeSlots && selectedTimeSlots.length) {     
      delete selectedTimes[dayOfWeek];
      setSelectedTimes(selectedTimes);
    }}
  
  };
  
  // validate shedule data obj before submit
  const checkKeyLength = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key].length > 0) {
        return true;
      }
    }
    return false;
  };
    // validate shedule data obj before submit
  const hasNull = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const daysData = obj[key];
        for (const day of daysData) {
           
            if (!day.startTime && !day.endTime) {
              return false;
            }

            if (!day.startTime || !day.endTime) {
              return true;
            } 
        }
      }
    }
    return false;
  };
  // validate shedule data obj before submit
  const removeBothNullObj = (data) => {
    const filteredData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        value.filter(
          (obj) => !(obj.startTime === null && obj.endTime === null)
        ),
      ])
    );
    return filteredData;
  };
  // submit valid schedule data
  const handleSubmit = async () => {
    const transformData = transformScheduleData(selectedTimes);
  
    if (!checkKeyLength(selectedTimes)) {
      ErrorToast("Please Select Time And Day");
    } else if (hasNull(selectedTimes)) {
      ErrorToast("Start time and end time both are required");
    } else {
      const removedBothNull = removeBothNullObj(selectedTimes);
      for (const day in removedBothNull) {
        if (removedBothNull[day].length === 0) {
          delete removedBothNull[day];
        }
      }
      const body = {
        flag: 1,
        schedule: {
          ...transformData,
          ...checkboxStates,
        },
        originalSchduleData: removedBothNull,
        profilePhase: 2,
      };
      await ApiPut("doctor", body)
        .then((res) => {
          SuccessToast("sign up successfully!!!");
          const olddata = JSON.parse(localStorage.getItem("logindata"));
          const newdata = {
            ...olddata,
            firstName: res?.data?.data?.firstName,
            lastName: res?.data?.data?.lastName,
          };
          if (res?.data?.data?.profilePhase === 0) {
            history.push("/SetUpProfile");
          } else if (res?.data?.data?.profilePhase === 1) {
            history.push("/SetUpAppoinmentSchedule");
          } else if (res?.data?.data?.profilePhase === 2) {
            localStorage.setItem(
              "Doctordetails",
              JSON.stringify(res?.data?.data)
            );
            localStorage.setItem("logindata", JSON.stringify(newdata));
            history.push("/Dashboard");
          }
        })
        .catch((err) => {
          console.log("err", err);
          ErrorToast(err?.message);
        });
    }
  };
  return (
    <>
      <div className="step">
        <ul className="stepdetail">
          <li>
            <span></span>
            <p>Setup your profile</p>
          </li>
          <li>
            <span></span>
            <p>Setup your availability to consult</p>
          </li>
        </ul>
      </div>
      <div className="editprofiledetail setpro setappo setup_appointment_schedule p-0">
        <div className="paymentinfo ">
          <Row>
            <Col lg="12" md="12">
              <div className="setupappo">
                <Row>
                  <Col lg="12" md="12">
                    <div className="setproheading">
                      <h3>Select your available slot</h3>
                    </div>
                  </Col>
                </Row>
                {!isMobile && (
                  <Row>
                    <Col lg="4" md="4"></Col>
                    <Col lg="8" md="8">
                      <Row className="h-100 d-flex justify-content-end">
                        <Col lg="5.5" md="5" className="h-100">
                          <div className="h-100 d-flex align-items-end">
                            <span className="start_time">Start Time</span>
                          </div>
                        </Col>
                        <Col lg="5.5" md="5" className="h-100">
                          <div className="h-100 d-flex align-items-end">
                            <span className="start_time">End Time</span>
                          </div>
                        </Col>
                        <Col lg="1" md="1" className="h-100">
                          {/* <div className="h-100 d-flex align-items-end">
                            <span className="start_time">End Time</span>
                          </div> */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                {[...Array(7)].map((_, index) => {
                  const day = moment().startOf("week").add(index, "days");
                  const dayOfWeek = day.format("dddd");

                  const timeSlots = selectedTimes[dayOfWeek] || [
                    { startTime: defaultStartTime, endTime: defaultEndTime },
                  ];
                  return (
                    <Col key={dayOfWeek} className="calendar-day">
                      <Row className="m-0">
                        <Col
                          lg="4"
                          md="4"
                          sm="12"
                          xs="12"
                          className="day_wrapper"
                          style={{ paddingRight: "16px" }}
                        >
                          <Row className="justify-content-end m-0">
                            <Col
                              className="p-0"
                              lg="12"
                              md="12"
                              sm="12"
                              xs="12"
                            >
                              <div className="selecttab days">
                                <div className="male">
                                  <input
                                    type="checkbox"
                                    id="age1"
                                    disabled
                                    name="age"
                                    value="1"
                                  />
                                  <label for="age1">{dayOfWeek}</label>
                                </div>
                              </div>
                            </Col>
                            <Col
                              lg="12"
                              md="12"
                              sm="12"
                              xs="12"
                              className="day_checkBox"
                            >
                              <Form.Check
                                type="checkbox"
                                id={`checkbox-${dayOfWeek}`}
                                checked={checkboxStates[`is${dayOfWeek}`]}
                                onChange={() =>
                                  handleCheckboxChange(`is${dayOfWeek}`)
                                }
                                label="Day off"
                                className="dayOfCheckbox"
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col lg="8" md="8" sm="12" xs="12">
                          <div className="time-range">
                            {isMobile && (
                              <Row style={{ margin: "6px 0px" }}>
                                <Col lg="5" md="5" xs="5" className="p-0">
                                  <span className="start_time m-0 d-flex align-items-end">
                                    Start Time
                                  </span>
                                </Col>
                                <Col lg="5" md="5" xs="5">
                                  <span className="start_time m-0 d-flex align-items-end">
                                    End Time
                                  </span>
                                </Col>
                              </Row>
                            )}
                            {timeSlots.map((timeSlot, idx) => (
                              <div key={idx} className="time-slot">
                                <Row className="justify-content-end">
                                  <Col lg="5.5" md="5.5" xs="5">
                                    <Form.Group>
                                      <TimePicker
                                        disabled={
                                          checkboxStates[`is${dayOfWeek}`]
                                        }
                                        // inputReadOnly={true}

                                        className="form-control"
                                        style={{
                                          padding: "10px",
                                          backgroundColor: "transparent",
                                        }}
                                        value={timeSlot.startTime}
                                        format="HH:mm"
                                        // onChange={handlecheck}
                                        onChange={(time) =>
                                          handleTimeSelect(
                                            day,
                                            idx,
                                            "startTime",
                                            time,
                                            dayOfWeek
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col
                                    lg="5.5"
                                    md="5.5"
                                    xs="5"
                                    style={{ marginBottom: "16px" }}
                                  >
                                    <Form.Group>
                                      <TimePicker
                                        disabled={
                                          checkboxStates[`is${dayOfWeek}`]
                                        }
                                        className="form-control"
                                        style={{
                                          padding: "10px",
                                          backgroundColor: "transparent",
                                        }}
                                        value={timeSlot.endTime}
                                        format="HH:mm"
                                        onChange={(time, timeString) =>
                                          handleTimeSelect(
                                            day,
                                            idx,
                                            "endTime",
                                            time,
                                            dayOfWeek
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col
                                    lg="1"
                                    md="1"
                                    xs="2"
                                    className="d-flex justify-content-end "
                                  >
                                    {timeSlots?.length > 1 && (
                                      <Button
                                        onClick={() =>
                                          removeTimeSlot(dayOfWeek, idx)
                                        }
                                        className="add-time-btn plus_minus_btn"
                                      >
                                        -
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            ))}
                            <Row className={`d-flex ${isMobile ? "" :"justify-content-end"}`} style={{marginTop:"1.5px"}}>
                              <Col lg="5.5" md="5" xs="5"></Col>
                              <Col lg="5.5" md="5" xs="5" className={`d-flex justify-content-end`}>
                                <Button
                                  onClick={() => addNewTimeSlot(day, dayOfWeek)}
                                  className="add-time-btn plus_btn "
                                  disabled={checkboxStates[`is${dayOfWeek}`]}
                                >
                                  +
                                </Button>
                              </Col>
                              <Col lg="1" md="1" xs="1"></Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
                <div className="nextbtns setappo" style={{ marginTop: "0px" }}>
                  <div className="editprofilebtn">
                    <Link
                      to={
                        isEdit ? "/SetUpProfile?isEdit=true" : "/SetUpProfile"
                      }
                      className="redbtn whitebtn"
                    >
                      <svg
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.7185 5.33312H10.8332V6.66645H2.7185L6.2945 10.2425L5.35184 11.1851L0.166504 5.99979L5.35184 0.814453L6.2945 1.75712L2.7185 5.33312Z"
                          fill="#003B61"
                        />
                      </svg>
                      Back
                    </Link>
                    <a role="button" onClick={handleSubmit} className="redbtn">
                      Next{" "}
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.2812 7.33312L7.70517 3.75712L8.64784 2.81445L13.8332 7.99979L8.64784 13.1851L7.70517 12.2425L11.2812 8.66645H3.1665V7.33312H11.2812Z"
                          fill="#F8F5F0"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="nextbtns">
        <p>©2023 Frenchie M.D Pty Ltd</p>
      </div>
    </>
  );
};

export default SetUpAppoinmentSchedule;
