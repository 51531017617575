import "./OtpDoctor.css";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../Assets/images/doclogo.png";
import { Row, Col } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { ApiPostNoAuth } from "../../../helpers/API/API_data";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import Timer from "otp-timer";
import { useRef } from "react";
import { encryptdata } from "../../../helpers/Encrypt";

const OtpDoctor = () => {
  const [val, setVal] = useState("");
  const ref = useRef();

  const history = useHistory();
  const doctorEmail = localStorage.getItem("userdata");
  const doctorPass = localStorage.getItem("userpassword");
  
  // submit OTP 
  const handleSubmit = async () => {
    const body = {
      otp: val,
      email: doctorEmail,
    };
    const encryptedData = encryptdata(body);
    await ApiPostNoAuth("doctor/otp_verification", {encryptedData})
      .then((res) => {
        
        if (res.data.status === 200) {
          localStorage.removeItem("userdata");
          localStorage.removeItem("userpassword");
          localStorage.setItem("doctorData", JSON.stringify(res?.data?.data));
          localStorage.setItem("logindata", JSON.stringify(res?.data?.data));
          localStorage.setItem("token", JSON.stringify(res?.data?.data?.token));
          SuccessToast("Register Successfully!!!");
          history.push("/SetUpProfile");
        }
      })
      .catch((err) => {
        
        ErrorToast("Invalid Otp!!!");
      });
  };
    // handle resend OTP 
  const handleResend = async (e) => {
    let body = {
      email: doctorEmail,
      password: doctorPass
    }
    const encryptedSignupData = encryptdata(body)
    await ApiPostNoAuth("doctor", { encryptedSignupData })
      .then(res => {
        
        SuccessToast(`Otp Resend to ${doctorEmail} Successfully!!!`)
        // ref.current?.handleClick(e)
      })
      .catch((err) => {
        
        ErrorToast(err?.message);
      });
  };
  return (
    <>
      <section className="login otp_doctor_section">
        <header className="docheader">
          <img src={logo} alt="" />
        </header>
        <Row>
          <Col lg={12}>
            <div className="loginuserdetail otp otpdoctor">
              <h3>2 Step Verification </h3>
              <p>An 6-digit code has been sent to Your Email : {doctorEmail}</p>
              <div className="inputdetail">
                <div className="d-flex justify-content-center align-content-center">
                  <OtpInput
                    className="mb-5 otp-input"
                    value={val}
                    onChange={(o) => setVal(o)}
                    numInputs={6}
                    separator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                  />
                </div>

                <div className="btnred btnwhite">
                  {" "}
                  <Link onClick={handleSubmit} role="button">
                    Verify
                  </Link>{" "}
                </div>
              </div>
              <div className="resend">
                <Timer
                  ref={ref}
                  seconds={60}
                  minutes={0}
                  background={"transparent"}
                  resend={handleResend}
                  ButtonText={
                    <p>
                      Didn’t received the code?{" "}
                      <span style={{ color: "#B4555D" }}> Resend</span>
                    </p>
                  }
                  text="Resend OTP in"
                ></Timer>
                <span>I need help getting my verificatrion code.</span>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default OtpDoctor;
