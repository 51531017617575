import React, { useEffect, useState } from "react";
import profile from "../../Assets/images/editprofile.png";
import edit from "../../Assets/images/pencile.png";
import { Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { APIUploadPost, ApiGet, ApiPost, ApiPut, Bucket } from "../../helpers/API/API_data";
import { ErrorToast, SuccessToast } from "../../helpers/Toast";
import Header from "../Header/Header";
import "./EditProfile.css";

const EditProfile = () => {
  const [data, setdata] = useState({});
  const [profileImage, setprofileImage] = useState([]);
  const history = useHistory();
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await ApiGet("doctor")
      .then((res) => {
        setdata(res?.data?.data);
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setdata({
      ...data,
      [name]: value,
    });
  };
  const saveBtn = async () => {
    const formData = new FormData();
    formData.append("image", profileImage[0]);
    await APIUploadPost("upload/profile_image", formData)
      .then(async (res1) => {
        let body = {
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          contactNumber: data?.contactNumber,
          clinicContactNumber: data?.clinicContactNumber,
          bio: data?.bio,
          registrationNo: data?.registrationNo,
          medicareNo: data?.medicareNo,
          profilePhase: 1,
          state: data?.state,
          city: data?.city,
          image: res1?.data?.data?.image,
          address: data?.address,
          postcode: data?.postcode,
        };
        await ApiPut("doctor", body)
          .then((res) => {
            setdata(res?.data?.data);
            SuccessToast(res?.data?.message);
            history.push("/DoctorDashboard");
          })
          .catch((e) => {
            console.log("e", e);
            ErrorToast(e?.message);
          });
      })
      .catch((e) => {
        console.log("e", e);
      });
  };
  const handleimage = (e) => {
    let file = e.target.files[0];
    let fileURL = URL.createObjectURL(file);
    file.fileURL = fileURL;

    setprofileImage([file]);
  };

  return (
    <>
      <Header />
      <section className="edit_profile_sec">
        <div className="editprofiledetail">
          <div className="user-avatar position-relative text-center">
            <img
              src={
                profileImage[0]?.fileURL
                  ? profileImage[0]?.fileURL
                  : data.image
                    ? Bucket + data?.image
                    : profile
              }
              height={150}
              width={150}
              alt=""
              className="br-50"
            />
            <div className="position-absolute labelFile">
              <label htmlFor="images" className="">
                <div className="editIcon">
                  <img src={edit} alt="" />
                </div>
                <input
                  type="file"
                  id="images"
                  name="profile_avatar"
                  accept=".png, .jpg, .jpeg"
                  hidden
                  onChange={(e) => handleimage(e)}
                />
              </label>
            </div>
          </div>

          <div className="paymentinfo">
            <Row>
              <Col lg={6}>
                <div className="profiledetail">
                  <span>
                    <svg
                      width="12"
                      height="17"
                      viewBox="0 0 12 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 16.5H10.5V15C10.5 14.4033 10.2629 13.831 9.84099 13.409C9.41903 12.9871 8.84674 12.75 8.25 12.75H3.75C3.15326 12.75 2.58097 12.9871 2.15901 13.409C1.73705 13.831 1.5 14.4033 1.5 15V16.5H0V15C0 14.0054 0.395088 13.0516 1.09835 12.3483C1.80161 11.6451 2.75544 11.25 3.75 11.25H8.25C9.24456 11.25 10.1984 11.6451 10.9017 12.3483C11.6049 13.0516 12 14.0054 12 15V16.5ZM6 9.75C5.40905 9.75 4.82389 9.6336 4.27792 9.40746C3.73196 9.18131 3.23588 8.84984 2.81802 8.43198C2.40016 8.01412 2.06869 7.51804 1.84254 6.97208C1.6164 6.42611 1.5 5.84095 1.5 5.25C1.5 4.65905 1.6164 4.07389 1.84254 3.52792C2.06869 2.98196 2.40016 2.48588 2.81802 2.06802C3.23588 1.65016 3.73196 1.31869 4.27792 1.09254C4.82389 0.866396 5.40905 0.75 6 0.75C7.19347 0.75 8.33807 1.22411 9.18198 2.06802C10.0259 2.91193 10.5 4.05653 10.5 5.25C10.5 6.44347 10.0259 7.58807 9.18198 8.43198C8.33807 9.27589 7.19347 9.75 6 9.75ZM6 8.25C6.79565 8.25 7.55871 7.93393 8.12132 7.37132C8.68393 6.80871 9 6.04565 9 5.25C9 4.45435 8.68393 3.69129 8.12132 3.12868C7.55871 2.56607 6.79565 2.25 6 2.25C5.20435 2.25 4.44129 2.56607 3.87868 3.12868C3.31607 3.69129 3 4.45435 3 5.25C3 6.04565 3.31607 6.80871 3.87868 7.37132C4.44129 7.93393 5.20435 8.25 6 8.25Z"
                        fill="#003B61"
                      />
                    </svg>
                    First Name
                  </span>
                  <input
                    type="text"
                    placeholder=""
                    name="firstName"
                    value={data?.firstName}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="profiledetail">
                  <span>Last Name</span>
                  <input
                    type="text"
                    placeholder=""
                    name="lastName"
                    value={data?.lastName}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="profiledetail">
                  <span>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.6">
                        <path
                          d="M2.25 2.25H15.75C15.9489 2.25 16.1397 2.32902 16.2803 2.46967C16.421 2.61032 16.5 2.80109 16.5 3V15C16.5 15.1989 16.421 15.3897 16.2803 15.5303C16.1397 15.671 15.9489 15.75 15.75 15.75H2.25C2.05109 15.75 1.86032 15.671 1.71967 15.5303C1.57902 15.3897 1.5 15.1989 1.5 15V3C1.5 2.80109 1.57902 2.61032 1.71967 2.46967C1.86032 2.32902 2.05109 2.25 2.25 2.25V2.25ZM15 5.4285L9.054 10.7535L3 5.412V14.25H15V5.4285ZM3.38325 3.75L9.04575 8.7465L14.6265 3.75H3.38325Z"
                          fill="#003B61"
                        />
                      </g>
                    </svg>
                    Email
                  </span>
                  <input
                    type="email"
                    placeholder=""
                    name="email"
                    value={data?.email}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="profiledetail">
                  <span>Contact Number</span>
                  <input
                    type="text"
                    placeholder=""
                    name="contactNumber"
                    value={data?.contactNumber}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={12}>
                <div className="profiledetail">
                  <span>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_392_6152)">
                        <path
                          d="M2.25 2.25H15.75C15.9489 2.25 16.1397 2.32902 16.2803 2.46967C16.421 2.61032 16.5 2.80109 16.5 3V15C16.5 15.1989 16.421 15.3897 16.2803 15.5303C16.1397 15.671 15.9489 15.75 15.75 15.75H2.25C2.05109 15.75 1.86032 15.671 1.71967 15.5303C1.57902 15.3897 1.5 15.1989 1.5 15V3C1.5 2.80109 1.57902 2.61032 1.71967 2.46967C1.86032 2.32902 2.05109 2.25 2.25 2.25V2.25ZM15 8.25H3V14.25H15V8.25ZM15 6.75V3.75H3V6.75H15ZM10.5 11.25H13.5V12.75H10.5V11.25Z"
                          fill="#003B61"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_392_6152">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    GP Credentials/Registration No.*
                  </span>
                  <input
                    type="text"
                    placeholder=""
                    name="address"
                    value={data?.address}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={12}>
                <div className="profiledetail">
                  <span>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.6">
                        <path
                          d="M15.75 15.0026C15.75 15.2016 15.671 15.3923 15.5303 15.533C15.3897 15.6736 15.1989 15.7526 15 15.7526H3C2.80109 15.7526 2.61032 15.6736 2.46967 15.533C2.32902 15.3923 2.25 15.2016 2.25 15.0026V7.12015C2.24992 7.00585 2.27596 6.89306 2.32614 6.79037C2.37631 6.68768 2.44929 6.59782 2.5395 6.52765L8.5395 1.86115C8.67116 1.75873 8.8332 1.70312 9 1.70312C9.1668 1.70312 9.32884 1.75873 9.4605 1.86115L15.4605 6.52765C15.5507 6.59782 15.6237 6.68768 15.6739 6.79037C15.724 6.89306 15.7501 7.00585 15.75 7.12015V15.0026V15.0026ZM14.25 14.2526V7.48615L9 3.40315L3.75 7.48615V14.2526H14.25Z"
                          fill="#003B61"
                        />
                      </g>
                    </svg>
                    Home Address
                  </span>
                  <input
                    type="text"
                    placeholder=""
                    name="address"
                    value={data?.address}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className="profiledetail">
                  <input
                    type="text"
                    placeholder=""
                    name="address"
                    value={data?.address}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className="profiledetail">
                  <input
                    type="text"
                    placeholder=""
                    name="address"
                    value={data?.address}
                    onChange={handleChange}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className="profiledetail">
                  <input
                    type="text"
                    placeholder=""
                    name="address"
                    value={data?.address}
                    onChange={handleChange}
                  />
                </div>
              </Col>

              <Col lg={12}>
                <div className="profiledetail mt-0">
                  <span>Bio*</span>
                  <textarea></textarea>
                </div>
              </Col>
              <Col lg={12}>
                <div className="editprofilebtn">
                  <Link className="redbtn" onClick={saveBtn}>
                    SAVE
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditProfile;
