import "./AddMedicine.css";
import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import SideDrawer from "../SideDrawer/SideDrawer";
import Header from "../Header/Header";

const AddMedicine = () => {
  return (
    <>
      <div className="chatscreen admindashboard">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col lg={10}>
            <Header />

            <div className="activepatient">
              <div className="titlepatient">
                <h4>Add Medicine </h4>
              </div>

              <div className="medicinlist">
                <Form>
                  <Row>
                    <Col lg={6}>
                      <div className="medicininput">
                        <span>Item No.</span>
                        <input type="text" placeholder="Enter No." />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="medicininput">
                        <span>Medicine Name</span>
                        <input
                          type="text"
                          placeholder="Enter Your Medicine Name"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="medicininput">
                        <span>Doctor of Repeats</span>
                        <input
                          type="text"
                          placeholder="Enter Treatment Quantity
"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="medicininput">
                        <span>Prescription Subscription Frequency</span>
                        <select>
                          <option>30 Days</option>
                          <option>30 Days</option>

                          <option>30 Days</option>
                          <option>30 Days</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="medicininput">
                        <span>Condition</span>
                        <input type="text" placeholder="Enter Condition" />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="medicininput">
                        <span>Provider</span>
                        <input
                          type="text"
                          placeholder="Infinity Wellness (SA)"
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="medicininput">
                        <span>Drug</span>
                        <input type="text" placeholder="Enter Drug" />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="medicininput">
                        <span>Form</span>
                        <input type="text" placeholder="Enter Form" />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="medicininput">
                        <span>Price</span>
                        <input type="text" placeholder="Price" />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="medicininput">
                        <span>Directions</span>
                        <input type="text" placeholder="Enter Direction" />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="medicininput">
                        <span>How do I take this treatment?</span>
                        <input type="text" placeholder="Information" />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="medicininput">
                        <span>How long does it take to work?</span>
                        <input type="text" placeholder="Treatment" />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="medicininput">
                        <span>How long does the treatment last?</span>
                        <input type="text" placeholder="Treatment" />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="medicininput">
                        <span>Are there any side effects?</span>
                        <input type="text" placeholder="Information" />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="medicininput">
                        <span>How do the ingredients work?</span>
                        <input type="text" placeholder="Work" />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="medicininput">
                        <span>Safety Information</span>
                        <input type="text" placeholder="Information" />
                      </div>
                    </Col>
                  </Row>
                  <div className="btnredsubmit">
                    <Button> Submit</Button>
                  </div>
                </Form>
              </div>
              <div className="coptrights">
                <p>©2023 Frenchie M.D Pty Ltd</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AddMedicine;
