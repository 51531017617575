import "./ChatScreenMobile.css";
import React, { useState, useEffect, useRef } from "react";
import profile3 from "../../Assets/images/chat/avatarBlue.png";
import { Row, Col} from "react-bootstrap";
import { Link, useLocation} from "react-router-dom";
import SideDrawer from "../SideDrawer/SideDrawer";
import Header from "../Header/Header";
import { useContext } from "react";
import { appContext } from "../../helpers/AppContext";
import Auth from "../../helpers/Auth";
import { GoogleLogin } from "react-google-login";
import { loadGapiInsideDOM } from "gapi-script";
import { ReactComponent as SingleTick } from "../../Assets/images/chat/singleTick.svg";
import { ReactComponent as DoubleTicks } from "../../Assets/images/chat/doubleTicks.svg";
import { ReactComponent as BlueTicks } from "../../Assets/images/chat/blueTicks.svg";
import Say_hi from "../../Assets/images/Say_hi.gif";
import moment from "moment";
import { TextareaAutosize } from "@mui/material";
import { RiArrowLeftSLine } from "react-icons/ri";
import {
  ApiGet,
  ApiGetMessage,
  ApiPost,
  Bucket,
} from "../../helpers/API/API_data";
import { AiOutlinePlus } from "react-icons/ai";
import { ErrorToast, SuccessToast } from "../../helpers/Toast";
import ChatLoader from "../../Component/Auth/ChatLoader/ChatLoader";
import { v4 as uuidv4 } from "uuid";
import { SlCalender } from "react-icons/sl";
import Autolinker from 'react-autolinker';

const ChatScreenMobile = () => {
  const clientId = process.env.REACT_APP_GOOGLE_CLOUD_SECTREAT_ID
  const { socket, loader, setLoader,  connectSocket, roomContext, setRoomContext } = useContext(appContext);
  const userData = Auth.getUserData();
  const uuid = uuidv4();
  const [message, setMessage] = useState("");
  const [chatData, setchatData] = useState([]);
  const [page, setPage] = useState(1);
  const [userMessage, setUserMessage] = useState([]);
  const [doctorRoomId, setDoctorRoomId] = useState();
  const [roomId, setRoomId] = useState("");
  const [name, setName] = useState();
  const [profile, setProfile] = useState({});
  const [data, setData] = useState({})

  const focusref = useRef();
  const messageEl = useRef(null);
  const elementRef = useRef(null);

  const location = useLocation();
  // get message id from useParams
  let messageId = window.location?.pathname.split("/")[2];

  const id = window.location?.pathname.split("/")[3];
// handle sent msg
  const receiverId = id;
  const handleSendMessage = (e, message) => {
    e.preventDefault();

    if (message.trim()) {
      socket?.emit("send_message", {
        senderId: userData?._id,
        roomId: doctorRoomId?._id ? doctorRoomId?._id : roomId,
        receiverId: receiverId,
        message: message.trim(),
      });
      setMessage("");
    }
  };



  useEffect(() => {
    connectSocket()
    setRoomId(messageId);
  }, []);

  // get msg when navigate from initiate chat 
  useEffect(() => {
    if (location?.state?.patientId) {
      const doctorRoomId = roomContext?.find(
        (ele) => ele?.user?._id === location?.state?.patientId
      );
      setDoctorRoomId(doctorRoomId);
      setRoomId(doctorRoomId?._id);
    }
  }, [roomContext]);

  const getRoomData = async () => {
    let body = {
      page: 1,
      limit: 10000,
    };
    await ApiPost("doctor/room/get", body)
      .then((res) => {
        setRoomContext(res?.data?.data?.room_data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const scrollToBottom = () => {
    if (elementRef.current) {
      elementRef.current.scrollToBottom = elementRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    // Call the scroll function after the component has rendered
    scrollToBottom();
  }, [page]);


  const handleKeySendMsg = (e) => {
    e.preventDefault();
    if (e.key === "Enter" && !e.shiftKey) {
      handleSendMessage(e, message);
    }
  };

  const sendermsg = [];
  const receivermsg = [];
  for (let i = 0; i < userMessage.length; i++) {
    sendermsg.push(
      userMessage[i - 1]?.senderId !== userData?._id &&
      userMessage[i]?.senderId === userData?._id &&
      userMessage[i]
    );
    receivermsg.push(
      userMessage[i - 1]?.senderId === userData?._id &&
      userMessage[i]?.senderId !== userData?._id &&
      userMessage[i]
    );
  }

  useEffect(() => {
    socket?.emit("online", { userId: userData?._id });
  }, [socket]);

  socket?.off("receive_message").on("receive_message", (data) => {
    socket?.emit("message_deliver_status", {
      messageId: data._id,
      status:
        data?.roomId === (doctorRoomId?._id ? doctorRoomId?._id : roomId) && data?.receiverId === userData?._id && window.location?.pathname !== "/ChatScreen"
          ? 2
          : 1,
      roomId: data?.roomId,
    });
    if (data?.roomId === roomId) {
      setUserMessage((prev) => {
        return JSON.parse(JSON.stringify([...prev, data]));
      });
    }
  });

  const onLoginSuccess = (res,selectedData) => {
    if (selectedData?.jsonMessage?.doctorEmail?.includes(res?.profileObj?.email)) {
      SuccessToast("Event Already Added")
      return 
    }
    let body = {
      accessToken: res?.tokenObj?.access_token,
      refreshToken: res?.tokenObj?.id_token,
      patientId: "",
      doctorId: profile?._id,
      addToCaledarId : selectedData?._id,
      userEmail: res?.profileObj?.email,
      isPatient:false
    };
    ApiPost("patient/google_login", body)
      .then((res) => {
        getProfile();
        addEvent(selectedData);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const onLoginFailure = (res) => {
  };
  useEffect(() => {
    (async () => {
      await loadGapiInsideDOM();
    })();
  });

  // get user msg
  const callmessage = async () => {
    socket?.emit("join_room", { roomId: roomId, userId: userData?._id });
    let body = {
      page: 1,
      limit: 20,
      roomId: roomId,
    };
    await ApiPost("doctor/room/message/get", body)
      .then((res) => {
        console.log("res", res);
        setUserMessage(res?.data?.data?.message_data.reverse());
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  // load msg with scroll
  const firstEvent = async (e) => {
    console.log("scroll", e.target.scrollTop);

    const element = e.target;
    const currentScrollPos = element.scrollTop;
    if (currentScrollPos === 0) {
      console.log("Reached the top");
      // Get the current scroll height before loading new data
      const prevScrollHeight = element.scrollHeight;
      const newScrollHeight = element.scrollHeight;
      // Calculate the difference in scroll height
      const scrollHeightDiff = newScrollHeight - prevScrollHeight;
      // Set the scroll position to maintain the user's position
      element.scrollTop = currentScrollPos + scrollHeightDiff;
      // Make your API call here
      await loadMoreMessages(page + 1);
    }
  };
  const loadMoreMessages = async (i) => {
    let body = {
      page: i,
      limit: 20,
      roomId: roomId,
    };

    try {
      const res = await ApiPost("doctor/room/message/get", body);
      const newMessages = res?.data?.data?.message_data.reverse();

      if (page >= res?.data?.data?.state?.page_limit) {
        console.log("condition");
        return;
      } else {
        setUserMessage((prevMessages) => [...newMessages, ...prevMessages]);
        setPage((page) => page + 1);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  // get room whole chat on rooms id change
  useEffect(() => {
    if (roomId && roomContext.length > 0) {
      const name = roomContext?.find((ele) => ele?.user?._id === id);
      setName(name);
      focusref?.current?.focus();
      callmessage();
    }



  }, [roomId, roomContext]);

  useEffect(() => {
    if (doctorRoomId) {
      const name = roomContext?.find(
        (ele) => ele?.user?._id === doctorRoomId.user?._id
      );
      setName(name);
      focusref?.current?.focus();

    }
  }, [doctorRoomId?._id]);

  // chat scroll to bottom
  const scrollToBottomWithSmoothScroll = () => {
    messageEl.current?.addEventListener("DOMNodeInserted", (event) => {
      const { currentTarget: target } = event;
      target.scroll({ top: target.scrollHeight, behavior: "auto" });
    });
  };

// handle video call before 10 min of schedule tim
  const isWithin10Minutes = (dateTime) => {
    console.log(dateTime);
    const givenDateTime = moment(dateTime, "DD-MM-YYYY HH:mm");
    console.log(givenDateTime);
    const currentDateTime = moment();
    console.log(currentDateTime);

    const differenceInMinutes = givenDateTime.diff(currentDateTime, "minutes");
    console.log(differenceInMinutes);

    return differenceInMinutes < 10;
  };

//  modify user chat messgage
  useEffect(() => {
    if (userMessage.length) {
      const temp = [];
      userMessage.forEach((ele, i) => {
        if (
          userMessage[i - 1]?.createdAt &&
          moment(userMessage[i - 1]?.createdAt).format("DD-MM-YYYY") ===
          moment(ele?.createdAt).format("DD-MM-YYYY")
        ) {
          temp[temp.length - 1].item.push(ele);
        } else {
          temp.push({
            date: ele?.createdAt,
            item: [ele],
          });
        }
        setchatData(temp);
      });
    } else {
      setchatData("");
    }
    scrollToBottomWithSmoothScroll();
  }, [userMessage]);

  // get profile
  const getProfile = () => {
    ApiGet("doctor")
      .then((res) => {
        setProfile(res?.data?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    getRoomData()
    getProfile();
  }, []);
  
  // add event in google calendar
  const addEvent = (res) => {
    const dateTime = moment(`${res?.jsonMessage?.videoCall?.videoCallDate} ${res?.jsonMessage?.videoCall?.videoCallTime}`, "DD-MM-YYYY hh:mm A");
    const formattedDateTime = dateTime?.format("YYYY-MM-DDTHH:mm:ss");
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    let body = {
      patientId: "",
      doctorId: profile?._id,
      addToCaledarId: res?._id,
      googleCalendarPayload: {
        start: {
          dateTime:formattedDateTime,
          timeZone,
        },
        end: {
          dateTime:formattedDateTime,
          timeZone,
        },
        summary: `Medicine Meeting with ${res?.jsonMessage?.doctor?.firstName} ${res?.jsonMessage?.doctor?.lastName}`,
        description: `Meeting URL ${window.location.protocol +
          "//" +
          window.location.hostname +
          (window.location.port ? ":" + window.location.port : "") +
          "/" +
          res?.jsonMessage?.videoLinkPath
          }`,
        conferenceData: {
          createRequest: {
            requestId: uuid,
            conferenceSolutionKey: {
              type: "eventHangout",
            },
            entryPoints: [
              {
                entryPointType: "video",
                uri: `${window.location.protocol +
                  "//" +
                  window.location.hostname +
                  (window.location.port ? ":" + window.location.port : "") +
                  "/" +
                  res?.jsonMessage?.videoLinkPath
                  }`,
              },
            ],
          },
        },
      },
      addToCaledarId: data?._id,
    };
    console.log("body", body);
    ApiPost("doctor/message/google/calendar", body)
      .then((res) => {
        SuccessToast(res?.data?.message);
        setData({})
        callmessage()
      })
      .catch((err) => {
        ErrorToast(err?.message);
        console.log("err", err);
      }).finally(()=>{setLoader(false)})
  };
  return (
    <>
      <div className="chatscreen admindashboard chat_with_doctor chat_screen_mobile">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col lg={10}>
            <Header />
            <div className="inner_div_dashboard" style={{ paddingTop: "70px" }}>
              <ul className="chatprofileheader">
                {/* back to room list screen  */}
                <li>
                  <Link
                    to={"/ChatScreen"}
                    className="chat_screen_arrow_mobile_link"
                  >
                    <RiArrowLeftSLine />
                  </Link>
                  <img
                    src={name?.user?.image ? name?.user?.image : profile3}
                    className="userAvatar"
                    alt=""
                  />
                  <div className="chatmeninfo">
                    <h4>
                      {name?.user?.firstName
                        ? name?.user?.firstName + " " + name?.user?.lastName
                        : "-"}
                    </h4>
                    <p>{name && name?.user?.email}</p>
                  </div>
                </li>
                <li>
                
                  <button className="bg-transparent border-0">
                  
                    <SlCalender
                      className="me-3 mt-1"
                      color="#003B61"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setMessage(
                          `Hi ${name?.user?.firstName
                            ? name?.user?.firstName +
                            " " +
                            name?.user?.lastName
                            : "-"
                          }, I’ve reviewed your information and it would be great to schedule a brief 10 min video call to confirm and chat through next steps. {Schedule your video consultation}`
                        )
                      }
                    />
                   
                  </button>
                
                </li>
              </ul>
              {loader ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <ChatLoader />
                </div>
              ) : (
                <div className="msgsends"
                  style={{
                    height: "76vh !important"
                  }}
                >
                  {!chatData.length && (
                    <div
                      className="says_hii_gif"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                      }}
                    >
                      <div className="modal_msg">
                        <img alt="" style={{ height: "50px" }} src={Say_hi} />
                        <p>
                          Say hi{" "}
                          {name?.user?.firstName
                            ? name?.user?.firstName + " " + name?.user?.lastName
                            : "-"}{" "}
                          with a wave
                        </p>
                        <button
                          className="button"
                          onClick={(e) => {
                            handleSendMessage(e, "Hi");
                          }}
                        >
                          Say hi
                        </button>
                      </div>
                    </div>
                  )}
                  <ul className="chats" ref={messageEl}
                    onScroll={firstEvent} id="chat-feed">
                    {chatData.length && chatData.length
                      ? chatData.map((item, i) => (
                        <>
                          <li className="display_date">
                            {moment(new Date()).format("DD-MM-YYYY") ===
                              moment(new Date(item?.date)).format("DD-MM-YYYY")
                              ? "Today"
                              : moment(new Date(item?.date)).format(
                                "DD-MM-YYYY"
                              )}
                          </li>
                          {item.item.map((res, i) => (
                            <>
                              {res.senderId === userData._id && (
                                <>
                                  {res?.jsonMessage?.doctor?._id ? (
                                    <li
                                      className=" mt-2 send_massage "
                                      key={`sender${i}`}
                                    >
                                      <div className="massage_sent">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "end",
                                          }}
                                        >
                                          <div
                                            style={{
                                              padding: "10px",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <div>
                                              <span className="p-0 fw-semibold">
                                                {
                                                  res?.jsonMessage?.patient
                                                    ?.firstName
                                                }{" "}
                                                {
                                                  res?.jsonMessage?.patient
                                                    ?.lastName
                                                }
                                              </span>{" "}
                                              Scheduled a meeting with{" "}
                                              <span className="p-0 fw-semibold">
                                                {
                                                  res?.jsonMessage?.doctor
                                                    ?.firstName
                                                }{" "}
                                                {
                                                  res?.jsonMessage?.doctor
                                                    ?.lastName
                                                }
                                              </span>
                                            </div>
                                            <div>
                                              <span className="p-0 fw-semibold">
                                                Date :
                                              </span>{" "}
                                              {
                                                res?.jsonMessage?.videoCall
                                                  ?.videoCallDate
                                              }
                                            </div>
                                            <div>
                                              <span className="p-0 fw-semibold">
                                                Time :
                                              </span>{" "}
                                              {
                                                res?.jsonMessage?.videoCall
                                                  ?.videoCallTime
                                              }
                                            </div>
                                            <div className="mt-3">
                                              {!isWithin10Minutes(
                                                `${res?.jsonMessage?.videoCall?.videoCallDate} ${res?.jsonMessage?.videoCall?.videoCallTime}`
                                              ) ? (
                                                <GoogleLogin
                                                clientId={clientId}
                                                className="w-93"
                                                buttonText="Sign In With Google"
                                                onSuccess={(ress) => onLoginSuccess(ress, res)}
                                                onFailure={onLoginFailure}
                                                scope="https://www.googleapis.com/auth/calendar"
                                                cookiePolicy={"single_host_origin"}
                                              render={(renderProps) => (
                                                <button className="Reschedule-button mx-1" id="google-login" onClick={() => { setData(res); renderProps.onClick() }}>
                                                  <AiOutlinePlus className="me-1" />
                                                  Add to Calendar
                                                </button>
                                              )}/>
                                              ) : (
                                                <button
                                                  className="join-button"
                                                  onClick={() =>
                                                    window.open(
                                                      window.location
                                                        .protocol +
                                                      "//" +
                                                      window.location
                                                        .hostname +
                                                      (window.location.port
                                                        ? ":" +
                                                        window.location
                                                          .port
                                                        : "") +
                                                      "/" +
                                                      res?.jsonMessage
                                                        ?.videoLinkPath,
                                                      "_blank"
                                                    )
                                                  }
                                                >
                                                  Join
                                                </button>
                                              )}
                                            </div>
                                         
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "end",
                                              gap: "5px",
                                              margin: "5px",
                                              minWidth: "45px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: "10px",
                                                padding: "0px",
                                                color: "darkgrey",
                                              }}
                                            >{`${new Date(
                                              res?.createdAt
                                            ).getHours()}:${new Date(
                                              res?.createdAt
                                            ).getMinutes()}`}</span>
                                            {res?.status === 0 ? (
                                              <SingleTick
                                                width="12px"
                                                height="12px"
                                              />
                                            ) : res?.status === 1 ? (
                                              <DoubleTicks
                                                width="12px"
                                                height="12px"
                                              />
                                            ) : (
                                              <BlueTicks
                                                width="16px"
                                                height="16px"
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="ms-2 me-2 "
                                        style={{
                                          height: "40px",
                                          width: "40px",
                                        }}
                                      >
                                        {sendermsg.includes(res) ? (
                                          <img
                                            src={
                                              userData?.image
                                                ? Bucket + userData?.image
                                                : profile3
                                            }
                                            alt="sender"
                                            className="rounded-pill"
                                            style={{
                                              height: "30px",
                                              width: "30px",
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </li>
                                  ) : (
                                    <li
                                      className=" mt-2 send_massage "
                                      key={`sender${i}`}
                                    >
                                      <div className="massage_sent">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "end",
                                          }}
                                        >
                                          <div
                                            style={{
                                              padding: "10px",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <Autolinker text={res?.message} />
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "end",
                                              gap: "5px",
                                              margin: "5px",
                                              minWidth: "45px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: "10px",
                                                padding: "0px",
                                                color: "darkgrey",
                                              }}
                                            >{`${new Date(
                                              res?.createdAt
                                            ).getHours()}:${new Date(
                                              res?.createdAt
                                            ).getMinutes()}`}</span>
                                            {res?.status === 0 ? (
                                              <SingleTick
                                                width="12px"
                                                height="12px"
                                              />
                                            ) : res?.status === 1 ? (
                                              <DoubleTicks
                                                width="12px"
                                                height="12px"
                                              />
                                            ) : (
                                              <BlueTicks
                                                width="16px"
                                                height="16px"
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="ms-2 me-2 "
                                        style={{
                                          height: "40px",
                                          width: "40px",
                                        }}
                                      >
                                        {sendermsg.includes(res) ? (
                                          <img
                                            src={
                                              userData?.image
                                                ? Bucket + userData?.image
                                                : profile3
                                            }
                                            alt="sender"
                                            className="rounded-pill"
                                            style={{
                                              height: "30px",
                                              width: "30px",
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </li>
                                  )}
                                </>
                              )}
                              {res.senderId !== userData._id && (
                                <>
                                  {res?.jsonMessage?.doctor?._id ? (
                                    <li
                                      className="mt-2 receiv_massage"
                                      key={`receiver${i}`}
                                    >
                                      <div
                                        className="chat_screen_user_image_chat_discuss_text_message"
                                        style={{
                                          height: "40px",
                                          width: "40px",
                                          marginRight: "8px",
                                        }}
                                      >
                                        <img
                                          src={
                                            name?.user?.image
                                              ? name?.user?.image
                                              : profile3
                                          }
                                          alt="receiver"
                                          className="rounded-pill"
                                          style={{
                                            height: "31px",
                                            width: "31px",
                                          }}
                                        />
                                      </div>
                                      <div
                                        className="massage_sent"
                                        style={{
                                          backgroundColor: "#e3f8ff",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "end",
                                          }}
                                        >
                                          <div
                                            style={{
                                              margin: "5px",
                                              minWidth: "26px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: "10px",
                                                padding: "0px",
                                                backgroundColor: "#e3f8ff",
                                                color: "darkgrey",
                                              }}
                                            >{`${new Date(
                                              res?.createdAt
                                            ).getHours()}:${new Date(
                                              res?.createdAt
                                            ).getMinutes()}`}</span>
                                          </div>
                                          <div
                                            className="receive_message"
                                            style={{
                                              padding: "10px",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <div>
                                              <span className="p-0 fw-semibold">
                                                {
                                                  res?.jsonMessage?.patient
                                                    ?.firstName
                                                }{" "}
                                                {
                                                  res?.jsonMessage?.patient
                                                    ?.lastName
                                                }
                                              </span>{" "}
                                              Scheduled a meeting with{" "}
                                              <span className="p-0 fw-semibold">
                                                {
                                                  res?.jsonMessage?.doctor
                                                    ?.firstName
                                                }{" "}
                                                {
                                                  res?.jsonMessage?.doctor
                                                    ?.lastName
                                                }
                                              </span>
                                            </div>
                                            <div>
                                              <span className="p-0 fw-semibold">
                                                Date :
                                              </span>{" "}
                                              {
                                                res?.jsonMessage?.videoCall
                                                  ?.videoCallDate
                                              }
                                            </div>
                                            <div>
                                              <span className="p-0 fw-semibold">
                                                Time :
                                              </span>{" "}
                                              {
                                                res?.jsonMessage?.videoCall
                                                  ?.videoCallTime
                                              }
                                              (EST)
                                            </div>
                                            <div className="mt-3">
                                              {!isWithin10Minutes(
                                                `${res?.jsonMessage?.videoCall?.videoCallDate} ${res?.jsonMessage?.videoCall?.videoCallTime}`
                                              ) ? (
                                                <GoogleLogin
                                                clientId={clientId}
                                                className="w-93"
                                                buttonText="Sign In With Google"
                                                onSuccess={(ress) => onLoginSuccess(ress, res)}
                                                onFailure={onLoginFailure}
                                                scope="https://www.googleapis.com/auth/calendar"
                                                cookiePolicy={"single_host_origin"}
                                              render={(renderProps) => (
                                                <button className="Reschedule-button mx-1" id="google-login" onClick={() => { setData(res); renderProps.onClick() }}>
                                                  <AiOutlinePlus className="me-1" />
                                                  Add to Calendar
                                                </button>
                                              )}
                                              />
                                              ) : (
                                                <button
                                                  className="join-button"
                                                  onClick={() =>
                                                    window.open(
                                                      window.location
                                                        .protocol +
                                                      "//" +
                                                      window.location
                                                        .hostname +
                                                      (window.location.port
                                                        ? ":" +
                                                        window.location
                                                          .port
                                                        : "") +
                                                      "/" +
                                                      res?.jsonMessage
                                                        ?.videoLinkPath,
                                                      "_blank"
                                                    )
                                                  }
                                                >
                                                  Join
                                                </button>
                                              )}
                                            </div>
                                          
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  ) : (
                                    <li
                                      className="mt-2 receiv_massage"
                                      key={`receiver${i}`}
                                    >
                                      <div
                                        className="chat_screen_user_image_chat_discuss_text_message"
                                        style={{
                                          height: "40px",
                                          width: "40px",
                                          marginRight: "8px",
                                        }}
                                      >
                                        <img
                                          src={
                                            name?.user?.image
                                              ? name?.user?.image
                                              : profile3
                                          }
                                          alt="receiver"
                                          className="rounded-pill"
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                          }}
                                        />
                                      </div>
                                      <div
                                        className="massage_sent"
                                        style={{
                                          backgroundColor: "#e3f8ff",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "end",
                                          }}
                                        >
                                          <div
                                            style={{
                                              margin: "5px",
                                              minWidth: "26px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: "10px",
                                                padding: "0px",
                                                backgroundColor: "#e3f8ff",
                                                color: "darkgrey",
                                              }}
                                            >{`${new Date(
                                              res?.createdAt
                                            ).getHours()}:${new Date(
                                              res?.createdAt
                                            ).getMinutes()}`}</span>
                                          </div>
                                          <div
                                            className="receive_message"
                                            style={{
                                              padding: "10px",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <Autolinker text={res?.message} />
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  )}
                                </>
                              )}
                            </>
                          ))}
                        </>
                      ))
                      : ""}
                  </ul>
                  <div
                    className="entermsgsend entermsgsends"
                    style={{
                      position: "unset",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <TextareaAutosize
                      ref={focusref}
                      style={{ padding: "15px 20px" }}
                      minRows={1}
                      maxRows={5}
                      placeholder={`Write a message for ${name?.user?.firstName
                        ? name?.user?.firstName + " " + name?.user?.lastName
                        : "-"
                        } `}
                      value={message}
                      onChange={(e) => {
                        socket.emit("is_user_typing", true);
                        setTimeout(() => {
                          socket.emit("is_user_typing", false);
                        }, 2000);
                        setMessage(e?.target?.value);
                      }}
                      onKeyUp={(e) => handleKeySendMsg(e)}
                      className={message ? "set_button" : "set_input"}
                    />
                    {message.length ? (
                      <Link
                        onClick={(e) => {
                          handleSendMessage(e, message);
                        }}
                        style={{ position: "unset" }}
                      >
                        Send
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ChatScreenMobile;
