import React, { createContext, useState } from "react";
import socketIO from "socket.io-client";
import config from "../config/API/api-dev";
import { useEffect } from "react";

export const appContext = createContext();

const AppProvider = ({ children }) => {
  const [patientProfileData, setPatientProfileData] = useState("");
  const [treatmentIds, setTreatmentIds] = useState([]);
  const [loader, setLoader] = useState(false);
  const [rooms, setRooms] = useState();
  const [UnreadMsg, setUnreadMsg] = useState();
  const [totalPatient, setTotalPatient] = useState([]);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 576);
  const [socket, setSocket] = useState(null);
  const [roomContext, setRoomContext] = useState([]);
  const [isCostpmerSupportOpen, setIsCostpmerSupportOpen] = useState(false)
  const [unreadData, setUnreadData] = useState();

  // itemPerPage for Pagination data
  const itemPerPage = 10;

  useEffect(() => {
    window.addEventListener("resize", () => {
      const mobile = window.innerWidth > 576;
      if (isTablet) setIsTablet(mobile);
    });
  }, [isTablet]);

  const connectSocket = () => {
    if (!socket) {
      const socket1 = socketIO.connect(`${config.endpoint}/room`);
      setSocket(socket1);
    }
  };

  const getRoom = () => {
    socket.off("get_rooms").on("get_rooms", async (rooms) => {
      rooms?.rooms &&
        rooms?.rooms.map((res) => {
          socket.emit("join_room", {
            roomId: res?._id,
            userId: res?.user?._id,
          });
        });
      setRooms(rooms?.rooms);
    });
  };

  return (
    <appContext.Provider
      value={{
        patientProfileData,
        setPatientProfileData,
        treatmentIds,
        setTreatmentIds,
        socket,
        loader,
        setLoader,
        getRoom,
        rooms,
        UnreadMsg,
        setUnreadMsg,
        setTotalPatient,
        totalPatient,
        connectSocket,
        isTablet,
        setIsTablet,
        itemPerPage,
        roomContext, setRoomContext,
        setIsCostpmerSupportOpen,
        isCostpmerSupportOpen,
        setUnreadData,
        unreadData

      }}
    >
      {children}
    </appContext.Provider>
  );
};

export default AppProvider;
