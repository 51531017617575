import React from 'react'
import './loader.css'
function Loader() {
    return (
        <>
            <div id="cssLoader17" class="main-wrap main-wrap--white">
                <div class="cssLoader17"></div>
            </div>

        </>
    )
}

export default Loader