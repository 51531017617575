import "./QuestionSummary.css";
import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import SideDrawer from "../SideDrawer/SideDrawer";
import { ApiPost } from "../../helpers/API/API_data";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import { appContext } from "../../helpers/AppContext";

const QuestionSummary = ({isMobile}) => {
  const { setLoader,  } = useContext(appContext);

  const [patientQuestion, setpatientQuestion] = useState("");
  const location = useLocation();
  const patientId = location?.state?.state?.id
  const history = useHistory();

  // get patient consult question detail
  useEffect(() => {
    getPatientDetails();
  }, []);

  const getPatientDetails = async () => {
    const body = {
      _id: location?.state?.state?.id,
      condition: location?.state?.state?.condition === "STI Screening" ? "STI/STD" : location?.state?.state?.condition,
    };
    await ApiPost(`doctor/patient`, body)
      .then((res) => {
        let data = res?.data?.data?.[0]?.patient_questions.find(
          (item, index) => index === location?.state?.state?.ind
        );
        setpatientQuestion(data);
      })
      .catch((e) => {
        console.log("e", e);
      });
  };
  // initiate chat and navigate to chat screen
  const navigateInitiateChat = async () => {
    setLoader(true);
    let body = {
      search: "",
      page: 1,
      limit: 100000,
    };
    await ApiPost("doctor/room/get", body)
      .then((res) => {
        const userRoomId = res?.data?.data?.room_data?.find(
          (ele, i) => ele?.user?._id === patientId
        );
        if (userRoomId) {
          isMobile
            ? history.push(`/ChatScreen`, { patientId })
            : history.push(`/ChatScreenMobile/id/${patientId}`, { patientId });
        } else {
          ApiPost("doctor/add_doctor_room_with_patient", { patientId })
            .then((res) => {
              if (res?.data?.data?.userIds?.length) {
               isMobile
                  ? history.push(`/ChatScreen`, { patientId})
                  : history.push(`/ChatScreenMobile/id/${patientId}`, { patientId });
              }
            })
            .catch((err) => {})
            .finally(() => {
              setLoader(false);
            });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoader(false);
      });
  };
  return (
    <>
      <div className="chatscreen admindashboard question_summary_main_sec">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col lg={10}>
            <Header />
            <div className="activepatient admindash question_summary_inner_div">
              <div className="summrytitle">
                <h2>Question Summary</h2>
                <div className="quesumconsul">
                  <Link
                    onClick={() => {
                      history.push(`/ViewConsultation/${location?.state?.state?.id}`, {
                        state: {
                          id: location?.state?.state?.id,
                          condition: location?.state?.state?.condition,
                          consultationId:location?.state?.state?.consultationId ,
                        },
                      });
                    }}
                    className="chatrequest"
                  >
                    Back to consult
                  </Link>
                  <Link
                    onClick={navigateInitiateChat
                    }
                    className="chatrequest"
                  >
                    Chat with patient
                  </Link>
                </div>
              </div>
              <div className="questionlist questionary">
                <div className="questionmain questionmain__main">
                  <Row>
                    {patientQuestion?.questions &&
                      patientQuestion?.questions.slice(1).map((item, i) => {
                        if (item !== null) {
                          return (<Col lg={6}>
                            <ul
                              key={`${i}${item.question}`}
                              className="questionmainlist questionmainlist__inner"
                            >
                              <div className="questionmain__header">
                                <span style={{ minWidth: "30px" }}>{i + 1}</span>{" "}
                                <h1>{item?.question}</h1>
                              </div>
                              <div className="questionmain__body">
                                {item?.answer === "undefined"
                                  ? "There is nothing to say"
                                  : item?.answer}
                              </div>
                            </ul>
                          </Col>)
                        }


                      })}
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default QuestionSummary;
