import React, { useContext, useEffect, useState } from "react";
import history from "../../Assets/images/history.png";
import add from "../../Assets/images/addfile.png";
import "./ViewConsultationDocument.css";
import { APIUploadPost, ApiGet, ApiPut } from "../../helpers/API/API_data";
import { ErrorToast, SuccessToast } from "../../helpers/Toast";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { appContext } from "../../helpers/AppContext";
import Loader from "../../Component/Auth/loader/Loader";

function ViewConsultationDocument() {
  const [documentget, setDocumentGet] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState();
  const { loader, setLoader } = useContext(appContext);

  useEffect(() => {
    getdata();
  }, []);

  const fileUpload = (e) => {
    setLoader(true);
    let extensions = e.target.files[0].type;
    let extensionsValidation = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
    ];
    if (extensionsValidation.includes(extensions)) {
      if (e?.target?.files && e?.target?.files[0]) {
        let formData = new FormData();
        formData.append("image", e.target.files[0]);
        APIUploadPost(`upload/document`, formData)
          .then((res) => {
            if (res.status === 200) {
              setLoader(false);
              SuccessToast("document upload successfully");
              const body = {
                _id: window.location.pathname?.split("/")[2],
                documents: [res?.data?.data?.image],
              };
              ApiPut(`doctor/upload_patient_document`, body)
                .then((res) => {
                  getdata();
                })
                .catch((e) => {
                  console.log("e", e);
                });
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    } else {
      setLoader(false);
      ErrorToast("Please select valid document file");
    }
  };

  const getdata = () => {
    ApiGet(`doctor/patient_document/${window.location.pathname?.split("/")[2]}`)
      .then((res) => {
        setDocumentGet(res?.data?.data?.[0]?.documents.reverse());
      })
      .catch((e) => {
        console.log("e", e);
      });
  };
  const HandleView = (data) => {
    const pdfData = data
      ?.split("/")
      ?.[data?.split("/")?.length - 1].split(".")?.[1];
    if (pdfData === "pdf" || pdfData === "docx") {
      window.open(data);
    } else if (pdfData !== "pdf" && pdfData !== "docx") {
      setData(data);
      setIsOpen(true);
    }
  };

  return (
    <div className="mytreatmentd">
      <div className="mytreatmentheading">
        {/* <h4>Patient Documents </h4> */}
        <div className="patienttitle1">Patient Documents </div>
      </div>
      <div className="upload ">
        <div className="file_img ">
          <img src={add} alt="" />
          <p>Upload a document</p>
        </div>
        <input
          type="file"
          onChange={(e) => fileUpload(e)}
          onClick={(e) => (e.currentTarget.value = null)}
        />
      </div>
      <ul
        className="uploaddata"
        style={{ height: `${documentget?.length > 0 ? "99px" : "30px"}` }}
      >
        {documentget?.length > 0 ? (
          documentget.map((vel, i) => {
            return (
              <li key={`${i}${vel}`}>
                <div
                  className="uploaddetail d-flex"
                  onClick={() => HandleView(vel)}
                >
                  <div>
                    <img src={history} alt="" />
                  </div>
                  <p className="uploadget ms-2">
                    {vel.split("/").at(-1).split("_")[0]}
                  </p>
                </div>
              </li>
            );
          })
        ) : (
          <span
            className="d-flex justify-content-center"
            style={{ fontFamily: "Helvetica Neue" }}
          >
            There are no documents
          </span>
        )}
      </ul>
      {isOpen && (
        <Lightbox
          // imageTitle={images[imgIndex].title}
          // imageCaption={ele[imgIndex].caption}
          mainSrc={data}
          // nextSrc={images?.[(imgIndex + 1) % images?.length]}
          // prevSrc={images?.[(imgIndex + images?.length - 1) % images?.length]}
          onCloseRequest={() => setIsOpen(false)}
        // onMovePrevRequest={() => setImgIndex((imgIndex + images?.length - 1) % images?.length)}
        // onMoveNextRequest={() => setImgIndex((imgIndex + 1) % images?.length)}
        />
      )}
      {loader && <Loader />}
    </div>
  );
}

export default ViewConsultationDocument;
