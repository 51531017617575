/**
 * The code defines a React component called TableListCard that renders different components based on
 * the data passed to it.
 * @returns The TableListCard component is being returned.
 */
import React from "react";
import moment from "moment";
import "./tableCard.css";
import { Checkbox } from "antd";

/**
 * The TableListCard component conditionally renders different components based on the presence of
 * specific data properties.
 * @returns The TableListCard component is returning a conditional rendering of different components
 * based on the presence of certain properties in the "data" object. If the "data" object has a
 * "consultation" property, it renders the "Consulation" component. If it has a "patient" property, it
 * renders the "Patient" component. If it has a "pathology" property, it renders the
 */
const TableListCard = ({ data, setSelectedRows = () => { }, selectedRows = [] }) => {
  return (
    <>
      {data?.consultation ? <Consulation data={data} /> : null}
      {data?.patient ? <Patient data={data} /> : null}
      {data?.pathology ? <Pathology data={data} /> : null}
      {data?.subscriptionModel ? <Subscription data={data} setSelectedRows={setSelectedRows} selectedRows={selectedRows} /> : null}
    </>
  );
};

export default TableListCard;

/**
 * The `Consulation` function is a React component that renders a list item with various data fields
 * and a checkbox for selection.
 * @param props - The `props` parameter is an object that contains the data passed to the `Consulation`
 * component. It is used to access the data properties within the component.
 * @returns a JSX element, specifically a list item (`<li>`) with various nested elements and
 * components.
 */
function Consulation(props) {
  console.log("propsres", props);

  const [isCheck, setIsCheck] = React.useState([]);


  const handleSelectBox = (checked, id) => {

    if (checked) {
      setIsCheck([...isCheck, id]);
    } else {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };


  return (
    <li className="consulation_list_li_data">
      <div className="consulation_list_li_data_head flex">
        <div
          style={{
            marginRight: "8px",
          }}
        >
          <Checkbox
            checked={isCheck.includes(props.data?.id)}
            onChange={(e) => handleSelectBox(e.target.checked, props.data?.id)}
          />
        </div>
        <h6 className="consulation_list_li__head">{props.data?.name}</h6>
      </div>
      <div className="consulation_list_li_data_inner">
        <div className="consulation_list_data_left">
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Consultation Number
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {props.data.consultno}
            </h6>
          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Date ( Patient )
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {moment(props.data.patientJoiningDate).format("L")}
            </h6>
          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Date ( Doctor )
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {moment(props.data.doctorAssignDate).format("L")}
            </h6>
          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Dr Repone Time
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {props.data?.duration}
            </h6>
          </div>
        </div>
        <div className="consulation_list_data_right">
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Time ( Patient )
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {moment(props.data.patientJoiningDate).format("LT")}
            </h6>
          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Time ( Doctor )
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {moment(props.data.doctorAssignDate).format("LT")}
            </h6>
          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Consult Status
            </h6>
            {props?.data?.consultStatus === 4 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#a52a2a1a", color: "#a52a2a" }}
              >
                {"Treatment Plan Sent"}
              </h6>
            ) : props?.data?.consultStatus === 1 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#6abddf1a", color: "#6ABDDF" }}
              >
                {"Paid Unassigned Doctor"}
              </h6>
            ) : props?.data?.consultStatus === 2 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#220a0a1a", color: "#220a0a" }}
              >
                {"Paid Unfulfilled"}
              </h6>
            ) : props?.data?.consultStatus === 3 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#0080001a", color: "#008000" }}
              >
                {"Live Consult"}
              </h6>
            ) : props?.data?.consultStatus === 0 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#ff00001a", color: "#ff0000" }}
              >
                {"Not Paid"}
              </h6>
            ) : props?.data?.consultStatus === 5 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#045fbf1a", color: "#045fbf" }}
              >
                {"Awaiting Pathology"}
              </h6>
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
    </li>
  );
}
function Patient(props) {
  return (
    <li className="consulation_list_li_data">
      <div className="consulation_list_li_data_head flex">
        <h6 className="consulation_list_li__head">{props.data?.name}</h6>
      </div>
      <div className="consulation_list_li_data_inner">
        <div className="consulation_list_data_left">
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Phone Number
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {props.data?.contactNumber}
            </h6>
          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">Email</h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {props.data?.email}
            </h6>
          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">Status</h6>
            {props?.data?.consultStatus === 4 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#ff00001a", color: "#ff0000" }}
              >
                {"Not Paid"}
              </h6>
            ) : props?.data?.consultStatus === 5 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#220a0a1a", color: "#220a0a" }}
              >
                {"Paid Unfulfilled"}
              </h6>
            ) : props?.data?.consultStatus === 6 ? (
              <h6
                className="consulation_list_li_data_inner_head_data status"
                style={{ backgroundColor: "#8b01011a", color: "#8b0000" }}
              >
                {"paid Fulfilled"}
              </h6>
            ) : (
              "-"
            )}
          </div>
        </div>
        <div className="consulation_list_data_right">
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">Time</h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {props.data.time}
            </h6>
          </div>

          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">Created At</h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {props.data.createdAt}
            </h6>
          </div>
        </div>
      </div>
    </li>
  );
}


function Pathology(props) {
  const [isCheck, setIsCheck] = React.useState([]);

  const handleSelectBox = (checked, id) => {
    if (checked) {
      setIsCheck([...isCheck, id]);
    } else {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  return (
    <li className="consulation_list_li_data">
      <div className="consulation_list_li_data_head flex">
        <div
          style={{
            marginRight: "8px",
          }}
        >
          <Checkbox
            checked={isCheck.includes(props.data?.id)}
            onChange={(e) => handleSelectBox(e.target.checked, props.data?.id)}
          />
        </div>
        <h6 className="consulation_list_li__head">{props.data?.name}</h6>
      </div>
      <div className="consulation_list_li_data_inner1">
        <div className="consulation_list_data_left">
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">Item Number</h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {props.data?.itemNumber}
            </h6>
          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">Kit Name</h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {props.data?.kitName}
            </h6>
          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Report Type - Pathology Result
            </h6>

            {/* {props.data?.pathologyResult} */}
            {
              props.data?.newPatholgyResult?.length ?
                props.data?.newPatholgyResult?.map((item, i) => {
                  return (
                    <>
                      <div style={{ alignItems: 'start', wordWrap: 'break-word' }}>
                        <span className="consulation_list_li_data_inner_head_data"> {item?.pathologyName} -</span>
                        <span className={`${item?.result === "Not Detected"
                          ? "text-success"
                          : item?.result === "Detected"
                            ? "text-danger"
                            : ""
                          } consulation_list_li_data_inner_head_data`}
                        // className="consulation_list_li_data_inner_head_data"
                        > {item?.result} </span>
                      </div>
                      <span>{props.data?.newPatholgyResult?.length !== i + 1 && " "}</span>
                    </>
                  )
                }) : <h6 className="consulation_list_li_data_inner_head_data" >{props.data?.reportType} - Waiting</h6>
            }

          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Date Recevied
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {
                props.data?.newPatholgyResult?.length ?
                  props.data?.newPatholgyResult?.map((item, i) => {
                    return (
                      <>
                        <div style={{ alignItems: 'start', wordWrap: 'break-word' }}>
                          <span className="consulation_list_li_data_inner_head_data">{moment(item.dateReceived).format("LLL")}</span>

                        </div>
                        <span>{props.data?.newPatholgyResult?.length !== i + 1 && " "}</span>
                      </>
                    )
                  }) : <h6 className="consulation_list_li_data_inner_head_data" >-</h6>
              }
            </h6>
          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Date Ordered
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {props?.data?.createdDate?.length > 0 ?
                <div> {props?.data?.createdDate} </div> : "-"}
            </h6>
          </div>
        </div>
        <div className="consulation_list_data_right">
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Panel Description
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {props.data?.kitDescription}
            </h6>
          </div>
        </div>
      </div>
    </li>
  );
}

/**
 * The `Subscription` component renders a list item with various data fields and checkboxes for
 * selecting items.
 * @returns The `Subscription` component is returning a JSX element, specifically a `<li>` element with
 * nested elements and data being rendered dynamically based on the `data` prop passed to the
 * component.
 */
const Subscription = ({ data, selectedRows = [], setSelectedRows = () => { } }) => {
 

  const handleSelectBox = (checked, id) => {
    if (checked) {
      setSelectedRows([...selectedRows, id]);
    }
    else { setSelectedRows(selectedRows.filter((item) => item !== id)); }
  };

  return (
    <li className="consulation_list_li_data">
      <div className="consulation_list_li_data_head flex">
        <h6 className="consulation_list_li__head">{data?.index}</h6>
        <div
          style={{
            margin: "0 8px",
          }}
        >
          <Checkbox
            checked={selectedRows.includes(data?.id)}
            onChange={(e) => handleSelectBox(e?.target?.checked, data?.id)}
          />
        </div>
        <h6 className="consulation_list_li__head">{data?.name}</h6>
      </div>
      <div className="consulation_list_li_data_inner">
        <div className="consulation_list_data_left">
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Treatment Name
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.treatment}
            </h6>
          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Prescription Expiry
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.expireDate}
            </h6>
          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">Action</h6>
            {data?.buttons}
          </div>
        </div>
        <div className="consulation_list_data_right">
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Number Of Repeats
            </h6>
            <h6 className="consulation_list_li_data_inner_head_data">
              {data?.number_of_repeats}
            </h6>
          </div>
          <div className="consulation_list_data_left_inner">
            <h6 className="consulation_list_li_data_inner_head">
              Prescription Status
            </h6>
            {data?.isSigned}
          </div>
        </div>
      </div>
    </li>
  );
};
