import "./Medicine.css";
import React, {  useContext, useEffect, useState } from "react";
import {  Row, Col, Form } from "react-bootstrap";
import {  useLocation } from "react-router-dom";
import SideDrawer from "../SideDrawer/SideDrawer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ApiGet, ApiPost } from "../../helpers/API/API_data";
import { useHistory } from "react-router-dom";
import Imgpatient from "../../Assets/images/delete.png";
import { appContext } from "../../helpers/AppContext";
import Header from "../Header/Header";
import Loader from "../../Component/Auth/loader/Loader";
import { ErrorToast } from "../../helpers/Toast";


const AddTreatment = (props) => {
  const { setTreatmentIds } = useContext(appContext);
  const history = useHistory();
  const location = useLocation();
  const [addTreatment, setAddTreatment] = useState([0]);
  const [getData, setgetData] = useState("");
  const [detailInformation, setdetailInformation] = useState("");
  const [providerData, setProviderData] = useState([]);
  const [selectDatas, setSelectDatas] = useState();
  const [apidata, setApiData] = useState([]);
  const [loader, setLoader] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);

  const id = window.location.pathname?.split("/")[2];
  const name = searchParams.get("name")
    ? searchParams.get("name").split("+").join(" ")
    : "";
// get treatment
  useEffect(() => {
    getTreatment();
  }, []);

  // shcema for add treatement form
  const signupSchema = yup.object().shape({
    treatment: yup.array().of(
      yup.object().shape({
        treatmentName: yup.string().required("Treatment Name is required"),
        directions: yup.string().required("Directions is required").nullable(),
        provider: yup.lazy((val) =>
          Array.isArray(val) === true
            ? yup.array().required("Providerwersdfwef is required").nullable()
            : yup.string().required("Provider is required").nullable()
        ),

     
      })
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(signupSchema),
  });

  // assign treatment
  const submitData = (data) => {
    setLoader(true);
    setdetailInformation((prev) => {
      return [
        ...prev,
        {
          drugName: selectDatas?.drugName,
          treatMentName: selectDatas?.treatmentName,
          detail_information: [...selectDatas?.detail_information],
        },
      ];
    });
    const body = {
      treatment:
        addTreatment &&
        addTreatment.map((val) => {
          delete apidata?.[val]?._id;
          return {
            ...apidata?.[val],
            treatmentName: data?.treatment?.[val]?.treatmentName,
            number_of_repeats: parseInt(
              data?.treatment?.[val]?.number_of_repeats
            ),
            subscription_frequency: parseInt(
              data?.treatment?.[val]?.subscription_frequency
            ),
            condition: name,
            directions: data?.treatment?.[val]?.directions,
            active_drug: data?.treatment?.[val]?.active_drug,
            form: data?.treatment?.[val]?.form,
            price: parseInt(data?.treatment?.[val]?.price),
            provider: data?.treatment?.[val]?.provider,
            monthly_subscription_cost: parseInt(
              data?.treatment?.[val]?.monthly_subscription_cost
            ),
            totalMonthDuration: parseInt(
              data?.treatment?.[val]?.totalMonthDuration
            ),
          };
        }),
      patientId: `${window.location.pathname?.split("/")[2]}`,
      consultationId:location?.state?.state?.consultationId
    };
    const bodies = body?.treatment.map(
      (ele) => ele.directions !== "" && ele.treatmentName
    );
    const ggg = detailInformation.filter((ele) =>
      bodies.includes(ele.treatMentName)
    );

    ApiPost(`doctor/treatment`, body)
      .then((res) => {

        setLoader(false);
        history.push(`/TreatmentList`, {
          state: { detailInformation: ggg, id: id, Name: name },
        });
        setTreatmentIds(res?.data?.data?.treatmentIds);
      })
      .catch((e) => {
        ErrorToast(e?.message)
        setLoader(false);
      });
  };

  // get treatment
  const getTreatment = () => {
    ApiGet(`doctor/treatment`).then((res) => {
      setgetData(res?.data?.data);
    });
  };

  const deleteForm = (id) => {
    let data = addTreatment.filter((data, i) => i !== id);
    setAddTreatment(data);
  };
  return (
    <>
      <div className="chatscreen admindashboard">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col lg={10}>
            <Header />

            <div className="activepatient">
              <div className="titlepatient">
                <h4>Add Treatment </h4>
              </div>

              <div className="medicinlist">
                <Form>
                  <Row>
                    {addTreatment?.map((val, i) => {
                      return (
                        <>
                         
                          {i === 0 ? (
                            ""
                          ) : (
                            <div style={{ textAlign: "end" }}>
                              <img
                                src={Imgpatient}
                                alt=""
                                onClick={() => deleteForm(i)}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          )}
                          <Col lg={12}>
                            <div className="medicininput">
                              <span>Treatment Name</span>
                              <select
                                {...register(
                                  `treatment.${[val]}.treatmentName`
                                )}
                                onChange={(e) => {
                                  clearErrors(
                                    `treatment.${[val]}.treatmentName`
                                  );

                                  const selectData = getData
                                    ? getData?.find(
                                      (item) =>
                                        item.treatmentName === e.target.value
                                    )
                                    : {};
                                  setSelectDatas(selectData);

                                  const dummyApiData = apidata;
                                  dummyApiData[val] = selectData;
                                  setApiData(dummyApiData);

                                  setProviderData(selectData?.provider);
                                  setValue(
                                    `treatment.${[val]}.number_of_repeats`,
                                    selectData?.number_of_repeats
                                  );
                                  setValue(
                                    `treatment.${[val]}.subscription_frequency`,
                                    selectData?.subscription_frequency
                                  );
                                  setValue(
                                    `treatment.${[val]}.directions`,
                                    selectData?.directions
                                  );
                                  setValue(
                                    `treatment.${[val]}.active_drug`,
                                    selectData?.drugName
                                  );
                                  setValue(
                                    `treatment.${[val]}.form`,
                                    selectData?.form
                                  );
                                  setValue(
                                    `treatment.${[val]}.price`,
                                    selectData?.price
                                  );
                                  setValue(
                                    `treatment.${[val]}.provider`,
                                    selectData?.provider
                                  );
                                  setValue(
                                    `treatment.${[
                                      val,
                                    ]}.monthly_subscription_cost`,
                                    selectData?.monthly_subscription_cost
                                  );
                                  setValue(
                                    `treatment.${[val]}.totalMonthDuration`,
                                    selectData?.quantity_of_unit_per_subscription *
                                    selectData?.subscription_frequency
                                  );
                                  setValue(
                                    `treatment.${[val]}.condition`,
                                    selectData?.condition
                                  );

                                  setdetailInformation((prev) => {
                                    return [
                                      ...prev,
                                      {
                                        drugName: selectData?.drugName,
                                        treatMentName:
                                          selectData?.treatmentName,
                                        detail_information: [
                                          ...selectData?.detail_information,
                                        ],
                                      },
                                    ];
                                  });
                                }}
                              >
                                <option value="" selected disabled hidden>
                                  Select Medicine Name
                                </option>
                                {getData &&
                                  getData.map((val, i) => {
                                    return (
                                      <option
                                        key={`${i}${val?.treatmentName}`}
                                        value={val?.treatmentName}
                                      >
                                        {val?.treatmentName}
                                      </option>
                                    );
                                  })}
                              </select>
                              {errors?.treatment?.[val]?.treatmentName && (
                                <p className="error">
                                  {
                                    errors?.treatment?.[val]?.treatmentName
                                      ?.message
                                  }
                                </p>
                              )}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="medicininput">
                              <span>Number Of Repeats</span>
                              <select
                                {...register(
                                  `treatment.${[val]}.number_of_repeats`
                                )}
                              >
                                <option value="" selected disabled hidden>
                                  Please Selected Your Number
                                </option>
                                <option value={0}>0</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                                <option value={9}>9</option>
                                <option value={10}>10</option>
                              </select>
                              {errors?.treatment?.[val]?.number_of_repeats && (
                                <p>
                                  {
                                    errors?.treatment?.[val].number_of_repeats
                                      .message
                                  }
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="medicininput">
                              <span>Prescription Subscription Frequency</span>
                              <input
                                {...register(
                                  `treatment.${[val]}.subscription_frequency`
                                )}
                                type="text"
                                placeholder=""
                                disabled
                              />

                              {errors?.treatment?.[val]
                                ?.subscription_frequency && (
                                  <p className="error">
                                    {
                                      errors?.treatment?.[val]
                                        ?.subscription_frequency.message
                                    }
                                  </p>
                                )}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="medicininput">
                              <span>Condition</span>
                              <textarea
                                {...register(`treatment.${[val]}.condition`)}
                                type="text"
                                placeholder="Enter Condition"
                                disabled
                              />
                              {errors?.treatment?.[val]?.condition && (
                                <p className="error">
                                  {errors?.treatment?.[val]?.condition.message}
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="medicininput">
                              <span>Directions</span>
                              <textarea
                                {...register(`treatment.${[val]}.directions`)}
                                type="text"
                                placeholder="Enter Direction/"
                              />
                              {errors?.treatment?.[val]?.directions && (
                                <p className="error">
                                  {errors?.treatment?.[val]?.directions.message}
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="medicininput">
                              <span>Active Drug</span>
                              <input
                                {...register(`treatment.${[val]}.active_drug`)}
                                type="text"
                                placeholder=""
                                disabled
                              />
                              {errors?.treatment?.[val]?.active_drug && (
                                <p className="error">
                                  {
                                    errors?.treatment?.[val]?.active_drug
                                      .message
                                  }
                                </p>
                              )}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="medicininput">
                              <span>Form</span>
                              <input
                                {...register(`treatment.${[val]}.form`)}
                                type="text"
                                placeholder="Enter Form"
                                disabled
                              />
                              {errors?.treatment?.[val]?.form && (
                                <p className="error">
                                  {errors?.treatment?.[val]?.form.message}
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="medicininput">
                              <span>Price</span>
                              <input
                                {...register(`treatment.${[val]}.price`)}
                                type="text"
                                placeholder="Enter Price"
                                disabled
                              />
                              {errors?.treatment?.[val]?.price && (
                                <p className="error">
                                  {errors?.treatment?.[val]?.price.message}
                                </p>
                              )}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="medicininput">
                              <span>Provider </span>

                              <select
                                {...register(`treatment.${[val]}.provider`)}
                              >
                                {providerData &&
                                  providerData.map((val, i) => {
                                    return (
                                      <>
                                        <option key={`${i}${val}`} value={val}>
                                          {val}
                                        </option>
                                      </>
                                    );
                                  })}
                              </select>
                              {errors?.treatment?.[val]?.provider && (
                                <p className="error">
                                  {errors?.treatment?.[val]?.provider.message}
                                </p>
                              )}
                            </div>
                          </Col>

                          {addTreatment.length - 1 !== i ? (
                            <div>
                              <hr className="dividers"></hr>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}

                    <div className="addmoreBgtn btnredsubmit text-end">
                      <button
                        type="button"
                        onClick={() =>
                          setAddTreatment((prev) => {
                            return [...prev, prev[prev.length - 1] + 1];
                          })
                        }
                      >
                        Add More
                      </button>
                    </div>
                  </Row>
                  <div className="btnredsubmit">
                    <button
                      onClick={handleSubmit((data) => {

                        submitData(data);
                      })}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
              <div className="coptrights">
                <p>©2023 Frenchie M.D Pty Ltd</p>
              </div>
            </div>
          </Col>
        </Row>
        {loader && <Loader />}
      </div>
    </>
  );
};

export default AddTreatment;
