/**
 * The code defines two React components, ClosEyes and OpenEyes, which render SVG icons representing
 * closed and open eyes respectively.
 * @returns The code is returning two components: ClosEyes and OpenEyes. Each component renders an SVG
 * icon of closed eyes and open eyes respectively.
 */
import React from "react";

export const ClosEyes = () => {
  return (
    <>
      <span className="open_eyes_icon">
        <svg
          width="20"
          height="11"
          viewBox="0 0 20 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.78547 10.1511L6.1763 9.71948L6.83214 7.27031C5.85044 6.90825 4.93807 6.38061 4.13464 5.71031L2.34047 7.50531L1.1613 6.32615L2.9563 4.53198C1.94303 3.31845 1.26221 1.86261 0.980469 0.306979L2.62047 0.0078125C3.25297 3.50948 6.3163 6.16615 10.0005 6.16615C13.6838 6.16615 16.748 3.50948 17.3805 0.0078125L19.0205 0.306146C18.7391 1.86199 18.0586 3.31812 17.0455 4.53198L18.8396 6.32615L17.6605 7.50531L15.8663 5.71031C15.0629 6.38061 14.1505 6.90825 13.1688 7.27031L13.8246 9.72031L12.2155 10.1511L11.5588 7.70115C10.5274 7.87788 9.47351 7.87788 8.44214 7.70115L7.78547 10.1511Z"
            fill="#F8F5F0"
          />
        </svg>
      </span>
    </>
  );
};

export const OpenEyes = () => {
  return (
    <>
      <span className="open_eyes_icon">
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0002 3C14.4935 3 18.2319 6.23333 19.016 10.5C18.2327 14.7667 14.4935 18 10.0002 18C5.50687 18 1.76854 14.7667 0.984375 10.5C1.76771 6.23333 5.50687 3 10.0002 3ZM10.0002 16.3333C11.6998 16.333 13.3489 15.7557 14.6776 14.696C16.0063 13.6363 16.936 12.1569 17.3144 10.5C16.9346 8.84442 16.0043 7.36667 14.6757 6.30835C13.3471 5.25004 11.6988 4.67377 10.0002 4.67377C8.30162 4.67377 6.65328 5.25004 5.32469 6.30835C3.99609 7.36667 3.06585 8.84442 2.68604 10.5C3.06445 12.1569 3.9941 13.6363 5.32283 14.696C6.65155 15.7557 8.30065 16.333 10.0002 16.3333V16.3333ZM10.0002 14.25C9.00565 14.25 8.05182 13.8549 7.34856 13.1516C6.6453 12.4484 6.25021 11.4946 6.25021 10.5C6.25021 9.50544 6.6453 8.55161 7.34856 7.84835C8.05182 7.14509 9.00565 6.75 10.0002 6.75C10.9948 6.75 11.9486 7.14509 12.6519 7.84835C13.3551 8.55161 13.7502 9.50544 13.7502 10.5C13.7502 11.4946 13.3551 12.4484 12.6519 13.1516C11.9486 13.8549 10.9948 14.25 10.0002 14.25ZM10.0002 12.5833C10.5527 12.5833 11.0826 12.3638 11.4733 11.9731C11.864 11.5824 12.0835 11.0525 12.0835 10.5C12.0835 9.94747 11.864 9.41756 11.4733 9.02686C11.0826 8.63616 10.5527 8.41667 10.0002 8.41667C9.44767 8.41667 8.91777 8.63616 8.52707 9.02686C8.13637 9.41756 7.91687 9.94747 7.91687 10.5C7.91687 11.0525 8.13637 11.5824 8.52707 11.9731C8.91777 12.3638 9.44767 12.5833 10.0002 12.5833Z"
            fill="#F8F5F0"
          />
        </svg>
      </span>
    </>
  );
};
