import React, { useEffect, useState } from "react";
import history from "../../Assets/images/history.png";
import { ApiGet, ApiPost } from "../../helpers/API/API_data";
import "./ViewPatientDoument.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { RxCross2 } from "react-icons/rx";
import { ErrorToast, SuccessToast } from "../../helpers/Toast";

function ViewPatientDoument({ datas, id }) {
  const [documentget, setDocumentGet] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState();

  const HandleView = (data) => {
    const pdfData = data
      ?.split("/")
      ?.[data?.split("/")?.length - 1].split(".")?.[1];
    if (pdfData === "pdf" || pdfData === "docx") {
      window.open(data);
    } else if (pdfData !== "pdf" && pdfData !== "docx") {
      setData(data);
      setIsOpen(true);
    }
  };

  const getdoument = () => {
    ApiGet(`doctor/patient_document/${id}`)
      .then((res) => {
        setDocumentGet(res?.data?.data?.[0]?.documents.reverse());
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleDelete = (val) => {
    console.log("val", val);
    let url = val?.split(
      "https://medicine-storage.s3.ap-southeast-2.amazonaws.com/"
    )[1];
    let body = {
      id: id,
      documentUrl: url,
    };
    ApiPost("doctor/delete/document", body)
      .then((res) => {
        console.log("res", res);
        SuccessToast(res?.data?.message);
        getdoument();
      })
      .catch((err) => {
        console.log("err", err);
        ErrorToast(err?.message);
      });
    // getdoument()
  };

  useEffect(() => {
    getdoument();
  }, []);

  return (
    <div className="profilepatient mb-3">
      <h4 style={{ marginLeft: "1vh" }}>Patient Documents </h4>
      <ul
        className="uploaddata"
        style={{ height: `${documentget?.length > 0 ? "450px" : "58px"}` }}
      >
        {documentget?.length > 0 ? (
          documentget?.map((vel, i) => {
            return (
              <li key={`${i}${vel}`}>
                <div className="d-flex justify-content-between align-items-center bg-white">
                  <div
                    className="uploaddetail d-flex align-items-center"
                    style={{ overflow: "hidden" }}
                    onClick={() => HandleView(vel)}
                  >
                    <div>
                      <img src={history} alt="" />
                    </div>
                    <div>
                      <div className="uploadget ms-1">
                        {vel.split("/").at(-1).split("_")[0]}
                      </div>
                    </div>
                  </div>
                  <div>
                    {" "}
                    <RxCross2
                      className="me-3 cursor-pointer"
                      size={20}
                      onClick={() => handleDelete(vel)}
                    />{" "}
                  </div>
                </div>
              </li>
            );
          })
        ) : (
          <span
            className="d-flex justify-content-center align-items: center"
            style={{ fontFamily: "Helvetica Neue" }}
          >
            There are no shared documents
          </span>
        )}
      </ul>
      {isOpen && (
        <Lightbox
          // imageTitle={images[imgIndex].title}
          // imageCaption={ele[imgIndex].caption}
          mainSrc={data}
          // nextSrc={images?.[(imgIndex + 1) % images?.length]}
          // prevSrc={images?.[(imgIndex + images?.length - 1) % images?.length]}
          onCloseRequest={() => setIsOpen(false)}
          // onMovePrevRequest={() => setImgIndex((imgIndex + images?.length - 1) % images?.length)}
          // onMoveNextRequest={() => setImgIndex((imgIndex + 1) % images?.length)}
        />
      )}
    </div>
  );
}

export default ViewPatientDoument;
