import "./ChatScreen.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import profile from "../../Assets/images/chat/avatarBlue.png";
import { Link, useLocation } from "react-router-dom";
import Header from "../Header/Header";
import { appContext } from "../../helpers/AppContext";
import close from "../../Assets/images/chat/close.png";
import Search from "../../Assets/images/chat/Search.png";
import Auth from "../../helpers/Auth";
import ChatLoader from "../../Component/Auth/ChatLoader/ChatLoader";
import { ApiPost } from "../../helpers/API/API_data";
import "./ChatScreenListMobile.css";

const ChatScreenListMobile = () => {
  const {  roomContext, setRoomContext} =
    useContext(appContext);
  const [id, setId] = useState();
  const [rommSearch, SetRommSearch] = useState("");
  const location = useLocation();
  let pathName = window.location.pathname;
  const [userRoomId, setUserRoomId] = useState();
  const [search, setsearch] = useState('');

// set active user when navigate from iniate screen
  useEffect(() => {
    if (location?.state?.patientId) {
      const userRoomId = roomContext?.find(
        (ele) => ele?.user?._id === location?.state?.patientId
      );
      setUserRoomId(userRoomId);
    }
  }, [roomContext]);

  // search room
  let timeOutId;
  const handleSearch = (e) => {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    timeOutId = setTimeout(() => {
      getRoomData(e.target.value);
    }, 500);
  };

  // get room data
  const getRoomData = async (i) => {
    let body = {
      search: i,
      page: 1,
      limit: 10000
    };
    await ApiPost("doctor/room/get", body)
      .then((res) => {
        setsearch(i)
        setRoomContext(res?.data?.data?.room_data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };


  
  // get room data on every 5sec
  useEffect(() => {
    let interval;
    if (pathName === "/ChatScreen") {
      interval = setInterval(async () => {
        await getRoomData(search);
      }, 5000);
    }

    return () => {
      clearInterval(interval);
    };

  }, []);



  return (
    <>
      <div className="chatscreen admindashboard chat_with_admin mobile_chat_screen_list">
        <Header />
        <div className="inner_div_dashboard">
          <div className="chat_screen_mobile_back_btn">
            <Link className="chat_screen_mobile_back_link" to={"/"}>
              <span className="chat_screen_mobile_back_span">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_5079_71993)">
                    <path
                      d="M10.828 12.0002L15.778 16.9502L14.364 18.3642L8 12.0002L14.364 5.63623L15.778 7.05023L10.828 12.0002Z"
                      fill="#003B61"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5079_71993">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              Back
            </Link>
          </div>
          <div className="customerlists customerlist">
            <div className="customersearch">
              <input
                type="text"
                placeholder="Search"
                value={rommSearch}
                onChange={(e) => {
                  handleSearch(e);
                  SetRommSearch(e.target.value);
                }}
              />
              <span>
                {rommSearch.length ? (
                  <img
                    alt=""
                    src={close}
                    onClick={() => {
                      SetRommSearch("");
                    }}
                  />
                ) : (
                  <img src={Search} />
                )}
              </span>
            </div>
            {/* rooms list */}
            <ul className="chatlists">
              {roomContext?.length ? (
                roomContext?.map((ele, i) => {
                  return (
                    <li className="activechats">
                      <Link
                        to={`/ChatScreenMobile/${ele?._id}/${ele?.user?._id}`}
                        className={
                          ele.user?._id ===
                            (userRoomId ? userRoomId?.user?._id : id)
                            ? "active_user"
                            : ""
                        }
                        onClick={Auth.setUserChatMessageId(
                          ele?.user?._id
                        )}
                      >
                        {" "}
                        <img
                          src={ele?.user?.image ? ele?.user?.image : profile}
                          className="userAvatarMain"
                          alt=""
                        />
                        <div className="profileinfochat">
                          <h4>
                            {ele?.user?.firstName &&
                              ele?.user?.firstName + " " + ele?.user?.lastName}
                          </h4>
                          <p>{ele?.message?.message}</p>
                        </div>
                        {ele?.unReadCount ? (
                          <span className="massage_count">
                            {ele?.unReadCount}
                          </span>
                        ) : (
                          ""
                        )}
                      </Link>
                    </li>
                  );
                })
              ) : (
                <div
                  className="chat_screen_loader"
                  style={{
                    height: "80vh",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <ChatLoader />
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatScreenListMobile;
