/**
 * The EmptyData function returns a React component that displays a message and an image when there are
 * no records to display.
 * @returns The EmptyData component is returning a div element with some inline styles and a className
 * of "copyright". Inside the div, there is an image element with a src attribute pointing to the
 * emty_box image and an alt attribute. There is also a paragraph element with the text "There Are No
 * Records To Display".
 */
import React from "react";
import emty_box from "../Assets/images/emty_box.png";

export default function EmptyData() {
  return (
    <div
      className="emptyBoxWrapper"
    >
      <img src={emty_box} alt="" width="50px" />
      <p> There Are No Records To Display </p>
    </div>
  );
}
