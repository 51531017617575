import "./PatientList.css";
import React, { useRef, useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { ApiPost } from "../../helpers/API/API_data";
import SideDrawer from "../SideDrawer/SideDrawer";
import Header from "../Header/Header";
import { CSVLink } from "react-csv";
import moment from "moment";
import Loader from "../../Component/Auth/loader/Loader";
import { appContext } from "../../helpers/AppContext";
import { useHistory } from "react-router-dom";
import Table from "../../Component/Table/Table";
import {  SuccessToast } from "../../helpers/Toast";
import TableListCard from "../../Component/tableListCard/TableListCard";
import EmptyData from "../../Component/EmptyBox";
import Pagination from "../../Component/Pagination";

const PatientList = () => {
  const csvLinkRef = useRef(null);
  const history = useHistory();
  const headers = [
    { label: "No", key: "no" },
    { label: "Patient Name", key: "name" },
    { label: "Phone Number", key: "contactNumber" },
    { label: "Email", key: "email" },
    { label: "Status", key: "status" },
    { label: "Time", key: "time" },
    { label: "condition", key: "condition" },
  ];
  const [patientList, setpatientList] = useState([]);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [search, setSearch] = useState();
  const [treatmentStatus, setTreatmentStatus] = useState();
  const [excel, setExcel] = useState([]);
  const { loader, setLoader, setTotalPatient, isTablet, itemPerPage } = useContext(appContext);

  // Pagination
  const handlePageChange = (pageNumber) => setPage(pageNumber);

  const handleNextClick = () => {
    setPage((prevPage) => prevPage + 1);
  };
  const handlePrevClick = () => setPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(totalData / itemPerPage);

  const searchdata = search?.trim().split(/ +/).join(" ");
  const columns = [
    {
      headerName: "Created At",
      field: "createdAt",
    },
  
    {
      headerName: "Patient Name",
      field: "name",
      cellRenderer: (params) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push(`/viewPatient`, {
                state: {
                  id: params?.data?._id,
                  condition: params?.data?.condition,
                },
              })
            }
          >
            {params?.value}
          </span>
        </>
      ),
    },
    {
      headerName: "Condition",
      field: "condition",
    },
    {
      headerName: "Phone Number",
      field: "contactNumber",
    },
    {
      headerName: "Email",
      field: "email",
    },
    {
      headerName: "Treatment Status",
      field: "treatmentStatus",
      cellRenderer: (params) =>
        params?.value === 0 ? (
          <span style={{ color: "#FF0000" }}>{"Not Assigned"}</span>
        ) : params?.value == 1 ? (
          <span style={{ color: "#220A0A" }}>{"Payment Pending"}</span>
        ) : params?.value == 2 ? (
          <span style={{ color: "#8B0000" }}>{"In Progress"}</span>
        ) : params?.value == 3 ? (
          <span style={{ color: "#8B0000" }}>{"Completed"}</span>
        ) : (
          "-"
        ),
    },
    {
      headerName: "Time",
      field: "time",
    },
    {
   
      headerName: "No",
      field: "no",
      cellRenderer: (params) => (
        <>
          <span
            className="cursor-pointer"
            onClick={() => {
              handleCopyClick(params?.value);
              SuccessToast("Copy Successfully!");
            }}
          >
            {params?.value}
          </span>
        </>
      ),
    },
  ];

  // modify patient data
  const patientListData = patientList?.map((value) => {
    return {
      patient: true,
      id: value._id,
      createdAt:value?.createdAt,
      contactNumber: value?.contactNumber,
      time: value?.time,
      email: value?.email,
      name: (
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            history.push(`/viewPatient`, {
              state: {
                id: value?._id,
                condition: value?.condition,
              },
            })
          }
        >
          {value?.name}
        </span>
      ),
      consultStatus: value?.treatmentStatus,
    };
  });

  // copy patient id
  const handleCopyClick = (value) => {

    // Create a temporary textarea element to copy the text
    const tempTextarea = document.createElement("textarea");
    tempTextarea.value = value;
    document.body.appendChild(tempTextarea);
    // Select the text inside the textarea and copy it
    tempTextarea.select();
    document.execCommand("copy");
    // Remove the temporary textarea element
    document.body.removeChild(tempTextarea);
  };

  // get patient data
  const getPatientList = (body) => {
    setLoader(true);
    ApiPost("doctor/patient/get", body)
      .then((res) => {
        setLoader(false);
        const data = res?.data?.data?.patient_data.map((val) => val?._id);
        setTotalPatient(data);
        setpatientList(
          res?.data?.data?.patient_data &&
          res?.data?.data?.patient_data.map((val, i) => {
            return {
              ...val,
              no: val?._id,
              createdAt: val?.createdAt && moment(val?.createdAt).format("LLL"),
              name: `${val?.firstName} ${val?.lastName}`,
              contactNumber: val?.contactNumber,
              email: val?.email,
              treatmentStatus: val?.treatmentStatus,
              time: moment(val?.consulation?.doctorAssignDate).format(
                "LLL"
              ),
              _id: val?._id,
              condition:
                val?.consulation?.condition === "STI/STD"
                  ? "STI Screening"
                  : val?.consulation?.condition,
            };
          })
        );
        setTotalData(res?.data?.data?.state?.data_count);
      })
      .catch((e) => {
        setLoader(false);
        console.log("e", e);
      });
  };
  const bodyData = {
    page: page,
    limit: perpage,
    search: searchdata,
    treatmentStatus: treatmentStatus,
  };
  useEffect(() => {
    getPatientList(bodyData);
  }, [page, perpage, treatmentStatus]);

  //handle patient search submit
  const HandleSubmit = () => {
    const body = {
      ...bodyData,
      search: searchdata,
    };
    getPatientList(body);
  };
  useEffect(() => {
    if (searchdata) {
      HandleSubmit();
    }
  }, [searchdata]);

    //handle patient search
  const Handlesearch = (SearchData) => {
    if (SearchData.length === 0) {
      getPatientList({ ...bodyData, search: "" });
    }
    setSearch(SearchData);
  };

  // export excel
  useEffect(() => {
    if (excel.length) {
      csvLinkRef?.current?.link.click();
      setExcel([]);
    }
  }, [excel]);
  const ExportExcel = () => {
    const body = {
      page: 1,
      search,
    };
    ApiPost("doctor/patient/get", body)
      .then((res) => {
        console.log("resexporttreat", res);
        setExcel(
          res?.data?.data?.patient_data &&
          res?.data?.data?.patient_data.map((val, i) => {
            return {
              ...val,
              no: val?._id,
              name: `${val?.firstName} ${val?.lastName}`,
              contactNumber: val?.contactNumber,
              email: val?.email,
              status:
                val?.treatmentStatus === 0
                  ? "Not Assigned"
                  : val?.treatmentStatus === 1
                    ? "Payment Pending"
                    : val?.treatmentStatus === 2
                      ? "In Progress"
                      : val?.treatmentStatus === 3
                        ? "Completed"
                        : "-",
              time: moment(val?.consulation?.doctorAssignDate).format(
                "LLL"
              ),
              _id: val?._id,
              condition: val?.consulation?.condition,
            };
          })
        );
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  return (
    <>
      <div className="chatscreen admindashboard">
        <div className="loader">
          <Row>
            <Col lg={2}>
              <SideDrawer />
            </Col>
            <Col
              lg={10}
              style={{
                height: "100vh",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <Header />
              <div className="activepatient table_main">
                <div className="titlepatient d-flex justify-content-between align-items-center">
                  <h4>Patient list</h4>
                  <CSVLink
                    data={excel}
                    style={{ opacity: 0 }}
                    ref={csvLinkRef}
                    filename={"Patient list"}
                    headers={headers}
                  ></CSVLink>
                  <div className="searchbox">
                    <div className="main_search_box">
                      <input
                        placeholder="input search text"
                        className="pe-3 search"
                        value={search}
                        onChange={(e) => Handlesearch(e.target.value)}
                      />
                      <button
                        className="searchbtn d-flex align-items-center"
                        onClick={HandleSubmit}
                      >
                        Search
                      </button>
                    </div>
                    <select
                      className="searchfilter"
                      name="TreatmentStatus"
                      onChange={(e) => setTreatmentStatus(Number(e.target.value))}
                    >
                      <option selected disabled hidden>
                        All
                      </option>
                      <option value='null'>All</option>
                      <option value={0}>Not Assigned</option>
                      <option value={1}>Payment Pending</option>
                      <option value={2}>In Progress</option>
                      <option value={3}>Completed</option>
                    </select>
                    <button
                      className="button ms-3"
                      type="button"
                      onClick={() => ExportExcel()}
                    >
                      {" "}
                      Export ExcelSheet
                    </button>
                  </div>
                </div>

                {isTablet ? (
                  <Table
                    columns={columns}
                    data={patientList}
                    page={page}
                    perpage={perpage}
                    setparpage={setPerpage}
                    setpage={setPage}
                    totalData={totalData}
                  />
                ) : (
                  <div className="patient_list_data">
                    {/* card to display data in mobile view */}
                    <ul className="consulation_list_ul_data">
                      {patientListData.length ? (
                        patientListData?.map((value, index) => {
                          return <TableListCard key={index} data={value} />;
                        })
                      ) : (
                        <EmptyData />
                      )}
                    </ul>
                    {totalData > itemPerPage && (
                      <Pagination
                        page={page}
                        handlePageChange={handlePageChange}
                        handleNextClick={handleNextClick}
                        handlePrevClick={handlePrevClick}
                        totalPages={totalPages}
                      />
                    )}
                  </div>
                )}
                <div className="copyright">
                  <p>©2023 Frenchie M.D Pty Ltd</p>
                </div>
              </div>
            </Col>
          </Row>
          {loader && <Loader />}
        </div>
      </div>
    </>
  );
};
export default PatientList;