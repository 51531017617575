import "./PathologyList.css";
import React, { useRef, useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import SideDrawer from "../SideDrawer/SideDrawer";
import { ApiPost } from "../../helpers/API/API_data";
import Header from "../Header/Header";
import { CSVLink } from "react-csv";
import { appContext } from "../../helpers/AppContext";
import Loader from "../../Component/Auth/loader/Loader";
import Table from "../../Component/Table/Table";
import TableListCard from "../../Component/tableListCard/TableListCard";
import EmptyData from "../../Component/EmptyBox";
import Pagination from "../../Component/Pagination";
import { useHistory } from "react-router-dom";
import moment from "moment";

const PathologyList = () => {
  const csvLinkRef = useRef(null);
  const history = useHistory();

  const headers = [
    { label: "Item No", key: "Itemnumber" },
    { label: "Patient Name", key: "assignpatient" },
    { label: "Report Type", key: "ReportType" },
    { label: "Kit Name", key: "Kitname" },
    { label: "Panel Description", key: "kitdiscription" },
    { label: "Result Status", key: "resultStatus" },
    { label: "Date Ordered", key: "date" },
    { label: "Date Recevied", key: "dateRecevied" },
  ];
  const [pathology, setPathology] = useState([]);
  const [search, setSearch] = useState();
  const [perpage, setPerpage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [excel, setExcel] = useState([]);

  const { loader, setLoader, isTablet, itemPerPage } = useContext(appContext);

  const searchdata = search?.trim().split(/ +/).join(" ");

  const columns = [
    {
      headerName: "Item Number",
      field: "Itemnumber",
    },
    {
      headerName: "Patient Name",
      field: "assignpatient",
      cellRenderer: (params) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push(`/viewPatient`, {
                state: {
                  id: params?.data?._id,
                  condition: params?.data?.condition,
                },
              })
            }
          >
            {params?.value}
          </span>
        </>
      ),
    },
    {
      headerName: "Kit Name",
      field: "Kitname",
    },
    {
      headerName: "Panel Description",
      field: "kitdiscription",
    },
    {
      headerName: "Report Type",
      field: "ReportType",
      cellRenderer: (value) => (
        <>
          <div>
            {value?.data?.pathologyResult?.length ? (
              <div>
                {value?.data?.pathologyResult?.map((v, i) => {
                  return (
                    <>
                      {
                        v?.pathologyName
                      }
                      <span>
                        {value?.data?.pathologyResult !== i + 1 && <br />}
                      </span>
                    </>
                  );
                })}
              </div>
            ) : (
              <div>{value?.value}</div>
            )}
          </div>
        </>
      ),
    },
    {
      headerName: "Pathology Result",
      field: "pathologyResult",
      cellRenderer: (value) => (

        <div>
          <div>{value?.data?.pathologyResult.length > 0 ? <div>
            {
              value?.data?.pathologyResult?.map((item) => {
                return (
                  <div className={`${item?.result?.trim() === "Not Detected" ? "text-success" : item?.result?.trim() === "Detected" ? "text-danger" : ""}`}>{item?.result}</div>
                )
              })
            }

          </div> : <div>Waiting</div>}</div>
        </div>

      )
    },
    {
      headerName: "Date Recevied",
      field: "dateRecevied",
      cellRenderer: (value) => (
        <div>
          <div>
            {value?.data?.dateRecevied.length > 0 ? (
              <div>
                {value?.data?.dateRecevied?.map((v, i) => {
                  return (
                    <>
                      {moment(v?.dateReceived).format("LLL")}
                      <span>
                        {value?.data?.dateRecevied !== i + 1 && <br />}
                      </span>
                    </>
                  );
                })}
              </div>
            ) : (
              <div>-</div>
            )}
          </div>
        </div>
      ),
    },
    {
      headerName: "Date Ordered",
      field: "createdDate",
    },


  ];

  // modify pathology data
  const pathologyListData = pathology?.map((value, index) => {

    return {
      id: index,
      pathology: true,
      itemNumber: value.Itemnumber,
      name: (
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            history.push(`/viewPatient`, {
              state: {
                id: value?._id,
                condition: value?.condition,
              },
            })
          }
        >
          {value?.assignpatient}
        </span>
      ),
      kitName: value?.Kitname,
      reportType: value?.ReportType,
      kitDescription: value?.kitdiscription,
      newPatholgyResult: value?.pathologyResult,
      pathologyResult: value?.pathologyResult && (
        <div
          className={`${value?.value?.trim() === "Not Detected"
            ? "text-success"
            : value?.value?.trim() === "Detected"
              ? "text-danger"
              : ""
            }`}
        >
          {value?.value}
        </div>
      ),
      createdDate: value?.createdDate
        ? moment(value?.createdDate).format("LLL")
        : "-",
      dateRecevied: value?.pathologyResult.length ? value?.pathologyResult?.map((item) => moment(item.dateReceived).format("LLL")) : "-",
    };
  });
  
  // Pagination
  const handlePageChange = (pageNumber) => setPage(pageNumber);

  const handleNextClick = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevClick = () => setPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(totalData / itemPerPage);

  const bodydata = {
    page: page,
    limit: perpage,
    search: searchdata,
  };

  useEffect(() => {
    assingedpathology(bodydata);
  }, [page, perpage]);

  // handle search
  const HandleSearch = (searchdata) => {
    if (searchdata.length === 0) {
      assingedpathology({ ...bodydata, search: "" });
    }
    setSearch(searchdata);
  };

  
  // handle search on search button
  const HandleSubmit = () => {
    const body = {
      ...bodydata,
      search: searchdata,
    };
    assingedpathology(body);
  };
  useEffect(() => {
    if (searchdata) {
      HandleSubmit();
    }
  }, [searchdata]);

  // get pathology data
  const assingedpathology = (body) => {
    setLoader(true);
    ApiPost(`doctor/assinged_pathology`, body)
      .then((res) => {

        setLoader(false);
        setPathology(
          res?.data?.data?.pathology_data &&
          res?.data?.data?.pathology_data.map((val) => {
            return {
              Itemnumber: val?.pathology?.itemNumber,
              assignpatient: `${val?.patient?.firstName} ${val?.patient?.lastName}`,
              ReportType: val?.pathology?.reportType,
              Kitname: val?.pathology?.kitName,
              OptionalTests: val?.pathology?.optionalTest ?? "-",
              kitdiscription: val?.pathology?.panelDescriptor,
              pathologyResult: val?.SonicResultData,
              _id: val?.patient?._id,
              condition:
                val?.condition === "STI/STD"
                  ? "STI Screening"
                  : val?.condition,
              createdDate: val?.createdAt
                ? moment(val?.createdAt).format("LLL")
                : "-",
              dateRecevied: val?.SonicResultData,
            };
          })
        );
        setTotalData(res?.data?.data?.state?.data_count);
      })
      .catch((e) => {
        setLoader(false);
        console.log("e", e);
      });
  };

  // handle export csv
  useEffect(() => {
    if (excel.length) {
      csvLinkRef?.current?.link.click();
      setExcel([]);
    }
  }, [excel]);

  const ExportExcel = () => {
    const body = {
      page: 1,
      search: searchdata,
    };

    ApiPost(`doctor/assinged_pathology`, body)
      .then((res) => {
        setExcel(
          res?.data?.data?.pathology_data &&
          res?.data?.data?.pathology_data.map((val) => {

            return {
              ...val,
              Itemnumber: val?.pathology?.itemNumber,
              assignpatient: `${val?.patient?.firstName} ${val?.patient?.lastName}`,
              ReportType: val?.SonicResultData.length ? val?.SonicResultData?.map((item) => item.pathologyName) : val?.pathology?.reportType,
              result: val?.pathology?.reportType,
              Kitname: val?.pathology?.kitName,
              OptionalTests: val?.pathology?.optionalTest ?? "-",
              kitdiscription: val?.pathology?.kitDescription,
              date:
                val?.createdAt && moment(val?.createdAt).format("DD/MM/YYYY"),
              dateRecevied: val?.SonicResultData.length ? val?.SonicResultData?.map((item) => moment(item.dateReceived).format("LLL")) : "-",
              resultStatus: val?.SonicResultData.length > 0 ? val?.SonicResultData?.map((item) => item.result) : "Waiting",
            };
          })
        );
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  return (
    <>
      <div className="chatscreen admindashboard">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col
            lg={10}
            style={{
              height: "100vh",
              overflow: "hidden",
              position: "relative",
            }}
            s
          >
            <Header />
            <div className="activepatient table_main">
              <div className="titlepatient d-flex justify-content-between align-items-center">
                <h4>Pathology</h4>
                {/* export csv */}
                <CSVLink
                  data={excel}
                  style={{ opacity: 0 }}
                  ref={csvLinkRef}
                  filename={"Pathology list"}
                  headers={headers}
                ></CSVLink>
                <div className="searchbox">
                  <div className="main_search_box">
                    <input
                      placeholder="input search text"
                      className="  pe-3 search"
                      value={search}
                      onChange={(e) => HandleSearch(e.target.value)}
                    />
                    <button
                      className="searchbtn d-flex align-items-center"
                      onClick={HandleSubmit}
                    >
                      Search
                    </button>
                  </div>
                   {/* export csv */}
                  <button className="button ms-3" onClick={() => ExportExcel()}>
                    Export to Excel
                  </button>
                </div>
              </div>
              {isTablet ? (
                <Table
                  columns={columns}
                  data={pathology}
                  page={page}
                  perpage={perpage}
                  setparpage={setPerpage}
                  setpage={setPage}
                  totalData={totalData}
                />
              ) : (
                <div className="patient_list_data">
                  <ul className="consulation_list_ul_data">
                    {pathologyListData.length ? (
                      pathologyListData?.map((value, index) => {
                        return <TableListCard key={index} data={value} />;
                      })
                    ) : (
                      <EmptyData />
                    )}
                  </ul>
                  {totalData > itemPerPage && (
                    <Pagination
                      page={page}
                      handlePageChange={handlePageChange}
                      handleNextClick={handleNextClick}
                      handlePrevClick={handlePrevClick}
                      totalPages={totalPages}
                    />
                  )}
                </div>
              )}
              <div className="copyright">
                <p>©2023 Frenchie M.D Pty Ltd</p>
              </div>
            </div>
          </Col>
        </Row>
        {loader && <Loader />}
        {/* </div> */}
      </div>
    </>
  );
};

export default PathologyList;
