import "./SignupDoctor.css";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../Assets/images/logo.png";
import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import { ApiPostNoAuth } from "../../../helpers/API/API_data";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import { encryptdata } from "../../../helpers/Encrypt";
import { ClosEyes, OpenEyes } from "../../Icons";

const SignupDoctor = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConformPass, setShowConformPass] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    c_password: "",
  });
  const history = useHistory();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

  // handle sign up inputs values
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  // submit valid sign up data
  const handleSubmit = async () => {
    if (!values?.email && !values?.password && !values?.c_password) {
      ErrorToast("All Field is Requried !");
    } else if (!values?.email) {
      ErrorToast("Email is Requried!");
    } else if (values?.email && !emailRegex.test(values?.email)) {
      ErrorToast("Invalid Email!");
    } else if (!values?.password) {
      ErrorToast("Password is Requried!");
    } else if (!values?.c_password) {
      ErrorToast("Confirm password is Requried!");
    } else if (values?.password !== values?.c_password) {
      ErrorToast("Password and confirm password does not match");
    } else {
      const body = {
        email: values?.email,
        password: values?.password,
      };
      const encryptedSignupData = encryptdata(body);
      await ApiPostNoAuth("doctor", { encryptedSignupData })
        .then((res) => {
          SuccessToast(`Otp sent to ${values?.email}  Successfully!!!`);
          if (res.data.status === 200) {
            localStorage.setItem("userdata", values?.email);
            localStorage.setItem("userpassword", values?.password);
            history.push("/OtpDoctor");
          }
        })
        .catch((err) => {
          console.log("err", err);
          ErrorToast(err?.message);
        });
    }
  };

// handle hide and show passcode and confirmpasscode
  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleConformPass = () => setShowConformPass(!showConformPass);

  return (
    <>
      <section className="login mainlogin">
        <header className="docheader">
          <img src={logo} alt="" />
        </header>
        <Row>
          <Col lg={12}>
            <div className="loginuserdetail doc">
              <h3>Doctor Portal </h3>
              <div className="inputdetail">
                <div className="userinput">
                  {" "}
                  <label htmlFor="email">Email address</label>{" "}
                  <input
                    type="text"
                    placeholder="Email address"
                    name="email"
                    value={values?.email}
                    onChange={handleChange}
                  />{" "}
                </div>
                <div className="userinput">
                  {" "}
                  <label htmlFor="password">Password</label>{" "}
                  <div
                    style={{ position: "relative" }}
                    className="password_filed"
                  >
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      value={values?.password}
                      onChange={handleChange}
                      className="input_show_icon"
                    />{" "}
                    <button
                      style={{
                        position: "absolute",
                        background: "transparent",
                        border: "none",
                        right: "10px",
                        transform: "translateY(-50%)",
                        top: "50%",
                      }}
                      onClick={handleShowPassword}
                      className="show_hide_password"
                    >
                      {showPassword ? <ClosEyes /> : <OpenEyes />}
                    </button>
                  </div>
                </div>
                <div className="userinput">
                  {" "}
                  <label htmlFor="c_password">Confirm Password</label>{" "}
                  <div
                    style={{ position: "relative" }}
                    className="password_filed"
                  >
                    <input
                      type={showConformPass ? "text" : "password"}
                      placeholder="Confirm Password"
                      name="c_password"
                      value={values?.c_password}
                      className="input_show_icon"
                      onChange={handleChange}
                    />{" "}
                    <button
                      style={{
                        position: "absolute",
                        background: "transparent",
                        border: "none",
                        right: "10px",
                        transform: "translateY(-50%)",
                        top: "50%",
                      }}
                      onClick={handleConformPass}
                      className="show_hide_password"
                    >
                      {showConformPass ? <ClosEyes /> : <OpenEyes />}
                    </button>
                  </div>
                </div>
                <div className="btnred">
                  {" "}
                  <Link to="#" onClick={() => handleSubmit()}>
                    Sign up
                  </Link>{" "}
                </div>
              </div>
              <div className="signuplink text-center">
                <p>
                  Already have a account?
                  <Link to="/">Login</Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default SignupDoctor;
