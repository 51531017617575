import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../Assets/images/doclogo.png";
import { Row, Col } from "react-bootstrap";
import { ApiPostNoAuth } from "../../../helpers/API/API_data";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import { encryptdata } from "../../../helpers/Encrypt";

function ForgetPassword() {
  const [email, setEmail] = useState({});

  const history = useHistory();

  
// handle change inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmail({ ...email, [name]: value });
  };

  // submit email to reset password
  const handleSubmit = async () => {
    const body = {
      email: email?.email,
    };
    const encryptedData = encryptdata(body);
    await ApiPostNoAuth("doctor/forgot_password", {encryptedData})
      .then((res) => {
        
        SuccessToast(`Otp sent to ${email?.email}  Successfully!!!`);
        localStorage.setItem("userdata", email?.email);
        localStorage.setItem("email", email?.email);

        history.push("/OtpVerification");
      })
      .catch((err) => {
        
        ErrorToast(err?.message);
      });
  };

  return (
    <>
      <section className="login forgot_pass">
        <header className="docheader">
          <img src={logo} alt="" />
        </header>
        <Row>
          <Col lg={12}>
            <div className="loginuserdetail doc">
              <h3>Forget Password </h3>
              <div className="inputdetail">
                <div className="userinput">
                  {" "}
                  <label htmlFor="email">Email address</label>{" "}
                  <input
                    type="text"
                    placeholder="Email address"
                    name="email"
                    onChange={handleChange}
                  />{" "}
                </div>
                <div className="btnred text-white">
                  {" "}
                  <Link onClick={handleSubmit}>Send Otp</Link>{" "}
                </div>
              </div>
              <div className="signuplink text-center">
                <p>
                  <Link to="/LoginDoctor">Back</Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}

export default ForgetPassword;
