import "./PatientSummryCard.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import SideDrawer from "../../SideDrawer/SideDrawer";
import Header from "../../Header/Header";

const PatientSummryCard = (props) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  const SummaryData = location?.state?.state?.data
  const SummaryCardData = location?.state?.SummaryCardData

  const handelSubmit = (e) => {
    e.preventDefault()
    if (type === 'patientCard') {
      history.push('/ConsultationList')
    } else if (type === 'consultationcard') {
      history.push('/ConsultationList')
    }


  }
  return (
    <>
      <div className="chatscreen admindashboard">

        <Row>
          <Col lg={2}>
            <SideDrawer />

          </Col>
          <Col lg={10}>
            <Header />
            {SummaryData ?

              <div className="docsummry patientsum">
                <h3 className="mainheading ">Patient summary card <span>#000123</span></h3>
                <div className="patiemtsubdetail">
                  <ul className="patientdetail">

                    <li><span>Patient’s Name </span> <p>{`${SummaryData?.patient?.[0]?.firstName} ${SummaryData?.patient?.[0]?.lastName}`}</p></li>
                    <li><span>Patient’s Address  </span> <p>
                      {SummaryData?.patient?.[0]?.billingAddress ? SummaryData?.patient?.[0]?.billingAddress : "-"},{" "}
                      {SummaryData?.patient?.[0]?.city ? SummaryData?.patient?.[0]?.city : "-"},{" "}
                      {SummaryData?.patient?.[0]?.state
                        ? SummaryData?.patient?.[0]?.state
                        : "-"}
                      ,{" "}
                      {SummaryData?.patient?.[0]?.country
                        ? SummaryData?.patient?.[0]?.country
                        : "-"}
                      ,{" "}
                      {SummaryData?.patient?.[0]?.postcode
                        ? SummaryData?.patient?.[0]?.postcode
                        : "-"}
                    </p></li>
                  </ul>
                  <h4 className="patienttitle">Patient Consult Notes:</h4>
                  <ul className="patientdetail">
                    <li><span>Current Condition</span> <p>{SummaryData?.condition}</p></li>
                    <li><span>Current Medication</span> <p>
                      {
                        SummaryData?.patient?.[0].currentMedicine ? SummaryData?.patient?.[0].currentMedicine : '-'
                      }
                      </p></li>
                    <li><span>Allergies</span> <p>{SummaryData?.patient?.[0]?.allergies ? SummaryData?.patient?.[0]?.allergies : "No"
                    }</p></li>
                  </ul>
                  <h4 className="patienttitle">Product Selection</h4>
                  {SummaryData?.treatment?.map((item, i) => {
                    return (

                      <ul key={`${i}${item?.treatmentName}`} className="productselection">
                        <li>
                          <div className="productsubsele">
                            <span>Item Number</span>
                            <p>{item.itemNo ? item?.itemNo : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>QTY</span>
                            <p>{item?.quantity ? `${item?.quantity}ML` : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Tratment</span>
                            <p>{item?.treatmentName ? item?.treatmentName : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Provider</span>
                            <p>{item?.provider[0] ? item?.provider[0] : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Patient Name</span>
                            <p>{`${SummaryData?.patient?.[0]?.firstName} ${SummaryData?.patient?.[0]?.lastName}`}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Drug</span>
                            <p>{item?.drugName ? item?.drugName : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Form</span>
                            <p>{item?.form ? item?.form : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Route</span>
                            <p>{item?.route ? item?.route : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Dose</span>
                            <p>{item?.dose ? item?.dose : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Summary</span>
                            <p className="wrapit d-block">{item?.card?.[0]?.summery}</p>
                          </div>
                        </li>
                      </ul>
                    )
                  })}
                  <ul className="patientdetail">
                  </ul>
                </div>
                <div className="assigndoctor" style={{ textAlign: 'end', padding: '30px' }}>
                  <a href="" onClick={(e) => handelSubmit(e)} >Go to list </a>

                </div>
              </div>
              :
              <div className="docsummry patientsum">
                <h3 className="mainheading ">Patient summary card <span>#000123</span></h3>
                <div className="patiemtsubdetail">
                  <ul className="patientdetail">

                    <li><span>Patient’s Name </span> <p>{`${SummaryCardData?.patient_data?.firstName} ${SummaryCardData?.patient_data?.lastName}`}</p></li>
                    <li><span>Patient’s Address  </span> <p>
                      {SummaryCardData?.patient_data?.billingAddress ? SummaryCardData?.patient_data?.billingAddress : "-"},{" "}
                      {SummaryCardData?.patient_data?.city ? SummaryCardData?.patient_data?.city : "-"},{" "}
                      {SummaryCardData?.patient_data?.state
                        ? SummaryCardData?.patient_data?.state
                        : "-"}
                      ,{" "}
                      {SummaryCardData?.patient_data?.country
                        ? SummaryCardData?.patient_data?.country
                        : "-"}
                      ,{" "}
                      {SummaryCardData?.patient_data?.postcode
                        ? SummaryCardData?.patient_data?.postcode
                        : "-"}
                    </p></li>
                  </ul>
                  <h4 className="patienttitle">Patient Consult Notes:</h4>
                  <ul className="patientdetail">
                    <li><span>Current Condition</span> <p>{SummaryCardData?.patient_question?.questionType}</p></li>
                    <li><span>Current Medication</span> 
                    <p>
                      {
                        SummaryCardData?.patient_data?.currentMedicine ? SummaryCardData?.patient_data?.currentMedicine : "-"
                      }
                      </p></li>
                    <li><span>Allergies</span> <p>{SummaryData?.patient?.[0]?.allergies ? SummaryData?.patient?.[0]?.allergies : "No"
                    }</p></li>
                  </ul>
                  <h4 className="patienttitle">Product Selection</h4>
                  {SummaryCardData?.treatment_data?.map((item, i) => {
                    return (

                      <ul key={`${i}${item.treatmentName}`} className="productselection">
                        <li>
                          <div className="productsubsele">
                            <span>Item Number</span>
                            <p>{item.itemNo ? item?.itemNo : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>QTY</span>
                            <p>{item?.quantity ? `${item?.quantity}ML` : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Tratment</span>
                            <p>{item?.treatmentName ? item?.treatmentName : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Provider</span>
                            <p>{item?.provider[0] ? item?.provider[0] : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Patient Name</span>
                            <p>{`${SummaryCardData?.patient_data?.firstName} ${SummaryCardData?.patient_data?.lastName}`}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Drug</span>
                            <p>{item?.drugName ? item?.drugName : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Form</span>
                            <p>{item?.form ? item?.form : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Route</span>
                            <p>{item?.route ? item?.route : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Dose</span>
                            <p>{item?.dose ? item?.dose : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="productsubsele">
                            <span>Summary</span>
                            <p className="wrapit d-block">{SummaryCardData?.card?.summery}</p>
                          </div>
                        </li>
                      </ul>
                    )
                  })}
                </div>
                <div className="assigndoctor" style={{ textAlign: 'end', padding: '30px' }}>
                  <a href="" onClick={(e) => handelSubmit(e)} >Go to list </a>

                </div>
              </div>
            }

          </Col>
        </Row >
      </div >
    </>
  );
};

export default PatientSummryCard;
