import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../Assets/images/doclogo.png";
import { Row, Col } from "react-bootstrap";
import { ApiPostNoAuth } from "../../../helpers/API/API_data";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import { encryptdata } from "../../../helpers/Encrypt";

function ResetPassword() {
  const history = useHistory();
  const [val, setVal] = useState({});
  let id = localStorage.getItem("userid");
  let email = localStorage.getItem("email");

//  handle change inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setVal({ ...val, [name]: value });
  };

  // submit new passcode
  const handleSubmit = async () => {
    let body = {
      id: email,
      password: val?.password,
    };

    const encryptedData = encryptdata(body);
    await ApiPostNoAuth("doctor/reset_password", {encryptedData})
      .then((res) => {
        localStorage.removeItem("email");
        SuccessToast("Password Update Successfully");
        history.push("/");
      })
      .catch((err) => {
        
        ErrorToast(err?.message);
      });
  };
  return (
    <>
      <section className="login reset_password">
        <header className="docheader">
          <img src={logo} alt="" />
        </header>
        <Row>
          <Col lg={12}>
            <div className="loginuserdetail doc">
              <h3>Reset Password </h3>
              <div className="inputdetail">
                <div className="userinput">
                  {" "}
                  <label htmlFor="password">New Password</label>{" "}
                  <input
                    type="password"
                    placeholder="new password"
                    name="password"
                    onChange={handleChange}
                  />{" "}
                </div>
                <div className="userinput">
                  {" "}
                  <label htmlFor="c_password">Confirm Password</label>{" "}
                  <input
                    type="password"
                    placeholder="confirm password"
                    name="c_password"
                  />{" "}
                </div>
                <div className="btnred text-white">
                  {" "}
                  <Link onClick={handleSubmit}>Update Password</Link>{" "}
                </div>
              </div>
              <div className="signuplink text-center">
                <p>
                  <Link to="/LoginDoctor">Back</Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}

export default ResetPassword;
