import React from "react";
import "antd/dist/antd.min.css";
import { Switch } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "./Assets/css/responsive.css";
import RouteLink from "./RouteLink/RouteLink.js";
import AppProvider from "./helpers/AppContext";
import "./Page/DoctorDashboard/DoctorDashboard.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
      <AppProvider>
        <Switch>
          <RouteLink />
        </Switch>
        <Toaster />
      </AppProvider>
    </>
  );
}
export default App;
