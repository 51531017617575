import * as React from "react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import user from "../../Assets/images/darkuser.png";
import notification from "../../Assets/images/notification.png";
import edit from "../../Assets/images/edit.png";
import { ApiGet } from "../../helpers/API/API_data";
import Drawer from "@mui/material/Drawer";
import Logo from "../../Assets/images/logo.png";
import { SideUl } from "../SideDrawer/SideDrawer";
import darklogo_3 from "../../Assets/images/darklogo.png";
import "./header.css";
import "../PathologyList/PathologyList.css";
import { appContext } from "../../helpers/AppContext";
import notificationSVG from '../../Assets/images/svg/notification.svg'

const Header = () => {
  const history = useHistory();
  const doctorProfile = JSON.parse(localStorage.getItem("logindata"));
  const [notifications, setNotification] = useState([]);
  const [windowInner, setWindowInner] = useState(window.innerWidth > 1440);
  const [isOpen, setIsOpen] = useState(false);
  const { isCostpmerSupportOpen } =
  React.useContext(appContext);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const isMobile = window.innerWidth > 1440;
        if (isMobile !== windowInner) setWindowInner(isMobile);
      },
      false
    );
    if (isCostpmerSupportOpen) {
      setIsOpen(false)
    }
  }, [windowInner,isCostpmerSupportOpen]);

  const toggleDrawer = () => setIsOpen(!isOpen);

  useEffect(() => {
    getNotification();
  }, []);

  const getNotification = () => {
    ApiGet(`doctor/doctor_notifications`)
      .then((res) => {
        
        setNotification(res?.data?.data);
      })
      .catch((e) => {
        
      });
  };

  return (
    <header className={`chatheader`}>
      <div className="header">
        <div className="profilelogo">
          <div className="header_profile_logo_main_div">
            {windowInner ? (
              ""
            ) : (
              <img alt="" src={darklogo_3} className="dark_logo_header" />
            )}
            {windowInner ? (
              <>
                <img
                  src={user}
                  alt=""
                  className="header_profile_image_user_logo_inner_div"
                />
                <button
                  style={{ border: "none", backgroundColor: "transparent" }}
                >
                  {doctorProfile?.firstName + " " + doctorProfile?.lastName}
                </button>
                <img
                  src={edit}
                  alt=""
                  onClick={() => history.push("/SetUpProfile?isEdit=true")}
                  className="edit_profile_link"
                  style={{ cursor: "pointer" }}
                  title="You can edit your profile and availability here"
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="header_right_button">
          <div className="notificationicon">
            <img style={{ cursor: "pointer" }} src={notification} alt="" />
            <input type="checkbox"></input>
            <ul className="bell noti">
              {notifications?.map((item, i) => {
                return (
                  <li
                    key={`${i}${item?.message}`}
                    style={{ justifyContent: "space-between", gap: "20px" }}
                  >
                    <p style={{ textAlign: "start" }}>
                      <span></span>
                      {item?.message}
                    </p>
                    <div
                      className="notiicon"
                      style={{ maxWidth: "90px", marginLeft: "0px" }}
                    >
                           <img src={notificationSVG} style={{width:"20px"}} alt="notification img"/>
                      <p>
                        {moment(new Date()).format("DD/MM/YYYY") ===
                          moment(new Date(item?.createdAt)).format("DD/MM/YYYY")
                          ? "Today"
                          : moment(new Date(item?.createdAt)).format("DD/MM")}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="header_button_menu">
            {windowInner ? (
              ""
            ) : (
              <>
                <div className="doctor_profile_and_edit">
                  <img
                    src={user}
                    alt=""
                    className="header_profile_image_user_logo_inner_div"
                  />
                  <img
                    src={edit}
                    alt=""
                    onClick={() => history.push("/SetUpProfile?isEdit=true")}
                    className="edit_profile_link"
                    style={{ cursor: "pointer" }}
                    title="You can edit your profile and availability here"
                  />
                  <button
                    onClick={toggleDrawer}
                    style={{ backgroundColor: "transparent", border: "none" }}
                    className="header_menu_btn"
                  >
                    <span>
                      <svg
                        width="18"
                        height="16"
                        viewBox="0 0 18 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 0H18V2H0V0ZM0 7H18V9H0V7ZM0 14H18V16H0V14Z"
                          fill="#003B61"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
                <Drawer
                  style={{ backgroundColor: "transparent" }}
                  anchor="right"
                  open={isOpen}
                  onClose={toggleDrawer}
                >
                  <div className="custom_drawer_main">
                    <div className="asidechat">
                      <img onClick={toggleDrawer} src={Logo} alt="" />
                      <button onClick={toggleDrawer} className="drawer_close">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_5114_34999)">
                            <path
                              d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V20ZM12 10.586L14.828 7.757L16.243 9.172L13.414 12L16.243 14.828L14.828 16.243L12 13.414L9.172 16.243L7.757 14.828L10.586 12L7.757 9.172L9.172 7.757L12 10.586Z"
                              fill="#F8F5F0"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_5114_34999">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                    <SideUl />
                  </div>
                </Drawer>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
