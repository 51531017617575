import React, { useEffect, useState } from "react";
import "./PathologyModal.css";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { ApiGet, ApiPost } from "../../helpers/API/API_data";
import Imgpatient from "../../Assets/images/delete.png";
import { AiFillDelete } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { ErrorToast } from "../../helpers/Toast";

function MyVerticallyCenteredModal(props) {
  const location = useLocation()
  const [patientpathology, setpatientpathology] = useState([]);
  const [data, setData] = useState({
    pathologyId: "",
    patientId: "",
    condition: "",
  });
  const [selctedData, setSelctedData] = useState({});
  const [getpatient, setGetPatient] = useState([]);
  const [addTest, setAddTest] = useState([0]);
  const [flage, setflage] = useState(false);
  const [selectDatas, setSelectDatas] = useState();
  const [apidata, setApiData] = useState([]);

  const [formErrors, setFormErrors] = useState();
  const signupSchema = yup.object().shape({
    treatment: yup.array().of(
      yup.object().shape({
        treatmentName: yup.string().required("Treatment Name is required"),
      })
    ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    getValues,
  } = useForm({
    resolver: yupResolver(signupSchema),
  });

  const history = useHistory();

  useEffect(() => {
    pathology();
    getPatientList();
  }, []);

  useEffect(() => {
    if (props.patientId && props.condition)
      setData({
        ...data,
        patientId: props.patientId,
        // condition: props.condition,
        condition: props.condition === "STI Screening"
          ? "STI/STD"
          : props.condition,
      });
  }, [props.patientId, props.condition]);

  useEffect(() => { }, [patientpathology]);

  const pathology = () => {
    ApiGet(`doctor/pathology`)
      .then((res) => {
        setpatientpathology(res?.data?.data);
      })
      .catch((e) => { });
  };

  const handelchange = (e) => {
    const fieldValue = e.target.value;
    const fieldName = e.target.name;
    setFormErrors({ ...formErrors, [fieldName]: "" });
    setData({ ...data, [fieldName]: fieldValue });
  };

  useEffect(() => {
    if (data.pathologyId)
      setSelctedData(
        patientpathology.find((item) => item._id === data.pathologyId)
      );
  }, [data.pathologyId]);

  const getPatientList = () => {
    const body = {
      page: 1,
      limit: 1000,
    };
    ApiPost("doctor/patient/get", body)
      .then((res) => {
        setGetPatient(res?.data?.data?.patient_data);
      })
      .catch((error) => { });
  };

  const handelsubmit = () => {
    console.log("click===>");

    let path = [];

    apidata?.length > 0 &&
      apidata?.map((val) => {
        if (val?._id) {
          path.push(val?._id);
        }
      });
    path?.forEach(element => {
      const body = {
        ...data,
        pathologyId: [element],
        consultationId: location?.state?.state?.consultationId  ? location?.state?.state?.consultationId : props?.consultationId  
        // pathologyId: path,
      };
      console.log("body==>", body);
      ApiPost("doctor/pathology", body)
        .then((res) => {
          history.push("/PathologyList");

          setData({ pathologyId: "", patientId: "" });
          setSelctedData({});
          setFormErrors({});
          setAddTest([0]);
          setApiData([]);
          props.onHide();
        })
        .catch((e) => {
          props.onHide();
          ErrorToast(e?.message)
          // alert("callll")
        });
    });


    return flage;
  };

  const deleteForm = (id, itemNumber) => {
    let data = addTest.filter((data, i) => i !== id);
    setAddTest(data);
    let dummmy = apidata?.filter((val, i) => i !== id);
    setApiData(dummmy);
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Pathology
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', justifyContent: "center" }}>
          <div className="activepatient pathology_modal_main">
            <div className="medicinlist">
              <Form>
                {/* <Row>
                  <Col lg={6}>
                    <div className="medicininput">
                      <span>Kit Name</span>
                      <select
                        name="pathologyId"
                        onChange={(e) => handelchange(e)}
                        value={data.pathologyId}
                      >
                        <option value="" selected disabled hidden>
                          Please Selected Kit Name
                        </option>
                        {patientpathology?.map((vel, i) => (
                          <option key={`${i}${vel._id}`} value={vel._id}>
                            {vel.kitName}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {formErrors?.pathologyId}
                      </span>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="medicininput">
                      <span>Assign to Patient</span>
                      <select
                        name="patientId"
                        onChange={(e) => handelchange(e)}
                        value={data.patientId}
                        disabled={props.isDisable}
                      >
                        <option value="" selected disabled hidden>
                          Please select Your Patient Name
                        </option>
                        {getpatient?.map((val, i) => (
                          <option key={`${i}${val._id}`} value={val._id}>
                            {`${val.firstName} ${val.lastName} `}{" "}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {formErrors?.patientId}
                      </span>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="medicininput">
                      <span>Item No.</span>
                      <input
                        type="text"
                        placeholder="Enter No."
                        disabled
                        value={selctedData?.itemNumber}
                        name="itemNumber"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="medicininput">
                      <span>Report Type</span>
                      <input
                        type="text"
                        placeholder="Enter Report Type"
                        disabled
                        value={selctedData?.reportType}
                        name="reportType"
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="medicininput">
                      <span>Kit Description</span>
                      <input
                        type="text"
                        placeholder="Enter kit Description"
                        disabled
                        value={selctedData?.kitDescription}
                        name="kitDescription"
                      />
                    </div>
                  </Col> */}
                {addTest?.map((val, i) => {
                  return (
                    <>
                      {i === 0 ? (
                        ""
                      ) : (
                        <div style={{ textAlign: "end" }}>
                          <AiFillDelete
                            size={20}
                            color="white"
                            onClick={() =>
                              deleteForm(i, `treatment.${[val]}.itemNumber`)
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      )}
                      <Row>
                        <Col lg={6}>
                          <div className="medicininput">
                            <span>Kit Name</span>
                            <select
                              name="pathologyId"
                              {...register(`treatment.${[val]}.pathologyId`)}
                              onChange={(e) => {
                                clearErrors(`treatment.${[val]}.pathologyId`);

                                const selectData = patientpathology
                                  ? patientpathology?.find(
                                    (item) => item._id === e.target.value
                                  )
                                  : {};
                                setSelectDatas(selectData);

                                const dummyApiData = apidata;
                                dummyApiData[val] = selectData;
                                setApiData(dummyApiData);

                                setValue(
                                  `treatment.${[val]}.pathologyId`,
                                  selectData?._id
                                );
                                setValue(
                                  `treatment.${[val]}.itemNumber`,
                                  selectData?.itemNumber
                                );
                                setValue(
                                  `treatment.${[val]}.reportType`,
                                  selectData?.reportType
                                );
                                setValue(
                                  `treatment.${[val]}.panelDescriptor`,
                                  selectData?.panelDescriptor
                                );
                              }}
                            // value={data.pathologyId}
                            >
                              <option value="" selected disabled hidden>
                                Please Selected Kit Name
                              </option>
                              {patientpathology?.map((vel, i) => (
                                <option key={`${i}${vel._id}`} value={vel._id}>
                                  {vel.kitName}
                                </option>
                              ))}
                            </select>
                            <span style={{ color: "red" }}>
                              {formErrors?.pathologyId}
                            </span>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="medicininput">
                            <span>Assign to Patient</span>
                            <select
                              name="patientId"
                              onChange={(e) => handelchange(e)}
                              value={data.patientId}
                              disabled={props.isDisable}
                            >
                              <option value="" selected disabled hidden>
                                Please select Your Patient Name
                              </option>
                              {getpatient?.map((val, i) => (
                                <option key={`${i}${val._id}`} value={val._id}>
                                  {`${val.firstName} ${val.lastName} `}{" "}
                                </option>
                              ))}
                            </select>
                            <span style={{ color: "red" }}>
                              {formErrors?.patientId}
                            </span>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="medicininput">
                            <span>Item No.</span>
                            <input
                              placeholder="Enter No."
                              disabled
                              {...register(`treatment.${[val]}.itemNumber`)}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="medicininput">
                            <span>Report Type</span>
                            <input
                              type="text"
                              placeholder="Enter Report Type"
                              disabled
                              {...register(`treatment.${[val]}.reportType`)}
                            />
                          </div>
                        </Col>
                        {/* <Col lg={12}>
                    <div className="medicininput">
                      <span>Optional Tests </span>
                      <input
                        type="text"
                        placeholder="Stetile gauze pad "
                        disabled
                        value={selctedData?.optionalTest ?? "-"}
                        name="optionalTest"
                      />
                    </div>
                  </Col> */}
                        <Col lg={12}>
                          <div className="medicininput">
                            <span>Panel Description</span>
                            <input
                              type="text"
                              placeholder="Enter kit Description"
                              disabled
                              {...register(`treatment.${[val]}.panelDescriptor`)}
                            />
                          </div>
                        </Col>

                        {/* <Col lg={12}>
                                            <div className="medicininput">
                                                <span>How do I take this treatment?</span>
                                                <input type="text" placeholder="Information" />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>How long does it take to work?
                                                </span>
                                                <input type="text" placeholder="Treatment" />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>How long does the treatment last?</span>
                                                <input type="text" placeholder="Treatment" />
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div className="medicininput">
                                                <span>Are there any side effects?</span>
                                                <input type="text" placeholder="Information" />
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div className="medicininput">
                                                <span>How do the ingredients work?</span>
                                                <input type="text" placeholder="Work" />
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div className="medicininput">
                                                <span>Safety Information
                                                </span>
                                                <input type="text" placeholder="Information" />
                                            </div>
                                        </Col> */}
                      </Row>
                    </>
                  );
                })}
                <div className="addmoreBgtn btnredsubmit">
                  <button
                    type="button"
                    onClick={() =>
                      setAddTest((prev) => {
                        return [...prev, prev[prev.length - 1] + 1];
                      })
                    }
                  >
                    Add More
                  </button>
                </div>
                {/* <Col lg={12}>
                                            <div className="medicininput">
                                                <span>How do I take this treatment?</span>
                                                <input type="text" placeholder="Information" />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>How long does it take to work?
                                                </span>
                                                <input type="text" placeholder="Treatment" />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="medicininput">
                                                <span>How long does the treatment last?</span>
                                                <input type="text" placeholder="Treatment" />
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div className="medicininput">
                                                <span>Are there any side effects?</span>
                                                <input type="text" placeholder="Information" />
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div className="medicininput">
                                                <span>How do the ingredients work?</span>
                                                <input type="text" placeholder="Work" />
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div className="medicininput">
                                                <span>Safety Information
                                                </span>
                                                <input type="text" placeholder="Information" />
                                            </div>
                                        </Col> */}
                {/* </Row> */}
                <div className="btnredsubmit">
                  <Button onClick={() => handelsubmit()}> Submit </Button>

                  {/* <Button ><Link to="/PathologyList"> Submit  </Link></Button> */}
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MyVerticallyCenteredModal;
