import React from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../Assets/images/logo.png";
import { Row, Col } from "react-bootstrap";
import { ApiPostNoAuth } from "../../../helpers/API/API_data";
import { useState } from "react";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import { encryptdata } from "../../../helpers/Encrypt";
import { ClosEyes, OpenEyes } from "../../Icons";

const LoginDoctor = () => {
  const [val, setVal] = useState({});
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

  // handle input values
  const handleChange = (e) => {
    const { name, value } = e.target;
    setVal({ ...val, [name]: value });
  };
  
  // submit valid login data 
  const handleSubmit = async () => {
    setLoader(true);
    if (!val?.email && !val?.password) {
      ErrorToast("All Field is Requried !");
      setLoader(false);
    } else if (!val?.email) {
      ErrorToast("Email is Requried!");
      setLoader(false);
    } else if (val?.email && !emailRegex.test(val?.email)) {
      ErrorToast("Invalid Email!");
      setLoader(false);
    } else if (!val?.password) {
      ErrorToast("Password is Requried!");
      setLoader(false);
    } else {
      const body = {
        email: val?.email,
        password: val?.password,
      };
      const encryptedLoginData = encryptdata(body);
      await ApiPostNoAuth("doctor/login", { encryptedLoginData })
        .then((res) => {
          
          SuccessToast("Login Successfully!!!");
          localStorage.setItem("logindata", JSON.stringify(res?.data?.data));
          localStorage.setItem("token", res?.data?.data?.token);
          if (res?.data?.data?.profilePhase === 0) {
            history.push("/SetUpProfile");
          } else if (res?.data?.data?.profilePhase === 1) {
            history.push("/SetUpAppoinmentSchedule");
          } else if (res?.data?.data?.profilePhase === 2) {
            localStorage.setItem(
              "Doctordetails",
              JSON.stringify(res?.data?.data)
            );
            history.push("/Dashboard");
          }
        })
        .catch((err) => {
          
          ErrorToast(err?.message);
        });
    }
  };

  // handle hide and show passcode
  const handleShowPassword = () => setShowPassword(!showPassword);

  return (
    <>
      <section className="login mainlogin">
        <header className="docheader">
          <img src={logo} alt="" />
        </header>
        <Row>
          <Col lg={12}>
            <div className="loginuserdetail doc">
              <h3>Doctor Portal </h3>
              <div className="inputdetail">
                <div className="userinput">
                  {" "}
                  <label htmlFor="email">Email address</label>{" "}
                  <input
                    type="text"
                    placeholder="Email address"
                    name="email"
                    value={val?.email}
                    onChange={handleChange}
                    autoComplete="off"
                  />{" "}
                </div>
                <div className="userinput">
                  {" "}
                  <label htmlFor="password">Password</label>{" "}
                  <div
                    style={{ position: "relative" }}
                    className="password_filed"
                  >
                    <input
                      autoComplete="off"
                      className="input_show_icon"
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      value={val?.password}
                      onChange={handleChange}
                    />{" "}
                    <button
                      style={{
                        position: "absolute",
                        background: "transparent",
                        border: "none",
                        right: "10px",
                        transform: "translateY(-50%)",
                        top: "50%",
                      }}
                      onClick={handleShowPassword}
                      className="show_hide_password"
                    >
                      {showPassword ? <ClosEyes /> : <OpenEyes />}
                    </button>
                  </div>
                </div>
                <div className="forgott">
                  <Link
                    style={{ textDecoration: "underline" }}
                    to="/ForgetPassword"
                  >
                    Forgot Password?
                  </Link>
                </div>
                <div className="btnred text-white">
                  {" "}
                  <Link to={"/"} onClick={handleSubmit}>
                    {loader ? "Loading.." : "LOG IN"}
                  </Link>{" "}
                </div>
              </div>
              <div className="signuplink text-center">
                <p>
                  {" "}
                  Don't have a account?
                  <Link to="/SignupDoctor">Sign Up</Link>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default LoginDoctor;
