/**
 * The `ConsultationList` component is a React functional component that displays a list of
 * consultations and allows the user to search and export the data to Excel.
 * @returns The component `ConsultationList` is being returned.
 */
import "./ConsultationList.css";
import React, { useRef, useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import SideDrawer from "../SideDrawer/SideDrawer";
import Header from "../Header/Header";
import { ApiPost } from "../../helpers/API/API_data";
import moment from "moment";
import { CSVLink } from "react-csv";
import { appContext } from "../../helpers/AppContext";
import Loader from "../../Component/Auth/loader/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Table from "../../Component/Table/Table";
import ConsultationCard from "../../Component/tableListCard/TableListCard";
import EmptyData from "../../Component/EmptyBox";
import Pagination from "../../Component/Pagination";

const ConsultationList = () => {
  const csvLinkRef = useRef(null);
  const history = useHistory();

  const headers = [
    { label: "Consultation Number", key: "consultno" },
    { label: "Patient Name ", key: "name" },
    { label: "Date/Time(Patient)", key: "patientJoiningDate" },
    { label: "Duration", key: "duration" },
    { label: "Consult Status", key: "status" },
    { label: "condition", key: "condition" },
    { label: "Next Appointment", key: "nextAppointment" },
    { label: "Join Meeting", key: "joinMeeting" },
  ];

  const columns = [
    {
      headerName: "Consultation Number",
      field: "consultno",
    },
    {
      headerName: "Patient Name ",
      field: "name",
      cellRenderer: (params) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/ViewConsultation/${params?.data?.patientId}`, {
              state: {
                id: params?.data?.patientId,
                condition: params?.data?.condition,
                consultationId: params?.data?.id,
              },
            });
          }}
        >
          {params?.value}
        </span>
      ),
    },
    {
      headerName: "Condition",
      field: "condition",
    },
    {
      headerName: "Date/Time(Patient)",
      field: "patientJoiningDate",
    },
    {
      headerName: "Dr Response Time",
      field: "duration",
    },
    {
      headerName: "Consult Status",
      field: "consultStatus",
      cellRenderer: (params) =>
        params?.value === 4 ? (
          <span style={{ color: "#a52a2a" }}>{"Completed"}</span>
        ) : params?.value === 1 ? (
          <span style={{ color: "#6ABDDF" }}>{"Unassigned Doctor"}</span>
        ) : params?.value === 3 ? (
          <span style={{ color: "#008000" }}>{"Live Consult"}</span>
        ) : params?.value === 0 ? (
          <span style={{ color: "#ff0000" }}>{"Payment Pending"}</span>
        ) : params?.value === 5 ? (
          <span style={{ color: "#045fbf" }}>{"Awaiting Pathology"}</span>
        ) : (
          "-" ),
    },
    {
      headerName: "Next Appointment",
      field: "nextAppointment",
    },
    {
      headerName: "Join Meeting",
      field: "joinMeeting",
      cellRenderer: (params) => (
        params?.data?.joinMeeting?.agora_video_calls?.joinMeetingLink ?
          <span
            style={{ cursor: "pointer", color: 'blue', textDecoration: 'underline' }}
            onClick={() => {
              const link = params?.data?.joinMeeting?.agora_video_calls?.joinMeetingLink;
              if (link) {
                window.open(link, '_blank');
              }
            }}
          >
            Join Meeting
          </span> : "-"
      ),
    },
  ];

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [totaldata, setTotaldata] = useState();
  const [search, setSearch] = useState();
  const [excel, setExcel] = useState([]);

  const { loader, setLoader, isTablet, itemPerPage } = useContext(appContext);

// modify consult data
  const consultationListData = data?.map((value) => {

    return {
      id: value.id,
      consultno: value?.consultno,
      consultation: true,
      doctorAssignDate: value?.doctorAssignDate,
      patientJoiningDate: value?.patientJoiningDate,
      duration: value?.duration,
      condition: value?.condition,
      name: (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/ViewConsultation/${value?.patientId}`, {
              state: {
                id: value?.patientId,
                condition: value?.condition,
                consultationId: value?.id,
              },
            });
          }}
        >
          {value?.name}
        </span>
      ),
      consultStatus: value?.consultStatus,
    };
  });

  // Pagination
  const handlePageChange = (pageNumber) => setPage(pageNumber);

  const handleNextClick = () => {
    setPage((prevPage) => prevPage + 1);
  };
  const handlePrevClick = () => setPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(totaldata / itemPerPage);

  const searchdata = search?.trim().split(/ +/).join(" ");

// get consult data
  const bodyData = {
    page: page,
    limit: perpage,
    search: searchdata,
  };
  useEffect(() => {
    getconsultationList(bodyData);
  }, [page, perpage]);

  // handle search on search button click
  const HandleSubmit = () => {
    const body = {
      ...bodyData,
      search: searchdata,
    };
    getconsultationList(body);
  };

  useEffect(() => {
    if (searchdata) {
      HandleSubmit();
    }
  }, [searchdata]);

  // handle search
  const HandleSearch = (SearchData) => {
    if (SearchData.length === 0) {
      getconsultationList({ ...bodyData, search: "" });
    }
    setSearch(SearchData);
  };

// get consult data
  const getconsultationList = (body) => {
    setLoader(true);
    ApiPost("doctor/consulation/get", body)
      .then((res) => {
        setLoader(false);
        res?.data?.data?.consulation_request_data &&
          setData(
            res?.data?.data?.consulation_request_data.map((val, i) => {
              return {
                id: val?._id,
                consultno: val?.consultNumber,
                name: `${val?.users?.firstName} ${val?.users?.lastName}`,
                patientJoiningDate:
                  val?.patientJoiningDate &&
                  moment(val?.patientJoiningDate).format("LLL"),
                doctorAssignDate:
                  val?.doctorAssignDate &&
                  moment(val?.doctorAssignDate).format("LLL"),
                duration: val?.durationInHoursMinutes
                  ? val?.durationInHoursMinutes
                  : "-",
                consultStatus: val?.consultStatus,
                patientId: val?.patientId,
                condition:
                  val?.condition === "STI/STD"
                    ? "STI Screening"
                    : val?.condition,
                nextAppointment: val?.users?.agora_video_calls?.slotDateTime ? moment(val?.users?.agora_video_calls?.slotDateTime).format("LLL") : "-",
                joinMeeting: val?.users,
              };
            })
          );
        setTotaldata(res?.data?.data?.state?.data_count);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  // export excel
  useEffect(() => {
    if (excel.length) {
      csvLinkRef?.current?.link.click();
      setExcel([]);
    }
  }, [excel]);

  const ExportExcel = () => {
    const body = {
      page: 1,
      search,
    };
    ApiPost("doctor/consulation/get", body)
      .then((res) => {
        setExcel(
          res?.data?.data?.consulation_request_data &&
          res?.data?.data?.consulation_request_data.map((val, i) => {
            return {
              id: val?._id,
              consultno: val?.consultNumber,
              name: `${val?.users?.firstName} ${val?.users?.lastName}`,
              patientJoiningDate:
                val?.patientJoiningDate &&
                moment(val?.patientJoiningDate).format("LLL"),
              duration: `${Math.abs(
                new Date(val?.patientJoiningDate).getHours() -
                new Date(val?.doctorAssignDate).getHours()
              )}:${Math.abs(
                new Date(val?.patientJoiningDate).getMinutes() -
                new Date(val?.doctorAssignDate).getMinutes()
              )}`,
              condition:
                val?.condition === "STI/STD"
                  ? "STI Screening"
                  : val?.condition,
              status:
                val?.consultStatus === 4
                  ? "Completed"
                  : val?.consultStatus === 1
                    ? "Unassigned Doctor"
                    : val?.consultStatus === 3
                      ? "live consult"
                      : val?.consultStatus === 0
                        ? "Payment Pending"
                        : val?.consultStatus === 5
                          ? "Awaiting pathology"
                          : "-",
              nextAppointment: val?.users?.agora_video_calls ? moment(val?.users?.agora_video_calls?.slotDateTime).format("LLL") : "-",
              joinMeeting: val?.users?.agora_video_calls ? "Join Meeting" : "-",
              patientId: val?.patientId,
            };
          })
        );
      })
      .catch((e) => { });
  };

  return (
    <>
      <div className="chatscreen admindashboard consultant">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col
            lg={10}
            style={{
              height: "100vh",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Header />
            <div className="activepatient table_main">
              <div className="titlepatient d-flex justify-content-between align-items-center">
                <h4>Consultation List</h4>
                <CSVLink
                  data={excel}
                  style={{ opacity: 0 }}
                  ref={csvLinkRef}
                  filename={"Consultation list"}
                  headers={headers}
                ></CSVLink>
                <div className="searchbox">
                  <div className="main_search_box">
                    <input
                      placeholder="input search text"
                      className="pe-3 search"
                      value={search}
                      onChange={(e) => HandleSearch(e.target.value)}
                    />
                    <button
                      className="searchbtn d-flex align-items-center"
                      onClick={HandleSubmit}
                    >
                      Search
                    </button>
                  </div>
                  <button className="button ms-3" onClick={() => ExportExcel()}>
                    Export to Excel
                  </button>
                </div>
              </div>
              {isTablet ? (
                <Table
                  columns={columns}
                  data={data}
                  page={page}
                  perpage={perpage}
                  setparpage={setPerpage}
                  setpage={setPage}
                  totalData={totaldata}
                />
              ) : (
                <div className="patient_list_data">
                  <ul className="consulation_list_ul_data">
                    {consultationListData.length ? (
                      consultationListData?.map((value, index) => {

                        return <ConsultationCard key={index} data={value} />;
                      })
                    ) : (
                      <EmptyData />
                    )}
                  </ul>
                  {totaldata > itemPerPage && (
                    <Pagination
                      page={page}
                      handlePageChange={handlePageChange}
                      handleNextClick={handleNextClick}
                      handlePrevClick={handlePrevClick}
                      totalPages={totalPages}
                    />
                  )}
                </div>
              )}
              <div className="copyright">
                <p>©2023 Frenchie M.D Pty Ltd</p>
              </div>
            </div>
          </Col>
        </Row>
        {loader && <Loader />}
      </div>
    </>
  );
};

export default ConsultationList;
