import "./Medicine.css";
import React from "react";
import { Row, Col, Button, Accordion } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import SideDrawer from "../SideDrawer/SideDrawer";
import Header from "../Header/Header";

const TreatmentList = () => {
  const history = useHistory();
  const location = useLocation();

  const question = location?.state?.state?.detailInformation;
  const id = location?.state?.state?.id;
  const name = location?.state?.state?.Name;

  return (
    <>
      <div className="chatscreen admindashboard TreatmentList">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col lg={10}>
            <Header />

            <div className="activepatient">
              <div className="viewpatienttitle">
                <h4>Add Treatment</h4>

              </div>

              <div className="medicinlist">
                {question?.map((ele, i) => {
                  return (
                    <Accordion
                      key={`${i}${ele?.drugName}`}
                      className="questionmain"
                      style={{ marginBottom: "10px" }}
                    >
                      <Row>
                        <Col lg={12}>
                          <ul className="questionmainlist">
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                <span>{i + 1}</span>
                                {`${
                                  ele?.drugName
                                } ${ele.detail_information[0].title?.slice(
                                  12
                                )}`}
                              </Accordion.Header>
                              <Accordion.Body className="notifi">
                                {ele.detail_information[0].description}
                              </Accordion.Body>
                            </Accordion.Item>
                          </ul>
                        </Col>
                      </Row>
                    </Accordion>
                  );
                })}
                <div></div>
              </div>
              <div className="btnredsubmit">
                <Button
                  onClick={() =>
                    history.push(`/PatientSummaryCard`, {
                      state: { id: id, Name: name },
                    })
                  }
                >
                  Continue
                </Button>
              </div>
              <div className="coptrights">
                <p>©2023 Frenchie M.D Pty Ltd</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TreatmentList;
