import "./ViewConsultation.css";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SideDrawer from "../SideDrawer/SideDrawer";
import { ApiGet, ApiPost } from "../../helpers/API/API_data";
import moment from "moment";
import Header from "../Header/Header";
import ViewConsultationDocument from "./ViewConsultationDocument";
import { Link } from "react-router-dom";
import { Button, Col, Form, Modal, Row, Accordion } from "react-bootstrap";
import triageImg from "../../Assets/images/ai.svg";
import MyVerticallyCenteredModal from "../PathologyList/PathologyModal";
import TextTruncate from "react-text-truncate";
import { useMediaQuery } from "@mui/material";
import { appContext } from "../../helpers/AppContext";

const ViewConsultation = ({ isMobile }) => {
  const { setLoader } = useContext(appContext);
  const history = useHistory();
  const location = useLocation();
  const isMobiles = useMediaQuery("(max-width:600px)");
  const id = location?.state?.state?.id;
  let condition = location?.state?.state?.condition;
  const [consultationData, setconsultationData] = useState({});
  const [PatientCard, setPatientCard] = useState([]);
  const [questionType, setQuestionType] = useState();
  const [closeconsult, setCloseconsult] = React.useState(false);
  const [addNotes, setAddNotes] = React.useState(false);
  const [pathologies, setpathologies] = React.useState("");
  const [patientId, setPatientId] = React.useState("");
  const [modelshow, setModelshow] = React.useState(false);
  const [note, setNote] = useState("");
  const [consultname, setConsultname] = useState("");
  // set consult name
  useEffect(() => {
    setConsultname(location?.state?.state?.condition);
  }, [consultname]);

  // get patient qsn by id
  useEffect(() => {
    getpatientQuestion();
  }, []);

  // get consulation data
  useEffect(() => {
    getViewConsultation();
  }, [questionType]);

  // close consult
  const getCloseconsult = async () => {
    setCloseconsult(false);
    const body = {
      questionType: consultname === "STI Screening" ? "STI/STD" : consultname,
      patientId: window.location.pathname?.split("/")[2],
    };
    await ApiPost(`doctor/patient_question/close_consult`, body)
      .then((res) => {
        setConsultname(condition);
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  // add notes
  const getNotedata = async () => {
    setAddNotes(false);
    const body = {
      questionType: consultname === "STI Screening" ? "STI/STD" : consultname,
      patientId: window.location.pathname?.split("/")[2],
      add_notes: note,
      patient_questionId :consultationData?.patient_questions?.[0]?._id
    };
    await ApiPost(`doctor/patient_question/Add_Notes`, body)
      .then((res) => {
        setNote("");
        getViewConsultation();
        setConsultname(condition);
      })
      .catch((e) => {
        console.log("e", e);
      });
  };
  // get consulation data
  const getViewConsultation = async () => {
    const body = {
      _id: window.location.pathname?.split("/")[2],
      condition: condition === "STI Screening" ? "STI/STD" : condition,
      consultationId: location?.state?.state?.consultationId,
    };
    await ApiPost(`doctor/viewconsultation`, body)
      .then((res) => {
        setConsultname(condition);
        setconsultationData(res?.data?.data?.[0]);
        setPatientCard(res?.data?.data?.[0]?.card);
        setpathologies(res?.data?.data?.[0]?.assingedpathologies);
      })
      .catch((e) => {
        console.log("e", e);
      });
  };
  // get patient qsn by id
  const getpatientQuestion = async () => {
    await ApiGet(
      `doctor/patient_question/${window.location.pathname?.split("/")[2]}`
    )
      .then((res) => {
        setQuestionType(res?.data?.data?.questionType);
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  let noNotesFound = true;

  // handle accordian toggle
  const [expanded, setExpanded] = useState([]);
  const [expanded1, setExpanded1] = useState([]);
  const toggleExpansion = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };
  const toggleExpansion1 = (index) => {
    const newExpanded1 = [...expanded1];
    newExpanded1[index] = !newExpanded1[index];
    setExpanded1(newExpanded1);
  };

  // initaite chat and navigate to chat screen
  const navigateInitiateChat = async () => {
    setLoader(true);
    let body = {
      search: "",
      page: 1,
      limit: 100000,
    };
    await ApiPost("doctor/room/get", body)
      .then((res) => {
        const userRoomId = res?.data?.data?.room_data?.find(
          (ele, i) => ele?.user?._id === id
        );
        if (userRoomId) {
          isMobile
            ? history.push(`/ChatScreen`, { patientId: id })
            : history.push(`/ChatScreenMobile/id/${id}`, { patientId: id });
        } else {
          ApiPost("doctor/add_doctor_room_with_patient", { patientId: id })
            .then((res) => {
              if (res?.data?.data?.userIds?.length) {
                isMobile
                  ? history.push(`/ChatScreen`, { patientId: id })
                  : history.push(`/ChatScreenMobile/id/${id}`, {
                      patientId: id,
                    });
              }
            })
            .catch((err) => {})
            .finally(() => {
              setLoader(false);
            });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <>
      <div className="chatscreen admindashboard view_patient_main_div">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col lg={10}>
            <Header />
            <div className="viewpatientmain">
              <div className="viewpatienttitle px-3">
                <Col lg={7}>
                  <h4>View Consultation</h4>
                </Col>
                <Col lg={5}>
                  <ul
                    className="uploaddoc"
                    style={{
                      columnGap: "20px",
                      justifyContent: "end",
                      display: "flex",
                      overflow: isMobiles && "auto",
                    }}
                  >
                    <li onClick={() => setCloseconsult(true)}>
                      <Link to="#">Close Consult</Link>
                    </li>

                    <li>
                      <a
                        onClick={navigateInitiateChat}
                        style={{ color: "white" }}
                      >
                        Initiate Chat
                      </a>
                    </li>

                    <li>
                      <button
                        className="upload_document"
                        onClick={() => {
                          setModelshow(true);
                          setPatientId(id);
                        }}
                      >
                        Order Pathology
                      </button>
                    </li>

                    <li
                      onClick={() =>
                        history.push(
                          `/AddTreatment/${id}?name=${
                            condition === "STI Screening"
                              ? "STI/STD"
                              : condition
                          }`,
                          {
                            state: {
                              consultationId: location?.state?.state
                                ?.consultationId
                                ? location?.state?.state?.consultationId
                                : consultationData?._id,
                            },
                          }
                        )
                      }
                    >
                      <Link to="#">Prescribe Treatment</Link>
                    </li>
                  </ul>
                </Col>
              </div>
              <Row>
                <Col lg={7}>
                  {/* patient profile */}
                  <div className="profilepatient">
                    <h4 style={{ marginLeft: "1vh" }}>Profile</h4>
                    <ul className="profilepatientsubdetail">
                      <li>
                        <div className="profilename">
                          <span>Name</span>
                          <p>
                            {consultationData?.patient?.[0]?.firstName
                              ? `${consultationData?.patient?.[0].firstName} ${consultationData?.patient?.[0].lastName}`
                              : "-"}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="profilename">
                          <span>Phone number</span>
                          <p>
                            {consultationData?.patient?.[0]?.contactNumber
                              ? consultationData?.patient?.[0]?.contactNumber
                              : "-"}
                          </p>
                        </div>
                      </li>{" "}
                      <li>
                        <div className="profilename">
                          <span>Health Problem</span>
                          <p>Chronic Pain</p>
                          <p>Chronic Illness</p>
                        </div>
                      </li>{" "}
                      <li>
                        <div className="profilename">
                          <span>Email</span>
                          <p>
                            {consultationData?.patient?.[0]?.email
                              ? consultationData?.patient?.[0]?.email
                              : "-"}
                          </p>
                        </div>
                      </li>{" "}
                      <li>
                        <div className="profilename">
                          <span>Address</span>
                          <p>
                            {" "}
                            {consultationData?.patient?.[0]?.billingAddress
                              ? consultationData?.patient?.[0]?.billingAddress
                              : "-"}
                            ,{" "}
                            {consultationData?.patient?.[0]?.city
                              ? consultationData?.patient?.[0]?.city
                              : "-"}
                            ,{" "}
                            {consultationData?.patient?.[0]?.state
                              ? consultationData?.patient?.[0]?.state
                              : "-"}
                            ,{" "}
                            {consultationData?.patient?.[0]?.country
                              ? consultationData?.patient?.[0]?.country
                              : "-"}
                            ,{" "}
                            {consultationData?.patient?.[0]?.postcode
                              ? consultationData?.patient?.[0]?.postcode
                              : "-"}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="profilename">
                          <span>Date of Birth</span>
                          <p>
                            {consultationData?.patient?.[0]?.dob
                              ? consultationData?.patient?.[0]?.dob
                              : "-"}
                          </p>
                        </div>
                      </li>{" "}
                      <li>
                        <div className="profilename">
                          <span>Gender</span>
                          <p>
                            {consultationData?.patient?.[0]?.gender?.toString()
                              ? (consultationData?.patient?.[0]?.gender?.toString() ===
                                  "0" &&
                                  "Male") ||
                                (consultationData?.patient?.[0]?.gender === 1 &&
                                  "Female") ||
                                (consultationData?.patient?.[0]?.gender === 2 &&
                                  "Other")
                              : "-"}
                          </p>
                        </div>
                      </li>{" "}
                      <li>
                        <div className="profilename">
                          <span>Gender Identity</span>
                          <p>
                            {consultationData?.patient?.[0]?.identityGender
                              ? consultationData?.patient?.[0]?.identityGender
                              : "-"}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="profilename">
                          <span>Current medicine</span>
                          <p>
                            {consultationData?.patient?.[0]?.currentMedicine
                              ? consultationData?.patient?.[0]?.currentMedicine
                              : "-"}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="profilename">
                          <span>Allergies</span>
                          <p>
                            {consultationData?.patient?.[0]?.allergies
                              ? consultationData?.patient?.[0]?.allergies
                              : "No "}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* Triage AI of qsn summary  */}
                  <div className="profilepatient mb-3">
                    <h4 style={{ marginLeft: "1vh" }}>
                      Triage AI{" "}
                      <img
                        src={triageImg}
                        width={25}
                        alt=""
                        style={{ marginTop: "-40px", marginLeft: "-5px" }}
                      />
                    </h4>
                    {consultationData?.patient_questions?.[0]?.summary?.length >
                    0 ? (
                      <div className="summary">
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              {
                                consultationData?.patient_questions?.[0]
                                  ?.questionType
                              }
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className=" w-100">
                                {
                                  consultationData?.patient_questions?.[0]
                                    ?.summary
                                }
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    ) : (
                      <span
                        className="d-flex justify-content-center my-3"
                        style={{
                          fontFamily: "Helvetica Neue",
                          padding: "5px",
                          minHeight: "46px",
                        }}
                      >
                        No Triage AI
                      </span>
                    )}
                  </div>
                  {/* regular doctor */}
                  <div className="profilepatient mb-3 ">
                    <h4 style={{ marginLeft: "1vh" }}>Regular Doctor</h4>
                    {consultationData?.patient?.[0]?.additionalInfo
                      ?.regularDoctor ? (
                      <ul
                        className="profilepatientsubdetail"
                        style={{ rowGap: "20px" }}
                      >
                        <li>
                          <div className="profilename">
                            <span>First Name</span>
                            <p>
                              {
                                consultationData?.patient?.[0]?.additionalInfo
                                  ?.regularDoctor?.firstName
                              }
                            </p>
                          </div>
                        </li>

                        <li>
                          <div className="profilename">
                            <span>Last Name</span>
                            <p>
                              {
                                consultationData?.patient?.[0]?.additionalInfo
                                  ?.regularDoctor?.lastName
                              }
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="profilename">
                            <span>Clinic Name</span>
                            <p>
                              {
                                consultationData?.patient?.[0]?.additionalInfo
                                  ?.regularDoctor?.clinicName
                              }
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="profilename">
                            <span>Email</span>
                            <p>
                              {
                                consultationData?.patient?.[0]?.additionalInfo
                                  ?.regularDoctor?.email
                              }
                            </p>
                          </div>
                        </li>
                      </ul>
                    ) : (
                      <span
                        className="d-flex justify-content-center my-3"
                        style={{
                          fontFamily: "Helvetica Neue",
                          padding: "5px",
                          minHeight: "45px",
                        }}
                      >
                        No regular doctor shared
                      </span>
                    )}
                  </div>
                  {/* regular Medications */}
                  <div className="profilepatient mb-3 ">
                    <h4 style={{ marginLeft: "1vh" }}>Regular Medications </h4>
                    {consultationData?.patient?.[0]?.currentMedicine &&
                    consultationData?.patient?.[0]?.currentMedicine !== "No" ? (
                      <ul
                        className="profilepatientsubdetail"
                        style={{ rowGap: "20px" }}
                      >
                        <li>
                          <div className="profilename">
                            <p>
                              {consultationData?.patient?.[0]?.currentMedicine}
                            </p>
                          </div>
                        </li>
                      </ul>
                    ) : (
                      <span
                        className="d-flex justify-content-center my-3"
                        style={{
                          fontFamily: "Helvetica Neue",
                          padding: "5px",
                          minHeight: "45px",
                        }}
                      >
                        No regular medications
                      </span>
                    )}
                  </div>
                  {/* Recent Pathology */}

                  <div
                    className="profilepatient"
                    style={{
                      padding: "0 10px 10px 10px",
                      marginBottom: "16px",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{
                        borderBottom: "1px solid rgb(204 204 204 / 60%)",
                      }}
                    >
                      <h4 className="border-0">Recent Pathology</h4>
                    </div>
                    <div style={{ maxHeight: "160px", overflow: "auto" }}>
                      {pathologies?.length !== 0 ? (
                        pathologies?.map((val, i) => {
                          return (
                            <>
                              <ul
                                key={`${i}${val?.pathology?.reportType}`}
                                className="profilepatientsubdetail"
                              >
                                <li>
                                  <div className="profilename">
                                    <span>Request Number</span>
                                    <p>
                                      {val?.pathology?.itemNumber
                                        ? val?.pathology?.itemNumber
                                        : "-"}
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <div className="profilename">
                                      <span>Report Type</span>
                                    </div>
                                    {val?.SonicResultData?.length > 0 ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "start",
                                          wordWrap: "break-word",
                                        }}
                                        className={`pathologyName ${
                                          val?.pathologyResult ===
                                          "Not Detected"
                                            ? "text-success"
                                            : val?.pathologyResult ===
                                              "Detected"
                                            ? "text-danger"
                                            : ""
                                        }`}
                                      >
                                        {val?.SonicResultData?.map((v, i) => {
                                          return (
                                            <>
                                              <div key={i}>
                                                {i + 1}. {v?.pathologyName} -
                                                <span
                                                  className={`${
                                                    v?.result === "Not Detected"
                                                      ? "text-success"
                                                      : v?.result === "Detected"
                                                      ? "text-danger"
                                                      : ""
                                                  }`}
                                                >
                                                  {" "}
                                                  {v?.result}
                                                </span>
                                              </div>
                                            </>
                                          );
                                        })}
                                      </div>
                                    ) : (
                                      <div className="profilename">
                                        {val?.pathology?.reportType?.split(
                                          "and"
                                        )?.length > 0 ? (
                                          <div>
                                            <p>
                                              {
                                                val?.pathology?.reportType?.split(
                                                  "and"
                                                )[0]
                                              }
                                            </p>
                                            <p>
                                              {
                                                val?.pathology?.reportType?.split(
                                                  "and"
                                                )[1]
                                              }
                                            </p>
                                          </div>
                                        ) : (
                                          <div>
                                            {val?.pathology?.reportType}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div className="profilename">
                                    <span>PDF Link</span>
                                    <p
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "start",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      {val?.SonicResultData?.length > 0 ? (
                                        <>
                                          <div>
                                            {" "}
                                            {i + 1}.{" "}
                                            <a
                                              href={val?.pdfURL}
                                              target="_blank"
                                              style={{ cursor: "pointer" }}
                                            >
                                              {" "}
                                              {val?.pdfURL
                                                ? val?.pdfURL
                                                    ?.split(".com/")
                                                    .slice(1)
                                                : "-"}
                                            </a>
                                          </div>
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </p>
                                  </div>
                                </li>{" "}
                              </ul>
                              {i !== pathologies?.length - 1 && <hr />}
                            </>
                          );
                        })
                      ) : (
                        <span
                          className="d-flex justify-content-center my-3"
                          style={{ fontFamily: "Helvetica Neue" }}
                        >
                          No recent results
                        </span>
                      )}
                    </div>
                  </div>
                  {/* Current Treatment Plan */}
                  <div
                    className="profilepatient"
                    style={{ padding: "0 10px 10px 0px", marginBottom: "16px" }}
                  >
                    <h4 className="ms-2">Current Treatment Plan</h4>
                    <div style={{ maxHeight: "160px", overflow: "auto" }}>
                      {consultationData?.treatment?.length ? (
                        consultationData?.treatment.map((ele, i) => {
                          return (
                            <>
                              <ul
                                key={`${i}${ele?.treatmentName}`}
                                className="profilepatientsubdetail"
                              >
                                <li>
                                  <div className="profilename">
                                    <span>Treatment</span>
                                    <p>
                                      {ele?.treatmentName
                                        ? ele?.treatmentName
                                        : "-"}
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="profilename">
                                    <span>Drug Name</span>
                                    <p>{ele?.drugName ? ele?.drugName : "-"}</p>
                                  </div>
                                </li>{" "}
                                <li>
                                  <div className="profilename">
                                    <span>Quantity</span>
                                    <p>{ele?.quantity ? ele?.quantity : "-"}</p>
                                  </div>
                                </li>
                                <li>
                                  <div className="profilename">
                                    <span>Number of repeats</span>
                                    <p>{ele?.number_of_repeats}</p>
                                  </div>
                                </li>
                                <li>
                                  <div className="profilename">
                                    <span>Next Payment Due</span>
                                    <p>
                                      {ele?.subscription?.nextPaymentDate
                                        ? moment(
                                            ele?.subscription?.nextPaymentDate
                                          )?.format("MM-DD-YYYY")
                                        : "-"}
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="profilename">
                                    <span>Payment Status</span>
                                    {ele?.paymentStatus === 0 ? (
                                      <p style={{ color: "#077E8C" }}>
                                        {"Pending"}
                                      </p>
                                    ) : ele?.paymentStatus === 1 ? (
                                      <p style={{ color: "#008000" }}>
                                        {"Payment Succsess"}
                                      </p>
                                    ) : (
                                      <p style={{ color: "#ff0000 " }}>
                                        {"Failed"}
                                      </p>
                                    )}
                                  </div>
                                </li>
                              </ul>
                              {i !==
                                consultationData?.treatment?.length - 1 && (
                                <hr />
                              )}
                            </>
                          );
                        })
                      ) : (
                        <span
                          className="d-flex justify-content-center py-4"
                          style={{ fontFamily: "Helvetica Neue" }}
                        >
                          There are No Treatment
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col lg={5}>
                  <div
                    className="patientotherdetail1"
                    style={{ marginBottom: "16px" }}
                  >
                    <div
                      className="patientothersubdetail"
                      style={{ padding: "5px 15px 15px" }}
                    >
                      <div className="d-flex justify-content-between mb-3 constline pb-1 align-items-center">
                        {/* CONSULTATION NOTES*/}
                        <div className="">
                          <div className="patienttitle1">
                            Consultation Notes
                          </div>
                        </div>
                        <div
                          className="addnote"
                          style={{ fontFamily: "Helvetica Neue" }}
                          onClick={() => setAddNotes(true)}
                        >
                          Add Notes
                        </div>
                      </div>

                      <div style={{ maxHeight: "615px", overflow: "auto" }}>
                        <div className="">
                          {PatientCard?.length ? (
                            PatientCard?.map((v, i) => {
                              noNotesFound = false;
                              return (
                                <div
                                  onClick={() =>
                                    history.push(
                                      "/PatientSummryCard?type=consultationcard",
                                      { state: { data: consultationData } }
                                    )
                                  }
                                  key={`${i}${v?.createdAt}`}
                                  className="patienttitle consnotenew mb-0"
                                  style={{
                                    flexDirection: "column",
                                    fontFamily: "Helvetica Neue",
                                    marginTop: "10px",
                                  }}
                                >
                                  <div
                                    style={{ display: "flex", width: "100%" }}
                                  >
                                    <div className="application d-flex">
                                      <div
                                        style={{
                                          color: "#003b61",
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {i + 1}. Treatment Summary
                                      </div>
                                    </div>
                                    <div className="patientnumber">
                                      <div
                                        style={{
                                          color: "#212529",
                                          fontWeight: "500",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {moment(v?.date).format("LLL")}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="descripsum">
                                    <div
                                      style={{
                                        color: "#212529",
                                        fontWeight: "500",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {" "}
                                      Description:{" "}
                                    </div>

                                    <div
                                      style={{
                                        color: "#212529",
                                        fontWeight: "300",
                                        fontSize: "14px",
                                      }}
                                      className="mt-1"
                                    >
                                      <div className="wrapit">
                                        <TextTruncate
                                          line={expanded[i] ? null : 2}
                                          element="span"
                                          truncateText="…"
                                          text={v?.summery ? v?.summery : "-"}
                                          textTruncateChild={
                                            <a
                                              onClick={() => toggleExpansion(i)}
                                            >
                                              {expanded[i]
                                                ? "Read less"
                                                : "Read more"}
                                            </a>
                                          }
                                          onTruncate={(wasTruncated) => {
                                            if (wasTruncated) {
                                              setExpanded((prevExpanded) => {
                                                const newExpanded = [
                                                  ...prevExpanded,
                                                ];
                                                newExpanded[i] = true;
                                                return newExpanded;
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </div>

                        {consultationData?.patient_questions?.map((v, i) => {
                          return (
                            <>
                              {consultationData?.condition ===
                                v?.questionType &&
                                v?.notes?.map((value, i) => {
                                  const noteText = value?.note || "";
                                  const lines = noteText
                                    ?.split("\n")
                                    .filter(Boolean);
                                  const truncatedLines = expanded1[i]
                                    ? lines
                                    : lines.slice(0, 2);

                                  noNotesFound = false;
                                  return (
                                    <>
                                      <div
                                        className="patienttitle consnotenew mt-3"
                                        style={{
                                          flexDirection: "column",
                                          fontFamily: "Helvetica Neue",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                          }}
                                        >
                                          <div className="application d-flex">
                                            <div
                                              style={{
                                                color: "#003b61",
                                                fontWeight: "500",
                                                fontSize: "16px",
                                              }}
                                            >
                                              {i + 1}. Doctor Notes
                                            </div>
                                          </div>
                                          <div className="patientnumber">
                                            <div
                                              style={{
                                                color: "#212529",
                                                fontWeight: "500",
                                                fontSize: "16px",
                                              }}
                                            >
                                              {moment(value?.notecreate).format(
                                                "LLL"
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="descripsum">
                                          <div
                                            style={{
                                              color: "#212529",
                                              fontWeight: "500",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {" "}
                                            Notes:{" "}
                                          </div>
                                          <div
                                            style={{
                                              color: "#212529",
                                              fontWeight: "300",
                                              fontSize: "14px",
                                            }}
                                            className="mt-1"
                                          >
                                            <div className="wrapit">
                                              <div className="wrapit">
                                                {truncatedLines.map(
                                                  (line, index) => (
                                                    <div key={index}>
                                                      {line}
                                                    </div>
                                                  )
                                                )}
                                                {lines.length > 2 &&
                                                  !expanded1[i] && (
                                                    <div className="read-more">
                                                      <a
                                                        onClick={() =>
                                                          toggleExpansion1(i)
                                                        }
                                                        style={{
                                                          color: "#b4555d",
                                                        }}
                                                      >
                                                        ...Read more
                                                      </a>
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                            </>
                          );
                        })}
                      </div>
                      {noNotesFound && (
                        <span
                          className="d-flex justify-content-center"
                          style={{ fontFamily: "Helvetica Neue" }}
                        >
                          There are No Data
                        </span>
                      )}
                    </div>
                  </div>
                  {/* docuement upload */}
                  <ViewConsultationDocument />
                </Col>
              </Row>
              {/* Consult Question Summary */}
              <Row>
                <div className="col-lg-12">
                  <div className="cunsultncyquestion">
                    <div className="d-flex justify-content-between">
                      <h3>Consult Question Summary</h3>
                    </div>
                    {consultationData?.patient_questions &&
                      consultationData?.patient_questions.map((item, index) => {
                        return (
                          <Accordion key={`${index}${item?.questionType}`}>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                {item?.questionType === "STI/STD"
                                  ? "STI Screening"
                                  : item?.questionType}
                                <span>
                                  {moment(
                                    consultationData?.patient_questions?.[0]
                                      ?.createdAt
                                  ).format("DD/MM/YYYY")}
                                </span>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="mt-5 w-100">
                                  {item?.questions &&
                                    item?.questions
                                      .slice(1, 3)
                                      .map((ele, i) => {
                                        return (
                                          <>
                                            <div
                                              key={`${i}${ele?.question}`}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                              }}
                                              className="view_consultation_inner_question_list accordian"
                                            >
                                              <div>
                                                <h4 className="questions__text">
                                                  <span className="numblue">
                                                    {i + 1}
                                                  </span>
                                                  {ele?.question}
                                                </h4>
                                                <h4 className="answer__text">
                                                  {ele?.answer}
                                                </h4>
                                              </div>
                                              {i ===
                                                item?.questions.slice(1, 3)
                                                  ?.length -
                                                  1 && (
                                                <div>
                                                  <div className="ViewMorequestions">
                                                    <Link
                                                      href="#"
                                                      onClick={() =>
                                                        history.push(
                                                          `/questionsummary/${
                                                            window.location.pathname?.split(
                                                              "/"
                                                            )[2]
                                                          }`,
                                                          {
                                                            state: {
                                                              consultationId:
                                                                location?.state
                                                                  ?.state
                                                                  ?.consultationId,
                                                              id: window.location.pathname?.split(
                                                                "/"
                                                              )[2],
                                                              ind: index,
                                                              condition:
                                                                questionType,
                                                            },
                                                          }
                                                        )
                                                      }
                                                    >
                                                      View More
                                                    </Link>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                            <hr></hr>
                                          </>
                                        );
                                      })}
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        );
                      })}
                  </div>
                </div>
              </Row>
            </div>
            <div className="coptrights">
              <p style={{ fontWeight: "700" }}>©2023 Frenchie M.D Pty Ltd</p>
            </div>
          </Col>
        </Row>

        {/* pathology add modal */}
        {modelshow && (
          <MyVerticallyCenteredModal
            show={modelshow}
            onHide={() => setModelshow(false)}
            patientId={patientId}
            isDisable={true}
            condition={condition}
            consultationId={consultationData?._id}
          />
        )}
        {/* consult close modal */}
        <Modal
          show={closeconsult}
          onHide={() => {
            setCloseconsult(false);
          }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Close Consult
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="closeconsult">
            <div className="p-0 px-4">
              <div className="">
                <Form>
                  <Row>
                    <label className="text-white text-center py-4">
                      Are you sure you want to close this consult?
                    </label>
                  </Row>
                  <div className="btnredsubmit m-0 py-2 d-flex justify-content-center gap-3">
                    <Button onClick={getCloseconsult}>Yes</Button>
                    <Button onClick={() => setCloseconsult(false)}>No</Button>
                  </div>
                </Form>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* add notes modal */}
        <Modal
          show={addNotes}
          onHide={() => {
            setAddNotes(false);
          }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Notes
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="closeconsult">
            <div className="p-0 px-4">
              <div className="">
                <Form>
                  <Row>
                    <label className="text-white py-2">Note:</label>
                    <div className="medicininput medicininput5">
                      <textarea
                        type="text"
                        name="note"
                        value={note}
                        onChange={(e) => setNote(e?.target?.value)}
                        placeholder="Add Notes"
                      />
                    </div>
                  </Row>
                  <div className="btnredsubmit my-2">
                    <Button onClick={getNotedata}>Submit</Button>
                  </div>
                </Form>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ViewConsultation;
