import "./SubscriptionModel.css";
import React, { useRef, useEffect, useState, useContext } from "react";
import { Row, Col, Modal, Form, Button } from "react-bootstrap";
import SideDrawer from "../SideDrawer/SideDrawer";
import { ApiPost, ApiPut } from "../../helpers/API/API_data";
import moment from "moment";
import Header from "../Header/Header";
import { CSVLink } from "react-csv";
import { appContext } from "../../helpers/AppContext";
import Loader from "../../Component/Auth/loader/Loader";
import Table from "../../Component/Table/Table";
import { useHistory } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { HiOutlineDownload } from "react-icons/hi";
import TableListCard from "../../Component/tableListCard/TableListCard";
import Pagination from "../../Component/Pagination";

let urlArray = [];

const SubscriptionModel = () => {
  const csvLinkRef = useRef(null);
  const history = useHistory();

  const headers = [
    { label: "Consultation no.", key: "Consultation" },
    { label: "Patient Name", key: "name" },
    { label: "Treatment Name", key: "treatment" },
    { label: "Prescription Expiry", key: "expireDate" },
    { label: "Number Of Repeats", key: "number" },
    { label: "Prescription Status", key: "isSigned" },
  ];

  const [data, setdata] = useState();

  const [search, setsearch] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState();
  const [status, setStatus] = useState(0);
  const [excel, setExcel] = useState([]);
  const [download, setDownload] = useState(false);
  const [modal, setModal] = useState(false);
  const [url, setUrl] = useState("");
  const [UserStatus, setUserStatus] = useState(null);

  const { loader, setLoader, setTotalPatient, isTablet, itemPerPage } =
    useContext(appContext);

  const searchdata = search?.trim().split(/ +/).join(" ");
  const [selectedRows, setSelectedRows] = useState([]);



  const columns = [

    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerName: "Consultation no.",
      field: "Consultation",
      cellRenderer: (params) => (
        <span className="d-flex align-items-center">{params?.value}</span>
      ),
    },
    {
      headerName: "Patient Name",
      field: "name",
      cellRenderer: (params) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push(`/viewPatient`, {
                state: {
                  id: params?.data?.patientId,
                  condition: params?.data?.questionType,
                },
              })
            }
          >
            {params?.value}
          </span>
        </>
      ),
    },
    {
      headerName: "Treatment Name",
      field: "treatment",
    },
    {
      headerName: "Prescription Expiry",
      field: "expireDate",
    },
    {
      headerName: "Number Of Repeats",
      field: "number",
    },
    {
      headerName: "View/Download",
      field: "",
      cellRenderer: (params) => (
        <span>
          {params?.data?.pdfDownload ? (
            <>
              <FaEye
                className="me-2 cursor-pointer"
                size={20}
                onClick={() => handleView(params?.data?.pdfPrint)}
              />{" "}
              <HiOutlineDownload
                className="ms-2 cursor-pointer"
                size={20}
                onClick={() => handleDownload(params?.data?.pdfDownload)}
              />
            </>
          ) : (
            "-"
          )}
        </span>
      ),
    },
    {
      headerName: "Prescription Status",
      field: "isSigned",
      cellRenderer: (params) => (
        <span
          style={{
            cursor: "pointer",
            color: `${params?.value === 1 ? "#8b0000" : "#220a0a"}`,
          }}
          onClick={() =>
            params?.value === 1 &&
            handleStatusChange(params?.data?.pdfDownload, params?.value)
          }
        >
          {params?.value === 1 ? "Signed" : "Unsigned"}
        </span>
      ),
    },
  ];

// modify subscriptions data
  const subscriptionModel = data?.map((value) => {

    return {
      id: value.id,
      index: value?.Consultation,
      subscriptionModel: true,
      treatment: value.treatment,
      expireDate: value.expireDate,
      number_of_repeats: value.number_of_repeats,
      buttons: value?.pdfDownload ? (
        <>
          <FaEye
            className="me-2 cursor-pointer"
            size={20}
            onClick={() => handleView(value?.pdfPrint)}
          />{" "}
          <HiOutlineDownload
            className="ms-2 cursor-pointer"
            size={20}
            onClick={() => handleDownload(value?.pdfDownload)}
          />
        </>
      ) : (
        "-"
      ),
      name: (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/viewPatient`, {
              state: {
                id: value?.patientId,
                condition: value?.questionType,
              },
            });
          }}
        >
          {value?.name}
        </span>
      ),
      isSigned: (
        <h6
          className="consulation_list_li_data_inner_head_data status"
          style={{
            cursor: "pointer",
            backgroundColor: `${value?.isSigned === 1 ? "#8b00001a" : "#220a0a1a"
              }`,
            color: `${value?.isSigned === 1 ? "#8b0000" : "#220a0a"}`,
          }}
          onClick={() =>
            value?.isSigned === 1 &&
            handleStatusChange(value?.pdfDownload, value?.isSigned)
          }
        >
          {value?.isSigned === 1 ? "Signed" : "Unsigned"}
        </h6>
      ),
    };
  });

  // Pagination
  const handlePageChange = (pageNumber) => setPage(pageNumber);

  const handleNextClick = () => {
    setPage((prevPage) => prevPage + 1);
  };
  const handlePrevClick = () => setPage((prevPage) => prevPage - 1);

  const totalPages = Math.ceil(totalData / itemPerPage);

  // handle filter for signed and unaasigned
  const handleStatusChange = (url, status) => {
    setModal(true);
    setUrl(url);
    setStatus(status);
  };

  // pdf view
  const handleView = (url) => {
    const pdfUrl = url;
    window.open(pdfUrl, "_blank");
  };

  // handle download file with status change
  const handleDownload = (url) => {
    const pdfUrl = url;
    window.open(pdfUrl, "_blank");
    let body = {
      data: [url],
      status: "1",
    };
    ApiPut("doctor/patient/subscriptions", body)
      .then((res) => {
        getdata(bodyData);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  // get subscription data
  const bodyData = {
    limit: perPage,
    page: page,
    search: searchdata,
    signed: UserStatus,
  };
  useEffect(() => {
    getdata(bodyData);
  }, [page, perPage, UserStatus]);

  // hanlde search on search button click
  const HandleSubmit = () => {
    const body = {
      ...bodyData,
      search: searchdata,
    };
    getdata(body);
  };

  useEffect(() => {
    if (searchdata) {
      HandleSubmit();
    }
  }, [searchdata]);

    // hanlde search on search
  const HandleSearch = (SearchData) => {
    if (SearchData.length === 0) {
      getdata({ ...bodyData, search: "" });
    }
    setsearch(SearchData);
  };

  // get subscription data
  const getdata = (body) => {
    setLoader(true);
    ApiPost(`doctor/patient/subscription/get`, body)
      .then((res) => {
        setLoader(false);
        const data = res?.data?.data?.subscription_data.map(
          (val) => val?.patientId
        );
        setTotalPatient(data);
        const temp =
          res?.data?.data?.subscription_data &&
          res?.data?.data?.subscription_data.map((val, index) => {
            return {
              Consultation: (page - 1) * perPage + (index + 1),
              name: `${val?.patient?.[0]?.firstName && val?.patient?.[0]?.lastName
                ? `${val?.patient?.[0]?.firstName} ${val?.patient?.[0]?.lastName} `
                : "-"
                }`,
              treatment: val?.treatment?.[0]?.treatmentName
                ? val?.treatment?.[0]?.treatmentName
                : "-",
              frequency: val?.treatment?.[0]?.subscription_frequency,
              number: val?.treatment?.[0]?.number_of_repeats,
              payment: val?.paymentAmount,
              next:
                val?.nextPaymentDate &&
                  moment(val?.nextPaymentDate).format("LLL")
                  ? val?.nextPaymentDate &&
                  moment(val?.nextPaymentDate).format("LLL")
                  : "-",
              shippingtrackignno:
                val?.trackingNumber && val?.shipmentStatus === 1
                  ? val?.trackingNumber
                  : "-",
              shippingstatus: val?.shipmentStatus,
              paymentstatus: val?.paymentStatus,
              id: val?._id,
              questionType: val?.questionType,
              patientId: val?.patientId,
              treatmentId: val?.treatmentId,
              number_of_repeats: val?.subscriptionDifference,
              pdfPrint: val?.pdfPrint,
              pdfDownload: val?.pdfDownload,
              isSigned: val?.signed,
              expireDate: val?.expireDate === null ? "-" : moment(val?.expireDate).format("LLL"),
            };
          });
        setdata(temp);
        setTotalData(res?.data?.data?.state?.data_count);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  // export excel
  useEffect(() => {
    if (excel.length) {
      csvLinkRef?.current?.link.click();
      setExcel([]);
    }
  }, [excel]);

  const ExportExcel = () => {
    const body = {
      page: 1,
      search: searchdata,
    };
    ApiPost(`doctor/patient/subscription/get`, body)
      .then((res) => {
        const temp =
          res?.data?.data?.subscription_data &&
          res?.data?.data?.subscription_data.map((val, index) => {
            return {
              Consultation: (page - 1) * perPage + (index + 1),
              name: `${val?.patient?.[0]?.firstName && val?.patient?.[0]?.lastName
                ? `${val?.patient?.[0]?.firstName} ${val?.patient?.[0]?.lastName} `
                : "-"
                }`,
              treatment: val?.treatment?.[0]?.treatmentName
                ? val?.treatment?.[0]?.treatmentName
                : "-",
              frequency: val?.treatment?.[0]?.subscription_frequency,
              number: val?.treatment?.[0]?.number_of_repeats,
              expireDate: moment(val?.expireDate).format("LLL"),
              isSigned: val?.signed === 1 ? "Signed" : "Unsigned",
            };
          });
        setExcel(temp);
      })
      .catch((e) => { });
  };
// download pdf in bulk
  const handleDownloadMulti = () => {
    let body = {
      data: urlArray,
    };
    ApiPut("doctor/patient/subscription", body)
      .then((res) => {
        getdata(bodyData);
        urlArray?.map((url) => {
          const pdfUrl = url;
          window.open(pdfUrl, "_blank");
        });
        setDownload(false);
        urlArray = [];
      })
      .catch((err) => { });
  };

  let urlview = [];

  if (selectedRows && selectedRows.length > 0) {
    selectedRows.forEach((row) => {
      if (row.id) {
        urlview.push(row.id);
      }
    });
  }

// view pdf in bulk
  const handleViewMultiPdf = () => {
    let body = {
      ids: isTablet ? urlview : selectedRows,
    };
    ApiPost("doctor/patient/subscription/viewSelectedPdfs", body)
      .then((res) => {
        getdata(bodyData);
        let viewdata = res?.data?.data?.mergedPdfURL;
        window.open(viewdata, '_blank');
        setSelectedRows("")
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSubmit = () => {
    let body = {
      data: [url],
      status: status,
    };
    ApiPut("doctor/patient/subscriptions", body)
      .then((res) => {
        setUrl("");
        setModal(false);
        getdata(bodyData);
      })
      .catch((err) => { });
  };

  return (
    <>
      <div className="chatscreen admindashboard">
        <Row>
          <Col lg={2}>
            <SideDrawer />
          </Col>
          <Col
            lg={10}
            style={{
              height: "100vh",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Header />
            <div className="activepatient table_main">
              <div className="titlepatient d-flex justify-content-between align-items-center">
                <h4>Prescriptions</h4>
                <CSVLink
                  data={excel}
                  style={{ opacity: 0 }}
                  ref={csvLinkRef}
                  filename={"Subscription Model "}
                  headers={headers}
                ></CSVLink>
                <div className="searchbox">
                  {download && (
                    <button
                      className="button me-3"
                      onClick={() => handleDownloadMulti()}
                    >
                      Download
                    </button>
                  )}
                  <div className="main_search_box">
                    <input
                      placeholder="input search text"
                      className="  pe-3 search"
                      value={search}
                      onChange={(e) => HandleSearch(e.target.value)}
                    />
                    <button
                      className="searchbtn d-flex align-items-center"
                      onClick={HandleSubmit}
                    >
                      Search
                    </button>
                  </div>
                  <select
                    className="searchfilter me-1"
                    name="userStatus"
                    onChange={(e) => setUserStatus(Number(e.target.value))}
                  >
                    <option selected disabled hidden>
                      All
                    </option>
                    <option value="null">All</option>
                    <option value={1}>Signed</option>
                    <option value={2}>Unsigned</option>
                  </select>
                  {selectedRows?.length > 0 && (
                    <button
                      className="button"
                      onClick={() => handleViewMultiPdf()}
                    >
                      Print
                    </button>
                  )}
                  <div className="export_to_excel">
                    <button
                      className="button ms-3"
                      onClick={() => ExportExcel()}
                    >
                      Export to Excel
                    </button>
                  </div>
                </div>
              </div>
              <div className="SubscriptionModelDatatable">
                {isTablet ? (
                  <Table
                    columns={columns}
                    data={data}
                    page={page}
                    perpage={perPage}
                    setparpage={setPerPage}
                    setpage={setPage}
                    totalData={totalData}
                    setSelectedRows={setSelectedRows}
                  />
                ) : (
                  <div className="patient_list_data">
                    <ul className="consulation_list_ul_data">
                      {subscriptionModel?.map((value, index) => {
                        return <TableListCard key={index} data={value} setSelectedRows={setSelectedRows} selectedRows={selectedRows} />;
                      })}
                    </ul>
                    {totalData > itemPerPage && (
                      <Pagination
                        page={page}
                        handlePageChange={handlePageChange}
                        handleNextClick={handleNextClick}
                        handlePrevClick={handlePrevClick}
                        totalPages={totalPages}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="copyright">
                <p className="french">2023 Frenchie M.D Pty Ltd</p>
              </div>
            </div>
          </Col>
        </Row>
        {loader && <Loader />}
      </div>

      <Modal
        show={modal}
        onHide={() => {
          setModal(false);
          setUrl();
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Prescription Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="activepatient" style={{ marginTop: "20px" }}>
            <div className="medicinlist">
              <Form>
                <Row>
                  <div className="medicininput">
                    <span>Change Prescription Status</span>
                    <select
                      name="patientId"
                      onChange={(e) => setStatus(e?.target?.value)}
                      value={status}
                    >
                      <option value={1}>Signed</option>
                      <option value={2}>Unsigned</option>
                    </select>
                  </div>
                </Row>
                <div className="btnredsubmit">
                  <Button onClick={() => handleSubmit()}> Submit </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubscriptionModel;
