import "./ViewPatient.css";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Col, Form, Modal, Row, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import SideDrawer from "../SideDrawer/SideDrawer";
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { ApiGet, ApiPost, ApiPut } from "../../helpers/API/API_data";
import { appContext } from "../../helpers/AppContext";
import MyVerticallyCenteredModal from "../PathologyList/PathologyModal";
import Header from "../Header/Header";
import { AiOutlineEdit } from "react-icons/ai";
import { ErrorToast, SuccessToast } from "../../helpers/Toast";
import ViewPatientDoument from "./ViewPatientDoument";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import triageImg from "../../Assets/images/ai.svg";
import { RxDownload } from "react-icons/rx";
import { FiPrinter } from "react-icons/fi";
import Loader from "../../Component/Auth/loader/Loader";
import TextTruncate from "react-text-truncate";

const ViewPatient = ({ isMobile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [images, setimages] = useState();
  const [imgIndex, setImgIndex] = useState(0);
  const history = useHistory();
  const [PatientData, setPatientData] = useState({});
  const [unAssignConsult, setUnAssignConsult] = useState([]);
  const [patientTreartments, setPatientTreartments] = useState([]);
  const [cardData, setcardData] = useState([]);
  const [documentGet, setDocumentGet] = useState();
  const { setPatientProfileData } = useContext(appContext);
  const [allergies, setAllergies] = useState([]);
  const [modelshow, setModelshow] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [radio, setRadio] = React.useState(false);
  const [modalAllergies, setModalAllergies] = React.useState(false);
  const [closeconsult, setCloseconsult] = React.useState(false);
  const [addNotes, setAddNotes] = React.useState(false);
  const [Age, setAge] = useState();
  const [patientId, setPatientId] = React.useState("");
  const [pathologies, setpathologies] = React.useState("");
  const [expanded, setExpanded] = useState([]);
  const [expanded1, setExpanded1] = useState([]);
  const location = useLocation();
  const id = location?.state?.state?.id;

  const condition = location?.state?.state?.condition;

  useEffect(() => {
    getPatientDetails();
    getdoument();
  }, []);

  // get patient deatil
  const getPatientDetails = async () => {
    const body = {
      _id: id,
      condition: condition === "STI Screening" ? "STI/STD" : condition,
    };
    await ApiPost(`doctor/patient`, body)
      .then(async (res) => {
        const data = res?.data?.data?.[0];
        await setPatientData(res?.data?.data?.[0]);

        await setPatientTreartments(res?.data?.data?.[0]?.treatment);
        await setPatientProfileData(res?.data?.data?.[0]);
        await setcardData(res?.data?.data?.[0]?.card);
        await calculateAge(res?.data?.data[0]?.patient?.[0]?.dob);
        await setAllergies(
          res?.data?.data[0]?.patient_questions[0]?.questions.find(
            (it) => "qustionType" in it && it?.qustionType === "allergies"
          )?.answer
        );
        await setpathologies(res?.data?.data?.[0]?.assingedpathologies);
        const consults = data?.usertreatment.map((item) => item._id);
        const unAssignConsult = data?.questionTypes.filter(
          (type) => !consults.includes(type)
        );
        setUnAssignConsult(unAssignConsult);
      })
      .catch((e) => {});
  };

  const calculateAge = (dob) => {
    const today = new Date();

    let age =
      moment(today).format("DD-MM-YYYY").split("-")[2] - dob?.split("-")[2];
    setAge(age);
  };

  // get patient document
  const getdoument = () => {
    ApiGet(`doctor/patient_document/${id}`)
      .then((res) => {
        setDocumentGet(res?.data?.data?.[0]?.documents.reverse());
      })
      .catch((err) => {});
  };

  // handle update allergies
  const updateAllergies = () => {
    let body = {
      id: PatientData?.patient?.[0]?._id,
      allergies: !radio ? null : allergies,
    };
    if ((radio && allergies) || !radio) {
      ApiPut("doctor/update/allergies", body)
        .then((res) => {
          SuccessToast(res?.data?.message);
          setModalAllergies(false);
          getPatientDetails();
        })
        .catch((err) => {
          ErrorToast(err?.message);
        });
    } else {
      ErrorToast("Please Enter Allergies!");
    }
  };
  // download prescription
  const handleDownload = (treatmentId) => {
    setLoader(true);
    let ids = [];
    if (patientTreartments?.length > 0) {
      patientTreartments?.map((val) => {
        ids.push(val?._id);
      });
    }
    let body = {
      tratementId: treatmentId,
      patientId: id,
      type: 2,
    };

    ApiPost("doctor/pdf", body)
      .then((res) => {
        const pdfUrl = res?.data?.data;
        window.open(pdfUrl, "_blank");
        setLoader(false);
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setLoader(false);
      });
  };

  // print prescription
  const handlePrint = (treatmentId) => {
    setLoader(true);
    let ids = [];
    if (patientTreartments?.length > 0) {
      patientTreartments?.map((val) => {
        ids.push(val?._id);
      });
    }
    let body = {
      tratementId: treatmentId,
      patientId: id,
      type: 1,
    };

    ApiPost("doctor/pdf", body)
      .then((res) => {
        const pdfUrl = res?.data?.data;
        window.open(pdfUrl, "_blank");
        setLoader(false);
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setLoader(false);
      });
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue?.length <= 50) {
      setAllergies(inputValue);
    }
  };
  let noNotesFound = true;

  // Function to toggle expansion for a specific treatment summary
  const toggleExpansion = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  // Function to toggle expansion for a specific treatment summary
  const toggleExpansion1 = (index) => {
    const newExpanded1 = [...expanded1];
    newExpanded1[index] = !newExpanded1[index];
    setExpanded1(newExpanded1);
  };

  // navigate to iniate sceen
  const navigateInitiateChat = async () => {
    setLoader(true);
    let body = {
      search: "",
      page: 1,
      limit: 100000,
    };
    await ApiPost("doctor/room/get", body)
      .then((res) => {
        const userRoomId = res?.data?.data?.room_data?.find(
          (ele, i) => ele?.user?._id === id
        );
        if (userRoomId) {
          isMobile
            ? history.push(`/ChatScreen`, { patientId: id })
            : history.push(`/ChatScreenMobile/id/${id}`, { patientId: id });
        } else {
          ApiPost("doctor/add_doctor_room_with_patient", { patientId: id })
            .then((res) => {
              if (res?.data?.data?.userIds?.length) {
                isMobile
                  ? history.push(`/ChatScreen`, { patientId: id })
                  : history.push(`/ChatScreenMobile/id/${id}`, {
                      patientId: id,
                    });
              }
            })
            .catch((err) => {})
            .finally(() => {
              setLoader(false);
            });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoader(false);
      });
  };
  return (
    <>
      <div className="chatscreen admindashboard">
        <div className="loader">
          {loader && <Loader />}
          <Row>
            <Col lg={2}>
              <SideDrawer />
            </Col>
            <Col lg={10}>
              <Header />
              <div className="viewpatientmain">
                <div className="viewpatienttitle px-3">
                  <Col lg={7}>
                    <h4>View Patient</h4>
                  </Col>
                  <Col lg={5}>
                    <ul
                      className="uploaddoc"
                      style={{ columnGap: "20px", justifyContent: "end" }}
                    >
                      <li onClick={navigateInitiateChat}>
                        <Link to="#">Chat with patient</Link>
                      </li>
                    </ul>
                  </Col>
                </div>
                <Row>
                  {/* patient profile */}
                  <Col lg={7}>
                    <div className="profilepatient">
                      <h4 style={{ marginLeft: "1vh" }}>Profile</h4>
                      <ul className="profilepatientsubdetail">
                        <li>
                          <div className="profilename">
                            <span>Name</span>
                            <p>
                              {PatientData?.patient?.[0]?.firstName
                                ? `${PatientData?.patient?.[0]?.firstName} ${PatientData?.patient?.[0]?.lastName}`
                                : "-"}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="profilename">
                            <span>Phone number</span>
                            <p>
                              {PatientData?.patient?.[0]?.contactNumber
                                ? PatientData?.patient?.[0]?.contactNumber
                                : "-"}
                            </p>
                          </div>
                        </li>{" "}
                        <li>
                          <div className="profilename">
                            <span>Conditions</span>
                            <p>{PatientData?.condition ?? "-"}</p>
                          </div>
                        </li>{" "}
                        <li>
                          <div className="profilename">
                            <span>Email</span>
                            <p>
                              {PatientData?.patient?.[0]?.email
                                ? PatientData?.patient?.[0]?.email
                                : "-"}
                            </p>
                          </div>
                        </li>{" "}
                        <li>
                          <div className="profilename">
                            <span>Address</span>
                            <p>
                              {PatientData?.patient?.[0]?.billingAddress
                                ? PatientData?.patient?.[0]?.billingAddress
                                : "-"}
                              ,{" "}
                              {PatientData?.patient?.[0]?.city
                                ? PatientData?.patient?.[0]?.city
                                : "-"}
                              ,{" "}
                              {PatientData?.patient?.[0]?.state
                                ? PatientData?.patient?.[0]?.state
                                : "-"}
                              ,{" "}
                              {PatientData?.patient?.[0]?.country
                                ? PatientData?.patient?.[0]?.country
                                : "-"}
                              ,{" "}
                              {PatientData?.patient?.[0]?.postcode
                                ? PatientData?.patient?.[0]?.postcode
                                : "-"}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="profilename">
                            <div
                              className="d-flex align-items-center cursor-pointer"
                              onClick={() => {
                                setModalAllergies(true);
                                setRadio(
                                  PatientData?.patient?.[0]?.allergies
                                    ? true
                                    : false
                                );
                                setAllergies(
                                  PatientData?.patient?.[0]?.allergies
                                    ? PatientData?.patient?.[0]?.allergies
                                    : ""
                                );
                              }}
                            >
                              <span style={{ color: "#FA5252", opacity: "1" }}>
                                Allergies{" "}
                              </span>{" "}
                              <sapn className="ms-2">
                                <AiOutlineEdit />
                              </sapn>
                            </div>
                            <p
                              className="text-danger cursor-pointer p-2 text-white rounded-5"
                              style={{ backgroundColor: "#FA5252" }}
                              onClick={() => {
                                setModalAllergies(true);
                                setRadio(
                                  PatientData?.patient?.[0]?.allergies
                                    ? true
                                    : false
                                );
                                setAllergies(
                                  PatientData?.patient?.[0]?.allergies
                                    ? PatientData?.patient?.[0]?.allergies
                                    : ""
                                );
                              }}
                            >
                              {PatientData?.patient?.[0]?.allergies
                                ? PatientData?.patient?.[0]?.allergies
                                : "No"}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="profilename">
                            <span>Date of Birth</span>
                            <p>
                              {PatientData?.patient?.[0]?.dob
                                ? PatientData?.patient?.[0]?.dob
                                : "-"}
                            </p>
                          </div>
                        </li>{" "}
                        <li>
                          <div className="profilename">
                            <span>Gender</span>
                            <p>
                              {PatientData?.patient?.[0]?.gender?.toString()
                                ? (PatientData?.patient?.[0]?.gender?.toString() ===
                                    "0" &&
                                    "Male") ||
                                  (PatientData?.patient?.[0]?.gender === 1 &&
                                    "Female") ||
                                  (PatientData?.patient?.[0]?.gender === 2 &&
                                    "Other")
                                : "-"}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="profilename">
                            <span>Age</span>
                            <p>{Age ? `${Age} Year` : "-"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="profilename">
                            <span>Gender Identity</span>
                            <p>
                              {PatientData?.patient?.[0]?.identityGender
                                ? PatientData?.patient?.[0]?.identityGender
                                : "-"}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/* consult summary by TRIAGE AI */}
                    <div className="profilepatient mb-3">
                      <h4 style={{ marginLeft: "1vh" }}>
                        Triage AI{" "}
                        <img
                          src={triageImg}
                          width={25}
                          alt=""
                          style={{ marginTop: "-40px", marginLeft: "-5px" }}
                        />
                      </h4>

                      {PatientData.patient_questions?.length > 0 ? (
                        PatientData?.patient_questions?.map((val, i) => {
                          return (
                            <div className="summary">
                              <Accordion key={`${i}${val?.questionType}`}>
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                    {val?.questionType}
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className=" w-100">
                                      {val?.summary
                                        .split("\n")
                                        .map((line, index) => (
                                          <span key={index}>
                                            {line}
                                            <br />
                                          </span>
                                        ))}
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          );
                        })
                      ) : (
                        <span
                          className="d-flex justify-content-center my-3"
                          style={{
                            fontFamily: "Helvetica Neue",
                            padding: "5px",
                            minHeight: "46px",
                          }}
                        >
                          No Triage AI
                        </span>
                      )}
                    </div>
                    {/* regulart doctor detail */}
                    <div className="profilepatient mb-3 ">
                      <h4 style={{ marginLeft: "1vh" }}>Regular Doctor</h4>
                      {PatientData?.patient?.[0]?.additionalInfo
                        ?.regularDoctor ? (
                        <ul
                          className="profilepatientsubdetail"
                          style={{ rowGap: "20px" }}
                        >
                          <li>
                            <div className="profilename">
                              <span>First Name</span>
                              <p>
                                {
                                  PatientData?.patient?.[0]?.additionalInfo
                                    ?.regularDoctor?.firstName
                                }
                              </p>
                            </div>
                          </li>

                          <li>
                            <div className="profilename">
                              <span>Last Name</span>
                              <p>
                                {
                                  PatientData?.patient?.[0]?.additionalInfo
                                    ?.regularDoctor?.lastName
                                }
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="profilename">
                              <span>Clinic Name</span>
                              <p>
                                {
                                  PatientData?.patient?.[0]?.additionalInfo
                                    ?.regularDoctor?.clinicName
                                }
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="profilename">
                              <span>Email</span>
                              <p>
                                {
                                  PatientData?.patient?.[0]?.additionalInfo
                                    ?.regularDoctor?.email
                                }
                              </p>
                            </div>
                          </li>
                        </ul>
                      ) : (
                        <span
                          className="d-flex justify-content-center my-3"
                          style={{
                            fontFamily: "Helvetica Neue",
                            padding: "5px",
                            minHeight: "45px",
                          }}
                        >
                          No regular doctor shared
                        </span>
                      )}
                    </div>
                    {/* regular Medications detail */}
                    <div className="profilepatient mb-3 ">
                      <h4 style={{ marginLeft: "1vh" }}>
                        Regular Medications{" "}
                      </h4>
                      {PatientData?.patient?.[0]?.currentMedicine &&
                      PatientData?.patient?.[0]?.currentMedicine !== "No" ? (
                        <ul
                          className="profilepatientsubdetail"
                          style={{ rowGap: "20px" }}
                        >
                          <li>
                            <div className="profilename">
                              <p>
                                {PatientData?.patient?.[0]?.currentMedicine}
                              </p>
                            </div>
                          </li>
                        </ul>
                      ) : (
                        <span
                          className="d-flex justify-content-center my-3"
                          style={{
                            fontFamily: "Helvetica Neue",
                            padding: "5px",
                            minHeight: "45px",
                          }}
                        >
                          No regular medications
                        </span>
                      )}
                    </div>
                    {/* recent pathology detail */}
                    <div
                      className="profilepatient"
                      style={{
                        padding: "0 10px 10px 10px",
                        marginBottom: "16px",
                      }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{
                          borderBottom: "1px solid rgb(204 204 204 / 60%)",
                        }}
                      >
                        <h4 className="border-0">Recent Pathology</h4>
                      </div>
                      <div style={{ maxHeight: "160px", overflow: "auto" }}>
                        {pathologies?.length !== 0 ? (
                          pathologies?.map((val, i) => {
                            return (
                              <>
                                <ul
                                  key={`${i}${val?.pathology?.reportType}`}
                                  className="profilepatientsubdetail"
                                >
                                  <li>
                                    <div className="profilename">
                                      <span>Request Number</span>
                                      <p>
                                        {val?.pathology?.itemNumber
                                          ? val?.pathology?.itemNumber
                                          : "-"}
                                      </p>
                                    </div>
                                  </li>

                                  <li>
                                    <div>
                                      <div className="profilename">
                                        <span>Report Type</span>
                                      </div>

                                      {val?.SonicResultData?.length > 0 ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "start",
                                            wordWrap: "break-word",
                                          }}
                                          className={`pathologyName ${
                                            val?.pathologyResult ===
                                            "Not Detected"
                                              ? "text-success"
                                              : val?.pathologyResult ===
                                                "Detected"
                                              ? "text-danger"
                                              : ""
                                          }`}
                                        >
                                          {val?.SonicResultData?.map((v, i) => {
                                            return (
                                              <>
                                                <div key={i}>
                                                  {i + 1}. {v?.pathologyName} -
                                                  <span
                                                    className={`${
                                                      v?.result ===
                                                      "Not Detected"
                                                        ? "text-success"
                                                        : v?.result ===
                                                          "Detected"
                                                        ? "text-danger"
                                                        : ""
                                                    }`}
                                                  >
                                                    {" "}
                                                    {v?.result}
                                                  </span>
                                                </div>
                                              </>
                                            );
                                          })}
                                        </div>
                                      ) : (
                                        <div className="profilename">
                                          {val?.pathology?.reportType?.split(
                                            "and"
                                          )?.length > 0 ? (
                                            <div>
                                              <p>
                                                {
                                                  val?.pathology?.reportType?.split(
                                                    "and"
                                                  )[0]
                                                }
                                              </p>
                                              <p>
                                                {
                                                  val?.pathology?.reportType?.split(
                                                    "and"
                                                  )[1]
                                                }
                                              </p>
                                            </div>
                                          ) : (
                                            <div>
                                              {val?.pathology?.reportType}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </li>

                                  <li>
                                    <div className="profilename">
                                      <span>PDF Link</span>
                                      <p
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "start",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        {val?.SonicResultData?.length > 0 ? (
                                          <div>
                                            {" "}
                                            {i + 1}.{" "}
                                            <a
                                              href={val?.pdfURL}
                                              target="_blank"
                                              style={{ cursor: "pointer" }}
                                            >
                                              {" "}
                                              {val?.pdfURL
                                                ? val?.pdfURL
                                                    .split(".com/")
                                                    .slice(1)
                                                : "-"}
                                            </a>
                                          </div>
                                        ) : (
                                          "-"
                                        )}
                                      </p>
                                    </div>
                                  </li>
                                </ul>
                                {/* <hr /> */}
                                {i !== pathologies?.length - 1 && <hr />}
                              </>
                            );
                          })
                        ) : (
                          <span
                            className="d-flex justify-content-center my-3"
                            style={{ fontFamily: "Helvetica Neue" }}
                          >
                            No recent results
                          </span>
                        )}
                      </div>
                    </div>
                    {/* FRENCHIE RX */}
                    <div
                      className="profilepatient"
                      style={{
                        padding: "0 10px 10px 10px",
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{
                          borderBottom: "1px solid rgb(204 204 204 / 60%)",
                        }}
                      >
                        <h4 className="border-0">Frenchie Rx</h4>
                      </div>
                      <div style={{ maxHeight: "160px", overflow: "auto" }}>
                        {patientTreartments?.length !== 0 ? (
                          patientTreartments?.map((vel, i) => {
                            return (
                              <>
                                <ul
                                  key={`${i}${vel?.treatmentName}`}
                                  className="profilepatientsubdetail"
                                >
                                  <li>
                                    <div className="profilename">
                                      <span>Treatment</span>
                                      <p>
                                        {vel?.treatmentName
                                          ? vel?.treatmentName
                                          : "-"}
                                      </p>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="profilename">
                                      <span>Drug Name</span>
                                      <p>
                                        {vel?.drugName ? vel?.drugName : "-"}
                                      </p>
                                    </div>
                                  </li>{" "}
                                  <li>
                                    <div className="profilename">
                                      <span>Quantity</span>
                                      <p>
                                        {vel?.quantity ? vel?.quantity : "-"}
                                      </p>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="profilename">
                                      <span>Number of repeats</span>
                                      <p>{vel?.number_of_repeats}</p>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="profilename">
                                      <span>Next Payment Due</span>
                                      <p>
                                        {vel?.subscription?.nextPaymentDate
                                          ? moment(
                                              vel?.subscription?.nextPaymentDate
                                            )?.format("MM-DD-YYYY")
                                          : "-"}
                                      </p>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="profilename">
                                      <span>Payment Status</span>
                                      {vel?.paymentStatus === 0 ? (
                                        <p style={{ color: "#077E8C" }}>
                                          {"Pending"}
                                        </p>
                                      ) : vel?.paymentStatus === 1 ? (
                                        <p style={{ color: "#008000" }}>
                                          {"Payment Succsess"}
                                        </p>
                                      ) : (
                                        <p style={{ color: "#ff0000" }}>
                                          {"Failed"}
                                        </p>
                                      )}
                                    </div>
                                  </li>
                                  <li>
                                    {!vel?.documents?.length ? (
                                      <div className="profilename">
                                        <span>Download Prescription</span>
                                        <div
                                          className="d-flex mt-1 cursor-pointer"
                                          onClick={() =>
                                            handleDownload(vel?._id)
                                          }
                                        >
                                          <RxDownload
                                            color="#003b61"
                                            size={20}
                                          />
                                          <div className="ms-2">
                                            <p>Download</p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                  <li>
                                    {!vel?.documents?.length ? (
                                      <div className="profilename">
                                        <span>Print Prescription</span>
                                        <div
                                          className="d-flex mt-1 cursor-pointer"
                                          onClick={() => handlePrint(vel?._id)}
                                        >
                                          <FiPrinter
                                            color="#003b61"
                                            size={20}
                                          />
                                          <div className="ms-2">
                                            <p>Print</p>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                </ul>
                                {i !== patientTreartments?.length - 1 && <hr />}
                              </>
                            );
                          })
                        ) : (
                          <span
                            className="d-flex justify-content-center my-3"
                            style={{ fontFamily: "Helvetica Neue" }}
                          >
                            No current Frenchie Rx
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col lg={5}>
                    {/* consulations */}
                    <div
                      className="patientotherdetail"
                      // style={{ padding: "10px" }}
                    >
                      <div
                        className="patientothersubdetail"
                        style={{ padding: "5px 15px 10px", height: "318px" }}
                      >
                        <div className=" mb-2 constline d-flex justify-content-between">
                          <div className="patienttitle1">Consultations</div>
                        </div>

                        <div
                          style={{
                            maxHeight: "240px",
                            overflow: "auto",
                            fontFamily: "Helvetica Neue",
                          }}
                        >
                          <Row className="fw-bold mb-3 mt-2">
                            <Col className="rowheading">Condition</Col>
                            <Col className="rowheading">Treatment Name</Col>
                            <Col className="rowheading">Status</Col>
                            <Col className="rowheading">Date</Col>
                          </Row>
                          <hr className="m-2 hrclolor" />

                          {PatientData?.usertreatment?.length
                            ? PatientData?.usertreatment?.map((v, i) => (
                                <>
                                  <Row
                                    className="py-1 rowdata"
                                    style={{ fontSize: "14px" }}
                                    onClick={() => {
                                      history.push(
                                        `/ViewConsultation/${PatientData?.patientId}`,
                                        {
                                          state: {
                                            condition: v?._id,
                                            id: PatientData?.patientId,
                                            consultationId: PatientData
                                              ?.consulation?._id
                                              ? PatientData?.consulation?._id
                                              : PatientData?._id,
                                          },
                                        }
                                      );
                                    }}
                                  >
                                    <Col className="py-1 conditionhover">
                                      {v?._id}
                                    </Col>
                                    <Col className="py-1">
                                      <div>
                                        {v?.treatments?.map((val, i) => {
                                          return (
                                            <>
                                              {/* {val?.treatmentName} */}
                                              <div
                                                className="name py-1"
                                                title={val?.treatmentName}
                                              >
                                                {
                                                  val?.treatmentName?.split(
                                                    " "
                                                  )?.[0]
                                                }
                                                {val?.treatmentName?.split(" ")
                                                  .length > 1 && "..."}
                                              </div>
                                            </>
                                          );
                                        })}
                                      </div>
                                    </Col>
                                    <Col className="py-1">
                                      {v?.treatments[0]?.treatment_status ===
                                      0 ? (
                                        <span>{"Not Assigned"}</span>
                                      ) : v?.treatments[0]?.treatment_status ==
                                        1 ? (
                                        <span>{"Payment Pending"}</span>
                                      ) : v?.treatments[0]?.treatment_status ==
                                        2 ? (
                                        <span>{"In Progress"}</span>
                                      ) : v?.treatments[0]?.treatment_status ==
                                        3 ? (
                                        <span>{"Completed"}</span>
                                      ) : (
                                        "-"
                                      )}
                                    </Col>
                                    <Col>
                                      <div>
                                        {v?.treatments?.map((val, i) => {
                                          return (
                                            <>
                                              <div className="py-1">
                                                {moment(val?.createdAt).format(
                                                  "DD/MM/YYYY"
                                                )}
                                              </div>
                                            </>
                                          );
                                        })}
                                      </div>
                                    </Col>
                                  </Row>
                                  {i !==
                                    PatientData?.usertreatment?.length - 1 && (
                                    <hr className="m-2 hrclolor" />
                                  )}
                                </>
                              ))
                            : ""}
                          {unAssignConsult?.map((consult) => {
                            return (
                              <Row
                                className="py-1 rowdata"
                                style={{ fontSize: "14px" }}
                                onClick={() => {
                                  history.push(
                                    `/ViewConsultation/${PatientData?.patientId}`,
                                    {
                                      state: {
                                        condition: consult,
                                        id: PatientData?.patientId,
                                        consultationId: PatientData?.consulation
                                          ?._id
                                          ? PatientData?.consulation?._id
                                          : PatientData?._id,
                                      },
                                    }
                                  );
                                }}
                              >
                                <Col className="conditionhover">{consult}</Col>
                                <Col>-</Col>
                                <Col>Not Assigned</Col>
                                <Col>-</Col>
                              </Row>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {/*   Consultation Notes*/}
                    <div className="patientotherdetail">
                      <div
                        className="patientothersubdetail"
                        style={{ padding: "5px 15px 15px" }}
                      >
                        <div className="mb-3 constline d-flex justify-content-between">
                          <div className="patienttitle1">
                            Consultation Notes
                          </div>
                        </div>

                        <div style={{ maxHeight: "467px", overflow: "auto" }}>
                          <div>
                            {cardData?.length ? (
                              cardData?.map((v, i) => {
                                noNotesFound = false;
                                return (
                                  <div
                                    key={`${i}${v?.summery}`}
                                    onClick={() =>
                                      history.push(
                                        "/PatientSummryCard?type=patientCard",
                                        {
                                          state: { data: PatientData },
                                        }
                                      )
                                    }
                                    className="patienttitle consnotenew mb-0"
                                    style={{
                                      flexDirection: "column",
                                      fontFamily: "Helvetica Neue",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{ display: "flex", width: "100%" }}
                                    >
                                      <div className="application d-flex">
                                        <div
                                          style={{
                                            color: "#003b61",
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {i + 1}. Treatment Summary
                                        </div>
                                      </div>
                                      <div className="patientnumber">
                                        <div
                                          style={{
                                            color: "#212529",
                                            fontWeight: "500",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {moment(v?.date).format("LLL")}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="descripsum">
                                      <div
                                        style={{
                                          color: "#212529",
                                          fontWeight: "500",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {" "}
                                        Description:{" "}
                                      </div>
                                      <div
                                        style={{
                                          color: "#212529",
                                          fontWeight: "300",
                                          fontSize: "14px",
                                        }}
                                        className="mt-1"
                                      >
                                        <div className="wrapit">
                                          <TextTruncate
                                            line={expanded[i] ? null : 2}
                                            element="span"
                                            truncateText="…"
                                            text={v?.summery ? v?.summery : "-"}
                                            textTruncateChild={
                                              <a
                                                onClick={() =>
                                                  toggleExpansion(i)
                                                }
                                              >
                                                {expanded[i]
                                                  ? "Read less"
                                                  : "Read more"}
                                              </a>
                                            }
                                            onTruncate={(wasTruncated) => {
                                              if (wasTruncated) {
                                                setExpanded((prevExpanded) => {
                                                  const newExpanded = [
                                                    ...prevExpanded,
                                                  ];
                                                  newExpanded[i] = true;
                                                  return newExpanded;
                                                });
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div></div>
                            )}
                          </div>
                          <div>
                            {PatientData?.patient_questions?.map((v, i) => {
                              return (
                                <div key={i}>
                                  {v?.notes?.map((val, j) => {
                                    const noteText = val?.note || "";
                                    const lines = noteText
                                      .split("\n")
                                      .filter(Boolean);
                                    const truncatedLines = expanded1[i]
                                      ? lines
                                      : lines.slice(0, 2);
                                    noNotesFound = false;
                                    return (
                                      <div
                                        className="patienttitle consnotenew mt-3"
                                        style={{
                                          flexDirection: "column",
                                          fontFamily: "Helvetica Neue",
                                        }}
                                        key={j}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                          }}
                                        >
                                          <div className="application d-flex">
                                            <div
                                              style={{
                                                color: "#003b61",
                                                fontWeight: "500",
                                                fontSize: "16px",
                                              }}
                                            >
                                              {j + 1}. Doctor Notes
                                            </div>
                                          </div>
                                          <div className="patientnumber">
                                            <div
                                              style={{
                                                color: "#212529",
                                                fontWeight: "500",
                                                fontSize: "16px",
                                              }}
                                            >
                                              {moment(val?.notecreate).format(
                                                "LLL"
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="descripsum">
                                          <div
                                            style={{
                                              color: "#212529",
                                              fontWeight: "500",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {" "}
                                            Notes:{" "}
                                          </div>
                                          <div
                                            style={{
                                              color: "#212529",
                                              fontWeight: "300",
                                              fontSize: "14px",
                                            }}
                                            className="mt-1"
                                          >
                                            <div className="wrapit">
                                              {/* <div dangerouslySetInnerHTML={{ __html: val?.note.replace(/\n/g, '<br>') }} ></div> */}

                                              <div className="wrapit">
                                                {truncatedLines.map(
                                                  (line, index) => (
                                                    <div key={index}>
                                                      {line}
                                                    </div>
                                                  )
                                                )}
                                                {lines.length > 2 &&
                                                  !expanded1[i] && (
                                                    <div className="read-more">
                                                      <a
                                                        onClick={() =>
                                                          toggleExpansion1(i)
                                                        }
                                                        style={{
                                                          color: "#b4555d",
                                                        }}
                                                      >
                                                        ...Read more
                                                      </a>
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        {noNotesFound && (
                          <span
                            className="d-flex justify-content-center"
                            style={{ fontFamily: "Helvetica Neue" }}
                          >
                            There are No Data
                          </span>
                        )}
                      </div>
                    </div>
                    <ViewPatientDoument datas={documentGet} id={id} />
                  </Col>
                </Row>
                {/* Consult Question Summary */}
                <Row>
                  <div className="col-lg-12">
                    <div className="cunsultncyquestion">
                      <div className="d-flex justify-content-between">
                        <h3>Consult Question Summary</h3>
                      </div>
                      {PatientData?.patient_questions &&
                        PatientData?.patient_questions.map((item, index) => {
                          return (
                            <Accordion key={`${index}${item?.questions}`}>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  {item?.questionType === "STI/STD"
                                    ? "STI Screening"
                                    : item?.questionType}
                                  <span>
                                    {moment(item?.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className=" w-100">
                                    {item?.questions &&
                                      item?.questions
                                        .slice(1, 3)
                                        .map((ele, i) => {
                                          return (
                                            <>
                                              <div
                                                key={`${i}${ele?.question}`}
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                                className="consultres"
                                              >
                                                <div>
                                                  <h4 className="questions__text">
                                                    <span className="numblue">
                                                      {i + 1}
                                                    </span>
                                                    {ele?.question}
                                                  </h4>
                                                  <h4 className="answer__text">
                                                    {ele?.answer}
                                                  </h4>
                                                </div>
                                                {i ===
                                                  item?.questions.slice(1, 3)
                                                    ?.length -
                                                    1 && (
                                                  <div>
                                                    <div className="ViewMorequestions">
                                                      <a
                                                        onClick={() =>
                                                          history.push(
                                                            `/questionsummary/${id}`,
                                                            {
                                                              state: {
                                                                id: id,
                                                                ind: index,
                                                                condition:
                                                                  condition,
                                                                consultationId:
                                                                  PatientData
                                                                    ?.consulation
                                                                    ?._id
                                                                    ? PatientData
                                                                        ?.consulation
                                                                        ?._id
                                                                    : PatientData?._id,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      >
                                                        View More
                                                      </a>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                              <hr></hr>
                                            </>
                                          );
                                        })}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          );
                        })}
                    </div>
                  </div>
                  {isOpen && (
                    <Lightbox
                      mainSrc={images?.[imgIndex]}
                      nextSrc={images?.[(imgIndex + 1) % images?.length]}
                      prevSrc={
                        images?.[
                          (imgIndex + images?.length - 1) % images?.length
                        ]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setImgIndex(
                          (imgIndex + images?.length - 1) % images?.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setImgIndex((imgIndex + 1) % images?.length)
                      }
                    />
                  )}
                </Row>
              </div>
              <div className="coptrights">
                <p style={{ fontWeight: "700" }}>©2023 Frenchie M.D Pty Ltd</p>
              </div>
            </Col>
          </Row>

          {modelshow && (
            <MyVerticallyCenteredModal
              show={modelshow}
              onHide={() => setModelshow(false)}
              patientId={patientId}
              isDisable={true}
              condition={condition}
            />
          )}

          <Modal
            show={modalAllergies}
            onHide={() => {
              setModalAllergies(false);
              setRadio(false);
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Allergies
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className="activepatient p-0 mx-4"
                style={{ marginTop: "20px" }}
              >
                <div className="">
                  <Form>
                    <Row>
                      <label className="text-white mt-2">
                        Do you have any allergies?
                      </label>
                      <div className="radio text-white ">
                        <label className="d-flex align-items-center">
                          <input
                            type="radio"
                            name="isPublished"
                            value="true"
                            className="me-3 custom-radio"
                            checked={radio === true}
                            onChange={(event) =>
                              setRadio(
                                event.currentTarget.value === "true"
                                  ? true
                                  : false
                              )
                            }
                          />
                          Yes
                        </label>
                      </div>
                      <div className="radio text-white">
                        <label className="d-flex align-items-center">
                          <input
                            type="radio"
                            name="isPublished"
                            value="false"
                            className="me-3 custom-radio"
                            checked={radio === false}
                            onChange={(event) =>
                              setRadio(
                                event.currentTarget.value === "true"
                                  ? true
                                  : false
                              )
                            }
                          />
                          No
                        </label>
                      </div>

                      {radio && (
                        <>
                          <label className="text-white mt-4">Allergies</label>
                          <div className="p-2 rounded">
                            <input
                              type="text"
                              value={allergies}
                              placeholder="Enter Allergies"
                              onChange={handleInputChange}
                              className="form-control alg-input"
                            />
                          </div>
                        </>
                      )}
                    </Row>
                    <div className="btnredsubmit">
                      <Button onClick={updateAllergies}>Submit</Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={closeconsult}
            onHide={() => {
              setCloseconsult(false);
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Close Consult
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="closeconsult">
              <div className="p-0 px-4">
                <div className="">
                  <Form>
                    <Row>
                      <label className="text-white text-center py-4">
                        Do you have any consult?
                      </label>
                    </Row>
                    <div className="btnredsubmit m-0 py-2 d-flex justify-content-center gap-3">
                      <Button>Yes</Button>
                      <Button onClick={() => setCloseconsult(false)}>No</Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={addNotes}
            onHide={() => {
              setAddNotes(false);
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Add Notes
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="closeconsult">
              <div className="p-0 px-4">
                <div className="">
                  <Form>
                    <Row>
                      <label className="text-white py-2">Note:</label>
                      <div className="medicininput ">
                        <input
                          type="text"
                          placeholder="Add Notes"
                          className=""
                        />
                      </div>
                    </Row>
                    <div className="btnredsubmit my-2">
                      <Button>Submit</Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ViewPatient;
