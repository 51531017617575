import "./SetUpProfile.css";
import React from "react";
import profile from "../../Assets/images/editprofile.png";
import edit from "../../Assets/images/pencile.png";
import {  Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import {
  APIUploadPost,
  ApiPost,
  ApiPut,
  Bucket,
} from "../../helpers/API/API_data";
import { ErrorToast, SuccessToast } from "../../helpers/Toast";
import moment from "moment";
import { useEffect } from "react";
import { useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import { isEighteenYearsOld } from "../../utils/dateAndTime.util";

const SetUpProfile = (props) => {
  const [val, setVal] = useState({});
  const [checked, setChecked] = useState([]);
  const [addressdata, setAddressdata] = useState();
  const [postalcode, setPostalcode] = useState();
  const [profileImage, setprofileImage] = useState([]);
  const [documentImg, setDocumnetImg] = useState("");
  const inputRef = useRef(null);
  const autoCompleteRef = useRef(null);
  const [isEdit, setIsEdit] = useState(false);
  const history = useHistory();
  const [specialities, setSpecialities] = useState({
    erectileDysfunction: false,
    prematureEjaculation: false,
    stiStd: false,
    thrushBacterialVaginosis: false,
    urinaryTractInfection: false,
    emergencyContraception: false,
    emergencyContraceptionPlanB: false,
  });


// pre filled value on edit
  useEffect(() => {
    const url = new URL(window.location.href);
    setIsEdit(url.searchParams.get("isEdit"));
    const temp = JSON.parse(localStorage.getItem("Doctordetails"));
    if (temp) {
      setVal({
        firstName: `${temp?.firstName.split("Dr ")[1]}`,
        lastName: temp?.lastName,
        contactNumber: temp?.contactNumber,
        pronounce: temp.additionalInfo?.pronouns,
        dob: temp?.additionalInfo?.dob,
        PrescriberNumber: temp?.additionalInfo?.prescriberNumber,
        postcode: temp?.postcode,
        address: temp?.address,
        bio: temp?.additionalInfo?.bio,
        other: temp?.additionalInfo?.othersymptom[0],
        gender: temp?.gender,
        identityGender: temp?.identityGender,
        frenchieProviderNumber: temp?.additionalInfo?.frenchieProviderNumber,
      });
      setprofileImage(temp?.image);
      setDocumnetImg(temp?.digitalSign);
      setSpecialities({
        erectileDysfunction:
          temp?.additionalInfo?.conditionSpecialities?.includes(
            "Erectile Dysfunction"
          ),
        prematureEjaculation:
          temp?.additionalInfo?.conditionSpecialities?.includes(
            "Premature Ejaculation"
          ),
        stiStd:
          temp?.additionalInfo?.conditionSpecialities?.includes("STI/STD"),
        thrushBacterialVaginosis:
          temp?.additionalInfo?.conditionSpecialities?.includes(
            "Thrush / Bacterial Vaginosis"
          ),
        urinaryTractInfection:
          temp?.additionalInfo?.conditionSpecialities?.includes(
            "Urinary Tract Infection"
          ),
      });
      setChecked(temp?.additionalInfo?.conditionSpecialities)
    }
  }, []);

  useEffect(() => {
    if (postalcode || addressdata) {
      setVal({
        ...val,
        address: addressdata,
        postcode: postalcode,
      });
    }
  }, [addressdata, postalcode]);

  // google map places auto complete
  const handlePlaceChanged = () => {
    const place = autoCompleteRef.current.getPlace();
    const postalcode = place.address_components.find((ele) =>
      ele.types.includes("postal_code")
    ).long_name;
    setPostalcode(postalcode);
    const Addressdata =
      place.name +
      place.address_components
        .map((item, i) =>
          i !== 0 && i !== place.address_components.length - 1
            ? item.long_name
            : ""
        )
        .toString();
    setAddressdata(Addressdata);
  };
  useEffect(() => {
    if (window) {
      autoCompleteRef.current =
        window.google?.maps &&
        new window.google.maps.places.Autocomplete(inputRef.current);
      autoCompleteRef.current?.addListener("place_changed", handlePlaceChanged);
    }
  }, []);

  // input handle changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setVal({ ...val, [name]: value });
  };

  // submit all doctor profile detail
  const handleSubmit = async () => {
    if (!profileImage || profileImage.length === 0) {
      console.error("Profile image is null or empty");
      ErrorToast("Profile image is null or empty");

      return;
    }
    const formData = new FormData();
    formData.append("image", profileImage[0]);
    await APIUploadPost("upload/profile_image", formData)
      .then(async (res) => {
          if (
            val?.firstName &&
            val?.lastName &&
            (val?.gender === 0 || val?.gender) &&
            val?.address &&
            val?.postcode &&
            val?.bio &&
            val?.contactNumber
          ) {
            if (checked.length === 0) {
              ErrorToast("Please select at least one specialty");
              
          
            }
              if (
                val?.contactNumber?.substring(0, 3) === "+61" ||  val?.contactNumber?.substring(0, 1) === "0"
              ) {
                if (isEighteenYearsOld(val?.dob)) {
                  const body = {
                    firstName: `Dr ${val?.firstName.charAt(0).toUpperCase() + val?.firstName.slice(1)
                      }`,
                    lastName:
                      val?.lastName.charAt(0).toUpperCase() + val?.lastName.slice(1),
                    contactNumber: val?.contactNumber,
                    gender: val?.gender,
                    identityGender: val?.identityGender,
                    address: val?.address,
                    postcode: val?.postcode,
                    profilePhase: !isEdit ? 1 : undefined,
                    additionalInfo: {
                      pronouns: val?.pronounce,
                      registrationNo: 0,
                      conditionSpecialities: checked,
                      prescriberNumber: val?.PrescriberNumber,
                      othersymptom: [val?.other],
                      bio: val?.bio,
                      dob: val?.dob,
                      frenchieProviderNumber: val?.frenchieProviderNumber,
                    },
                    image: res?.data?.data?.image,
                    digitalSign: documentImg,
                    flag:0
                  };
                  await ApiPut("doctor", body)
                    .then((res) => {
                      SuccessToast("SetupProfile Successfully!!!");
                      if (res?.data?.data?.profilePhase === 0) {
                        history.push("/SetUpProfile");
                      } else if (isEdit) {
                        const resData = res?.data?.data;
                        localStorage.setItem(
                          "Doctordetails",
                          JSON.stringify(res?.data?.data)
                        );
                        const logindata =
                          localStorage.getItem("logindata") &&
                          JSON.parse(localStorage.getItem("logindata"));
                        localStorage.setItem(
                          "logindata",
                          JSON.stringify({
                            ...logindata,
                            firstName: resData?.firstName,
                            lastName: resData?.lastName,
                            postcode: resData?.postcode,
                            contactNumber: resData?.contactNumber,
                            address: resData?.address,
                            additionalInfo: {
                              bio: resData?.additionalInfo?.bio,
                              conditionSpecialities:
                                resData?.additionalInfo?.conditionSpecialities,
                              dob: resData?.additionalInfo?.dob,
                              frenchieProviderNumber:
                                resData?.additionalInfo?.frenchieProviderNumber,
                              othersymptom: resData?.additionalInfo?.othersymptom,
                              prescriberNumber:
                                resData?.additionalInfo?.prescriberNumber,
                              pronouns: resData?.additionalInfo?.pronouns,
                              registrationNo: resData?.additionalInfo?.registrationNo,
                            },
                          })
                        );
                        history.push("/SetUpAppoinmentSchedule?isEdit=true");
                      } else if (res?.data?.data?.profilePhase === 1) {
                        localStorage.setItem(
                          "Doctordetails",
                          JSON.stringify(res?.data?.data)
                        );
                        history.push("/SetUpAppoinmentSchedule");
                      } else if (res?.data?.data?.profilePhase === 2) {
                        history.push("/Dashboard");
                      }
                    })
                    .catch((err) => {
                      console.log("err", err);
                      ErrorToast(err?.message);
                    });
                }else{
                  ErrorToast("Age must be 18 or older.");
                }
         
              }else{
                ErrorToast("Please Enter Your country code or 0");
                return;
              }
          
          } else {
            ErrorToast("All Field Required");

          }
      })
      .catch((err) => {
        console.log("err", err);
      }).finally(()=>{

      })
  };

  // profile image upload
  const handleimage = (e) => {
    let file = e.target.files[0];
    let fileURL = URL.createObjectURL(file);
    file.fileURL = fileURL;

    setprofileImage([file]);
  };

  const logindata = JSON.parse(localStorage.getItem("logindata"));

  // handle conditions
  const handleChangeCheckbox = (e) => {
    if (e.target.name === "Erectile Dysfunction") {
      setSpecialities({
        ...specialities,
        erectileDysfunction: e.target.checked,
      });
    } else if (e.target.name === "Premature Ejaculation") {
      setSpecialities({
        ...specialities,
        prematureEjaculation: e.target.checked,
      });
    } else if (e.target.name === "STI/STD") {
      setSpecialities({ ...specialities, stiStd: e.target.checked });
    } else if (e.target.name === "Thrush / Bacterial Vaginosis") {
      setSpecialities({
        ...specialities,
        thrushBacterialVaginosis: e.target.checked,
      });
    } else if (e.target.name === "Urinary Tract Infection") {
      setSpecialities({
        ...specialities,
        urinaryTractInfection: e.target.checked,
      });
    }

    if (checked.includes(e.target.name)) {
      setChecked(checked.filter((item) => item !== e.target.name));
    } else {
      setChecked([...checked, e.target.name]);
    }
  };

  // upload digital signature
  const uploadDocument = (e) => {
    let formData = new FormData();
    formData.append("image", e.target.files[0]);
    APIUploadPost(`upload/document`, formData)
      .then((res) => {
        setDocumnetImg(res?.data?.data?.image);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };


  return (
    <>
      <div className="step">
        <ul className="stepdetail">
          <li>
            <span></span>
            <p>Setup your profile</p>
          </li>
          <li>
            <span></span>
            <p>Setup your availability to consult</p>
          </li>
        </ul>
      </div>
      <div className="editprofiledetail setpro">
        <div className="setproheading">
          <h3>Setup your profile</h3>
        </div>
        <div className="editprofile">
          <img
            src={
              profileImage?.length > 0 || profileImage !== null
                ? profileImage[0]?.fileURL
                  ? profileImage[0]?.fileURL
                  : profileImage
                    ? Bucket + profileImage
                    : profile
                : profile
            }
            style={{
              height: "120px ",
              width: "120px",
              backgroundColor: "#dfdcd8",
            }}
            alt=""
            className="br-50 rounded-circle"
          />
          <div className="position-absolute labelFile">
            <label htmlFor="images" className="">
              <div className="editIcon">
                <img src={edit} alt="" />
              </div>
              <input
                type="file"
                id="images"
                name="profile_avatar"
                accept=".png, .jpg, .jpeg"
                hidden
                onChange={(e) => handleimage(e)}
              />
            </label>
          </div>
        </div>

        <div className="paymentinfo ">
          {/* profile detail field */}
          <Row>
            <Col lg={6}>
              <div className="profiledetail">
                <span>
                  <svg
                    width="12"
                    height="17"
                    viewBox="0 0 12 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 16.5H10.5V15C10.5 14.4033 10.2629 13.831 9.84099 13.409C9.41903 12.9871 8.84674 12.75 8.25 12.75H3.75C3.15326 12.75 2.58097 12.9871 2.15901 13.409C1.73705 13.831 1.5 14.4033 1.5 15V16.5H0V15C0 14.0054 0.395088 13.0516 1.09835 12.3483C1.80161 11.6451 2.75544 11.25 3.75 11.25H8.25C9.24456 11.25 10.1984 11.6451 10.9017 12.3483C11.6049 13.0516 12 14.0054 12 15V16.5ZM6 9.75C5.40905 9.75 4.82389 9.6336 4.27792 9.40746C3.73196 9.18131 3.23588 8.84984 2.81802 8.43198C2.40016 8.01412 2.06869 7.51804 1.84254 6.97208C1.6164 6.42611 1.5 5.84095 1.5 5.25C1.5 4.65905 1.6164 4.07389 1.84254 3.52792C2.06869 2.98196 2.40016 2.48588 2.81802 2.06802C3.23588 1.65016 3.73196 1.31869 4.27792 1.09254C4.82389 0.866396 5.40905 0.75 6 0.75C7.19347 0.75 8.33807 1.22411 9.18198 2.06802C10.0259 2.91193 10.5 4.05653 10.5 5.25C10.5 6.44347 10.0259 7.58807 9.18198 8.43198C8.33807 9.27589 7.19347 9.75 6 9.75ZM6 8.25C6.79565 8.25 7.55871 7.93393 8.12132 7.37132C8.68393 6.80871 9 6.04565 9 5.25C9 4.45435 8.68393 3.69129 8.12132 3.12868C7.55871 2.56607 6.79565 2.25 6 2.25C5.20435 2.25 4.44129 2.56607 3.87868 3.12868C3.31607 3.69129 3 4.45435 3 5.25C3 6.04565 3.31607 6.80871 3.87868 7.37132C4.44129 7.93393 5.20435 8.25 6 8.25Z"
                      fill="#003B61"
                    />
                  </svg>
                  First Name *
                </span>
                <input
                  type="text"
                  placeholder="Your First Name"
                  name="firstName"
                  onChange={handleChange}
                  value={val?.firstName}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="profiledetail">
                <span>Last Name *</span>
                <input
                  type="text"
                  placeholder="Your Last Name"
                  name="lastName"
                  onChange={handleChange}
                  value={val?.lastName}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="profiledetail">
                <span>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.6">
                      <path
                        d="M2.25 2.25H15.75C15.9489 2.25 16.1397 2.32902 16.2803 2.46967C16.421 2.61032 16.5 2.80109 16.5 3V15C16.5 15.1989 16.421 15.3897 16.2803 15.5303C16.1397 15.671 15.9489 15.75 15.75 15.75H2.25C2.05109 15.75 1.86032 15.671 1.71967 15.5303C1.57902 15.3897 1.5 15.1989 1.5 15V3C1.5 2.80109 1.57902 2.61032 1.71967 2.46967C1.86032 2.32902 2.05109 2.25 2.25 2.25V2.25ZM15 5.4285L9.054 10.7535L3 5.412V14.25H15V5.4285ZM3.38325 3.75L9.04575 8.7465L14.6265 3.75H3.38325Z"
                        fill="#003B61"
                      />
                    </g>
                  </svg>
                  Email *
                </span>
                <input
                  type="email"
                  placeholder="dustin@realista.com.au"
                  value={logindata?.email}
                  disabled
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="profiledetail">
                <span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.0245 6.0115C5.72825 7.24786 6.75214 8.27175 7.9885 8.9755L8.6515 8.047C8.75812 7.8977 8.91576 7.79266 9.0946 7.75175C9.27344 7.71084 9.46108 7.7369 9.622 7.825C10.6827 8.40469 11.8542 8.75333 13.0593 8.848C13.2473 8.8629 13.4229 8.94815 13.5509 9.08674C13.6789 9.22533 13.75 9.40708 13.75 9.59575V12.9423C13.75 13.1279 13.6812 13.3071 13.5568 13.4449C13.4324 13.5828 13.2612 13.6696 13.0765 13.6885C12.679 13.7297 12.2785 13.75 11.875 13.75C5.455 13.75 0.25 8.545 0.25 2.125C0.25 1.7215 0.27025 1.321 0.3115 0.9235C0.330441 0.738773 0.417238 0.567641 0.555092 0.443225C0.692946 0.31881 0.872054 0.24996 1.05775 0.25H4.40425C4.59292 0.249976 4.77467 0.321064 4.91326 0.449088C5.05185 0.577112 5.1371 0.752668 5.152 0.94075C5.24667 2.14584 5.59531 3.31726 6.175 4.378C6.2631 4.53892 6.28916 4.72656 6.24825 4.9054C6.20734 5.08424 6.1023 5.24188 5.953 5.3485L5.0245 6.0115V6.0115ZM3.133 5.51875L4.558 4.501C4.15359 3.62807 3.87651 2.70163 3.73525 1.75H1.7575C1.753 1.8745 1.75075 1.99975 1.75075 2.125C1.75 7.717 6.283 12.25 11.875 12.25C12.0002 12.25 12.1255 12.2477 12.25 12.2425V10.2648C11.2984 10.1235 10.3719 9.84641 9.499 9.442L8.48125 10.867C8.0715 10.7078 7.67351 10.5198 7.29025 10.3045L7.24675 10.2797C5.77568 9.44254 4.55746 8.22432 3.72025 6.75325L3.6955 6.70975C3.48018 6.32649 3.29221 5.9285 3.133 5.51875V5.51875Z"
                      fill="#003B61"
                    />
                  </svg>
                  Mobile Number *
                </span>
                <input
                  type="text"
                  maxLength={10}
                  pattern="\d{10}"
                  placeholder="0481 288 073"
                  name="contactNumber"
                  onChange={handleChange}
                  value={val?.contactNumber}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="profiledetail">
                <span>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 16.5C3 14.9087 3.63214 13.3826 4.75736 12.2574C5.88258 11.1321 7.4087 10.5 9 10.5C10.5913 10.5 12.1174 11.1321 13.2426 12.2574C14.3679 13.3826 15 14.9087 15 16.5H13.5C13.5 15.3065 13.0259 14.1619 12.182 13.318C11.3381 12.4741 10.1935 12 9 12C7.80653 12 6.66193 12.4741 5.81802 13.318C4.97411 14.1619 4.5 15.3065 4.5 16.5H3ZM9 9.75C6.51375 9.75 4.5 7.73625 4.5 5.25C4.5 2.76375 6.51375 0.75 9 0.75C11.4863 0.75 13.5 2.76375 13.5 5.25C13.5 7.73625 11.4863 9.75 9 9.75ZM9 8.25C10.6575 8.25 12 6.9075 12 5.25C12 3.5925 10.6575 2.25 9 2.25C7.3425 2.25 6 3.5925 6 5.25C6 6.9075 7.3425 8.25 9 8.25Z"
                      fill="#003B61"
                    />
                  </svg>
                  Pronouns *
                </span>
                <input
                  type="text"
                  maxLength={10}
                  pattern="\d{10}"
                  placeholder="He"
                  name="pronounce"
                  onChange={handleChange}
                  value={val?.pronounce}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="profiledetail">
                <span>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 16.5C3 14.9087 3.63214 13.3826 4.75736 12.2574C5.88258 11.1321 7.4087 10.5 9 10.5C10.5913 10.5 12.1174 11.1321 13.2426 12.2574C14.3679 13.3826 15 14.9087 15 16.5H13.5C13.5 15.3065 13.0259 14.1619 12.182 13.318C11.3381 12.4741 10.1935 12 9 12C7.80653 12 6.66193 12.4741 5.81802 13.318C4.97411 14.1619 4.5 15.3065 4.5 16.5H3ZM9 9.75C6.51375 9.75 4.5 7.73625 4.5 5.25C4.5 2.76375 6.51375 0.75 9 0.75C11.4863 0.75 13.5 2.76375 13.5 5.25C13.5 7.73625 11.4863 9.75 9 9.75ZM9 8.25C10.6575 8.25 12 6.9075 12 5.25C12 3.5925 10.6575 2.25 9 2.25C7.3425 2.25 6 3.5925 6 5.25C6 6.9075 7.3425 8.25 9 8.25Z"
                      fill="#003B61"
                    />
                  </svg>
                  Digital Signature *
                </span>
                <input type="file" onChange={uploadDocument} />
                {documentImg && (
                  <div className="mt-3 d-flex align-items-center">
                    <img
                      src={Bucket + documentImg}
                      alt=""
                      height={100}
                      width={100}
                    />
                    <RxCross2
                      className="ms-2 cursor-pointer"
                      color="#003b61"
                      size={20}
                      onClick={() => setDocumnetImg("")}
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col lg={6}>
              <div className="profiledetail">
                <span>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.25 2.25H15.75C15.9489 2.25 16.1397 2.32902 16.2803 2.46967C16.421 2.61032 16.5 2.80109 16.5 3V15C16.5 15.1989 16.421 15.3897 16.2803 15.5303C16.1397 15.671 15.9489 15.75 15.75 15.75H2.25C2.05109 15.75 1.86032 15.671 1.71967 15.5303C1.57902 15.3897 1.5 15.1989 1.5 15V3C1.5 2.80109 1.57902 2.61032 1.71967 2.46967C1.86032 2.32902 2.05109 2.25 2.25 2.25V2.25ZM15 8.25H3V14.25H15V8.25ZM15 6.75V3.75H3V6.75H15ZM10.5 11.25H13.5V12.75H10.5V11.25Z"
                      fill="#003B61"
                    />
                  </svg>
                  Gender *
                </span>
                <select
                  name="gender"
                  placeholder="Your Enter Gender"
                  value={val?.gender}
                  onChange={handleChange}
                >
                  <option selected disabled hidden>
                    select
                  </option>
                  <option value={0}>Male</option>
                  <option value={1}>Female</option>
                  <option value={2}>Intersex</option>
                </select>
              </div>
            </Col>
            <Col lg={6}>
              <div className="profiledetail">
                <span>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.25 1.5H13.5C13.6989 1.5 13.8897 1.57902 14.0303 1.71967C14.171 1.86032 14.25 2.05109 14.25 2.25V15.75C14.25 15.9489 14.171 16.1397 14.0303 16.2803C13.8897 16.421 13.6989 16.5 13.5 16.5H4.5C4.30109 16.5 4.11032 16.421 3.96967 16.2803C3.82902 16.1397 3.75 15.9489 3.75 15.75V0H5.25V1.5ZM5.25 6.75H12.75V3H5.25V6.75ZM5.25 8.25V15H12.75V8.25H5.25Z"
                      fill="#003B61"
                    />
                  </svg>
                  Current Gender Identity *
                </span>
                <select
                  name="identityGender"
                  placeholder="Your Gender Identity"
                  value={val?.identityGender}
                  onChange={handleChange}
                >
                  <option selected disabled hidden>
                    select
                  </option>
                  <option value={"Male"}>Male</option>
                  <option value={"Female"}>Female</option>
                  <option value={"Trans Feminine / Trans Female"}>
                    Trans Feminine / Trans Female
                  </option>
                  <option value={"I don’t identify with any of the above"}>
                    I don’t identify with any of the above
                  </option>
                  <option value={"Trans Masculine / Trans Male"}>
                    Trans Masculine / Trans Male
                  </option>
                </select>
              </div>
            </Col>
            <Col lg={12}>
              <div className="profiledetail end-date">
                <span>
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.000000pt"
                    height="auto"
                    viewBox="0 0 512.000000 512.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      fill="#003B61"
                      stroke="none"
                    >
                      <path
                        d="M1315 5108 c-49 -17 -92 -55 -114 -101 -18 -36 -21 -62 -21 -159 l0 -115 -158 -6 c-219 -9 -332 -35 -485 -113 -252 -128 -433 -357 -510 -648 l-22
                        -81 0 -1520 0 -1520 23 -89 c24 -97 91 -246 142 -321 46 -66 154 -179 222 -232 128 -98 307 -172 469 -193 59 -8 586 -10 1754 -8 l1670 3 77 22 c179 51 312 123 429 231 174 162 275 344 314 568 23 131 23 2948 0 3079 -36 204 -127
                        377 -280 530 -210 210 -435 294 -791 295 l-92 0 -3 129 c-4 125 -5 131 -34 172 -87 123 -268 113 -337 -20 -26 -49 -28 -62 -28 -167 l0 -114 -980 0 -980
                        0 0 114 c0 105 -2 118 -28 167 -45 87 -147 128 -237 97z m-135 -993 c0 -230 5 -262 53 -313 102 -110 297 -66 337 76 5 20 10 130 10 245 l0 207 980 0 980 0
                        0 -207 c0 -115 5 -225 10 -245 40 -142 235 -186 337 -76 48 51 53 83 53 314 l0 217 143 -6 c79 -3 168 -13 201 -22 159 -43 302 -162 376 -312 43 -90 60
                        -176 60 -320 l0 -123 -2160 0 -2160 0 0 123 c0 144 17 230 60 320 88 178 253 297 455 327 27 4 98 8 158 9 l107 1 0 -215z m3540 -2078 c0 -1082 -1 -1116
                      -20 -1192 -50 -194 -199 -353 -391 -416 l-74 -24 -1675 0 -1675 0 -74 24 c-89 29 -204 104 -261 169 -60 67 -107 157 -130 247 -19 76 -20 111 -20 1192 l0 1113 2160 0 2160 0 0 -1113z"
                      />
                      <path d="M1105 2541 c-68 -30 -125 -113 -125 -181 0 -68 57 -151 125 -181 35 -16 69 -19 273 -19 257 0 279 4 340 63 20 20 39 52 48 84 14 48 14 58 0 106 -9 32 -28 64 -48 84 -61 59 -83 63 -340 63 -204 0 -238 -3 -273 -19z" />
                      <path d="M2272 2534 c-116 -57 -144 -204 -59 -301 60 -68 82 -73 347 -73 260 0 285 5 344 68 88 94 53 257 -69 313 -35 16 -68 19 -276 19 -233 0 -235 0 -287 -26z" />
                      <path d="M3470 2544 c-166 -72 -165 -299 2 -368 30 -13 82 -16 270 -16 204 0 238 3 273 19 68 30 125 113 125 181 0 68 -57 151 -125 181 -35 16 -69 19 -275 18 -188 0 -242 -3 -270 -15z" />
                      <path d="M1092 1354 c-116 -57 -144 -204 -59 -301 59 -68 83 -73 345 -73 188 0 240 3 270 16 136 56 170 227 65 326 -56 54 -82 58 -336 58 -231 0 -234 0 -285 -26z" />
                      <path d="M2295 1367 c-71 -28 -116 -82 -130 -158 -15 -79 39 -174 120 -210 35 -16 69 -19 275 -19 266 0 290 6 348 75 83 101 47 250 -73 306 -35 16 -67 18 -275 18 -155 0 -245 -5 -265 -12z" />
                      <path d="M3470 1364 c-166 -72 -165 -299 2 -368 30 -13 82 -16 270 -16 262 0 286 5 345 73 86 98 56 244 -61 302 -49 24 -55 25 -285 25 -188 -1 -243 -4 -271 -16z" />
                    </g>
                  </svg>
                  Date Of Birth *
                </span>
                <input
                  type="Date"
                  maxLength={10}
                  pattern="\d{10}"
                  placeholder=""
                  name="dob"
                  onChange={handleChange}
                  max={moment(new Date()).format("YYYY-MM-DD")}
                  value={val?.dob}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="profiledetail">
                <span>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.25 1.5H13.5C13.6989 1.5 13.8897 1.57902 14.0303 1.71967C14.171 1.86032 14.25 2.05109 14.25 2.25V15.75C14.25 15.9489 14.171 16.1397 14.0303 16.2803C13.8897 16.421 13.6989 16.5 13.5 16.5H4.5C4.30109 16.5 4.11032 16.421 3.96967 16.2803C3.82902 16.1397 3.75 15.9489 3.75 15.75V0H5.25V1.5ZM5.25 6.75H12.75V3H5.25V6.75ZM5.25 8.25V15H12.75V8.25H5.25Z"
                      fill="#003B61"
                    />
                  </svg>
                  Prescriber Number *
                </span>
                <input
                  type="text"
                  maxLength={10}
                  pattern="\d{10}"
                  placeholder="Your Prescriber Number"
                  name="PrescriberNumber"
                  onChange={handleChange}
                  value={val?.PrescriberNumber}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="profiledetail">
                <span>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.25 1.5H13.5C13.6989 1.5 13.8897 1.57902 14.0303 1.71967C14.171 1.86032 14.25 2.05109 14.25 2.25V15.75C14.25 15.9489 14.171 16.1397 14.0303 16.2803C13.8897 16.421 13.6989 16.5 13.5 16.5H4.5C4.30109 16.5 4.11032 16.421 3.96967 16.2803C3.82902 16.1397 3.75 15.9489 3.75 15.75V0H5.25V1.5ZM5.25 6.75H12.75V3H5.25V6.75ZM5.25 8.25V15H12.75V8.25H5.25Z"
                      fill="#003B61"
                    />
                  </svg>
                  Your FRENCHIE M.D Provider Number *
                </span>
                <input
                  type="text"
                  maxLength={10}
                  pattern="\d{10}"
                  placeholder="Your FRENCHIE M.D Provider Number"
                  name="frenchieProviderNumber"
                  value={val?.frenchieProviderNumber}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="profiledetail">
                <span>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 17.7959L4.227 13.0229C3.28301 12.0789 2.64014 10.8762 2.3797 9.56683C2.11925 8.25746 2.25293 6.90026 2.76382 5.66687C3.27472 4.43347 4.13988 3.37927 5.24991 2.63757C6.35994 1.89588 7.66498 1.5 9 1.5C10.335 1.5 11.6401 1.89588 12.7501 2.63757C13.8601 3.37927 14.7253 4.43347 15.2362 5.66687C15.7471 6.90026 15.8808 8.25746 15.6203 9.56683C15.3599 10.8762 14.717 12.0789 13.773 13.0229L9 17.7959ZM12.7125 11.9624C13.4467 11.2282 13.9466 10.2927 14.1492 9.27435C14.3517 8.25596 14.2477 7.20039 13.8503 6.24111C13.4529 5.28183 12.78 4.46192 11.9167 3.88507C11.0533 3.30821 10.0383 3.00032 9 3.00032C7.96167 3.00032 6.94666 3.30821 6.08332 3.88507C5.21997 4.46192 4.54706 5.28183 4.14969 6.24111C3.75231 7.20039 3.64831 8.25596 3.85084 9.27435C4.05337 10.2927 4.55333 11.2282 5.2875 11.9624L9 15.6749L12.7125 11.9624V11.9624ZM9 9.74994C8.60218 9.74994 8.22065 9.59191 7.93934 9.3106C7.65804 9.0293 7.5 8.64777 7.5 8.24994C7.5 7.85212 7.65804 7.47059 7.93934 7.18928C8.22065 6.90798 8.60218 6.74994 9 6.74994C9.39783 6.74994 9.77936 6.90798 10.0607 7.18928C10.342 7.47059 10.5 7.85212 10.5 8.24994C10.5 8.64777 10.342 9.0293 10.0607 9.3106C9.77936 9.59191 9.39783 9.74994 9 9.74994Z"
                      fill="#003B61"
                    />
                  </svg>
                  Address Details *
                </span>
                <input
                  ref={inputRef}
                  type="text"
                  placeholder="Street Name"
                  name="address"
                  onChange={handleChange}
                  value={val?.address}
                />
              </div>
            </Col>
            <Col lg={4}>
              <div className="profiledetail">
                <input
                  value={val.postcode}
                  type="text"
                  placeholder="Postcode"
                  maxLength={6}
                  minLength={6}
                  pattern="\d{10}"
                  name="postcode"
                  onChange={handleChange}
                />
              </div>
            </Col>

            <Col lg={12}>
              <div className="profiledetail mb-0">
                <span>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.6">
                      <path
                        d="M15 1.5C15.414 1.5 15.75 1.836 15.75 2.25V15.75C15.75 16.164 15.414 16.5 15 16.5H4.5C4.086 16.5 3.75 16.164 3.75 15.75V14.25H2.25V12.75H3.75V11.25H2.25V9.75H3.75V8.25H2.25V6.75H3.75V5.25H2.25V3.75H3.75V2.25C3.75 1.836 4.086 1.5 4.5 1.5H15ZM14.25 3H5.25V15H14.25V3ZM10.5 6V8.25H12.75V9.75H10.4992L10.5 12H9L8.99925 9.75H6.75V8.25H9V6H10.5Z"
                        fill="#003B61"
                      ></path>
                    </g>
                  </svg>
                  Condition Specialities *
                </span>
                <div className="selecttab select">
                  <div className="male">
                    <input
                      type="checkbox"
                      id="age1"
                      checked={specialities.erectileDysfunction}
                      name="Erectile Dysfunction"
                      onChange={(e) => handleChangeCheckbox(e)}
                    />
                    <label for="age1">Erectile Dysfunction</label>
                  </div>
                  <div className="male">
                    <input
                      type="checkbox"
                      id="age2"
                      checked={specialities.prematureEjaculation}
                      name="Premature Ejaculation"
                      onChange={(e) => handleChangeCheckbox(e)}
                    />
                    <label for="age2">Premature Ejaculation</label>
                  </div>
                  <div className="male">
                    <input
                      type="checkbox"
                      id="age3"
                      checked={specialities.stiStd}
                      name="STI/STD"
                      onChange={(e) => handleChangeCheckbox(e)}
                    />
                    <label for="age3">STI/STD</label>
                  </div>
                  <div className="male">
                    <input
                      type="checkbox"
                      id="age6"
                      checked={specialities.thrushBacterialVaginosis}
                      name="Thrush / Bacterial Vaginosis"
                      onChange={(e) => handleChangeCheckbox(e)}
                    />
                    <label for="age6">Thrush / Bacterial Vaginosis</label>
                  </div>
                  <div className="male">
                    <input
                      type="checkbox"
                      id="age7"
                      checked={specialities.urinaryTractInfection}
                      name="Urinary Tract Infection"
                      onChange={(e) => handleChangeCheckbox(e)}
                    />
                    <label for="age7">Urinary Tract Infection</label>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={12}>
              <div className="profiledetail mt-0">
                <input
                  type="text"
                  placeholder="Other Condition"
                  name="other"
                  onChange={handleChange}
                  value={val?.other}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="profiledetail">
                <span>Bio *</span>
                <textarea
                  placeholder="Tell the patient a bit about yourself"
                  name="bio"
                  onChange={handleChange}
                  value={val?.bio}
                ></textarea>
              </div>
            </Col>
            <Col lg={12}>
              <div className="nextbtns">
                <div className="editprofilebtn">
                  <Link to="/LoginDoctor" className="redbtn whitebtn">
                    <svg
                      width="11"
                      height="12"
                      viewBox="0 0 11 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.7185 5.33312H10.8332V6.66645H2.7185L6.2945 10.2425L5.35184 11.1851L0.166504 5.99979L5.35184 0.814453L6.2945 1.75712L2.7185 5.33312Z"
                        fill="#003B61"
                      />
                    </svg>
                    Back
                  </Link>
                  <a
                    role="button"
                    onClick={() => handleSubmit()}
                    className="redbtn"
                  >
                    Next{" "}
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.2812 7.33312L7.70517 3.75712L8.64784 2.81445L13.8332 7.99979L8.64784 13.1851L7.70517 12.2425L11.2812 8.66645H3.1665V7.33312H11.2812Z"
                        fill="#F8F5F0"
                      />
                    </svg>
                  </a>
                </div>
                <p>©2023 Frenchie M.D Pty Ltd</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SetUpProfile;
