import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import SideDrawer from "../SideDrawer/SideDrawer";
import { appContext } from "../../helpers/AppContext";
import { ApiPost } from "../../helpers/API/API_data";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Header from "../Header/Header";

const PatientSummaryCard = () => {
  const history = useHistory();
  const [patientData, setPatientData] = useState();
  const location = useLocation();
  const {
    setPatientProfileData,
    treatmentIds,
    setTreatmentIds,
  } = useContext(appContext);

  const id = location?.state?.state?.id;
  const name = location?.state?.state?.Name;

  const getPatientDetails = async () => {
    const body = {
      _id: id,
      condition: name,
    };
    await ApiPost(`doctor/patient`, body)
      .then((res) => {

        setPatientData(res?.data?.data?.[0]);
      })
      .catch((e) => {

      });
  };
  useEffect(() => {
    getPatientDetails();
    return () => {
      setPatientProfileData("");
    };
  }, []);

  useEffect(() => {
    return () => {
      setTreatmentIds("");
    };
  }, []);

  const signupSchema = yup.object().shape({
    summery: yup.string().required("Patient consult notes is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signupSchema),
  });

  // add treatment summary by treatmentId
  const btnSubmitData = (data) => {

    if (!treatmentIds || treatmentIds.length === 0) {
      history.push(`/PatientList`);
    } else {
      const body = {
        patientId: id,
        treatmentIds: treatmentIds,
        summery: data?.summery,
        status: 1,
        date: new Date(),
        time: new Date().getTime(),
        condition: patientData?.condition,
      };
      ApiPost(`doctor/card`, body)
        .then((res) => {
          history.push(`/PatientSummryCard?type=patientCard`, {
            SummaryCardData: res?.data?.data,
          });
        })
        .catch((e) => {

        });
    }
  };

  return (
    <div className="chatscreen admindashboard">
      <Row>
        <Col lg={2}>
          <SideDrawer />
        </Col>
        <Col lg={10}>
          <Header />

          <div className="activepatient">
            <div className="viewpatienttitle">
              <h4>Add Treatment</h4>
              <ul className="uploaddoc">
              </ul>
            </div>

            <div
              className="medicinlist accordion"
              style={{ borderRadius: "0px" }}
            >
              <Container>
                <div className="patient-summary-card-bg">
                  <div className="viewpatienttitle">
                    <h4>Patient summary card(Profile)</h4>
                    <h4>#000123</h4>
                  </div>
                  <div className="detail">
                    <h3>Patient’s Name </h3>
                    <h3>
                      {patientData?.patient?.[0]?.firstName}{" "}
                      {patientData?.patient?.[0]?.lastName}
                    </h3>
                    <h3>Patient’s Address </h3>
                    <h3>
                      {patientData?.patient?.[0]?.billingAddress ? patientData?.patient?.[0]?.billingAddress : "-"},{" "}
                      {patientData?.patient?.[0]?.city ? patientData?.patient?.[0]?.city : "-"},{" "}
                      {patientData?.patient?.[0]?.state
                        ? patientData?.patient?.[0]?.state
                        : "-"}
                      ,{" "}
                      {patientData?.patient?.[0]?.country
                        ? patientData?.patient?.[0]?.country
                        : "-"}
                      ,{" "}
                      {patientData?.patient?.[0]?.postcode
                        ? patientData?.patient?.[0]?.postcode
                        : "-"}
                    </h3>
                  </div>
                  <div className="notes">
                    <h1>Patient Consult Notes:</h1>
                    <label>Summary</label>
                    <textarea
                      {...register("summery")}
                      rows={5}
                      placeholder="Write Discussion notes/symptoms"
                    ></textarea>
                    {errors?.summery && (
                      <p className="error">{errors?.summery.message}</p>
                    )}
                  </div>
                  <div className="btnredsubmit">
                    <Button
                      onClick={handleSubmit((data) => {
                        btnSubmitData(data);
                      })}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Container>
            </div>

            <div className="coptrights">
              <p>©2023 Frenchie M.D Pty Ltd</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PatientSummaryCard;
